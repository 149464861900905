import * as React from 'react'
import { Link } from "react-router-dom"
import Footer from 'components/Footer'
import * as config from "config"
import Logo from "../assets/logo.png"
import { Role } from "../constance/constance.role"
import { Layout, Header, Content, LogoContainer } from "./UnAuthorizedLayout.style"


const UnAuthorizedLayout: React.FC<any> = ({children}) => {

  return (

    <Layout>

      <Header>
        <LogoContainer>
          {
            config.setting.role === Role.Staff ? (
              <Link to={config.setting.paths.root}>
                <img src={Logo} alt={''}/>
              </Link>
            ) : (
              <img src={Logo} alt={''}/>
            )
          }
        </LogoContainer>
      </Header>

      <Content>
        <div>{children}</div>
      </Content>

      <Footer/>
    </Layout>
  )
}

export default UnAuthorizedLayout
