import * as React from 'react'
import {Button} from "antd"
import './RegisterHeader.less'
import {useHistory} from "react-router-dom"
import * as config from "../../config"

export interface IRegisterHeaderProps {
}

const RegisterHeader: React.FC<IRegisterHeaderProps> = props => {
  const history = useHistory()

  const onClick = () => {
    history.push(config.setting.paths.staffRegisterQuestionnaire)
  }

  return (
    <div className="register-header">
      <Button type="primary" htmlType="submit" onClick={onClick}>
        アンケートを登録する
      </Button>
    </div>
  )
}

export default RegisterHeader
