import Service from 'helper/helper.service'
import config from 'config/config.setting'

/**
 * @extends {Service}
 */
class QuestionnaireService extends Service {
  /**
   * @returns
   */
  async getList<T = QuestionnairesResponseDto>(params?: QueryParams) {
    return await this.get<T>(config.endpoints.questionnaires, params)
  }

  /**
   * @returns
   */
  async csvReport<T = QuestionnairesReportCsvResponseDto, U = null>(hash: string, query?: QueryParams) {
    const encoded = encodeURIComponent(hash)
    return await this.post<T, U>(config.endpoints.questionnaireCSV(encoded), {} as U, query)
  }

  /**
   * ショートURLのパスパラメータから、通常のアンケートのURLを取得
   * @returns
   */
  async getQuestionnairePass<T = string>(hash: string, params?: QueryParams) {
    return await this.get<T>(config.endpoints.questionnairePass(hash), params)
  }

  /**
   * 回答URLのパスを取得
   * @returns
   */
  async getByHash<T = QuestionnaireResponseDto>(id: string, params?: QueryParams) {
    return await this.get<T>(config.endpoints.questionnaire(id), params)
  }

  /**
   * @returns
   */
  async submit<T = QuestionnaireAnswerResponseDto, U = QuestionnaireAnswerRequestDto>(params: U) {
    return await this.post<T, U>(config.endpoints.contracts, params)
  }

  /**
   * @returns
   */
  async create<T = QuestionnaireCreateResponseDto, U = QuestionnaireCreateRequestDto>(params: U) {
    return await this.post<T, U>(config.endpoints.questionnaires, params)
  }

  /**
   * @returns
   */
  async update<T = QuestionnaireUpdateResponseDto, U = QuestionnaireUpdateRequestDto>(params: U) {
    return await this.put<T, U>(config.endpoints.questionnaires, params)
  }

  /**
   *アンケートの非表示
   * @returns
   */
  async hide<T = '', U = null>(params: U, queries: { hash: string }) {
    return await this.patch<T, U>(config.endpoints.questionnaire(queries.hash), params, queries)
  }

  /**
   *アンケートの再表示
   * @returns
   */
  async reDisplay<T = '', U = null>(params: U, queries: { hash: string }) {
    return await this.post<T, U>(config.endpoints.questionnaire(queries.hash), params, queries)
  }

  /**
   * @returns
   */
  async remove<T = QuestionnaireDeleteResponseDto>(hash: string) {
    return await this.delete<T>(config.endpoints.questionnaire(hash))
  }

  /**
   * @returns
   * メール本文の取得
   */
  async getMailHtml<T = QuestionnaireGetMailTextResponseDto>(hash: string) {
    return await this.get<T>(config.endpoints.questionnaireMailText(hash))
  }

  /**
   * @returns
   * SMS本文の取得
   */
  async getSmsText<T = QuestionnaireGetMailTextResponseDto>(hash: string) {
    return await this.get<T>(config.endpoints.questionnaireSmsText(hash))
  }

  /**
   *
   * @param params
   */
  async getHiddenList<T= QuestionnairesResponseDto>(params?: QueryParams) {
    return await this.get<T>(config.endpoints.archiveQuestionnaires, params)
  }
}

export default new QuestionnaireService()
