import styled from 'styled-components'
import { mq } from "constance/constance.breakPoint"
import { Layout as AntLayout, Menu as AntMenu } from "antd"
import { colorCode } from "constance/constance.color"


export const Layout = styled(AntLayout)`
  // rgba表記なのでベタうち 透明度はUIをみながら調整
  background: rgba(0, 174, 239, 0.025);
`

export const Sider = styled(AntLayout.Sider)`
  background: ${colorCode.professionalBlue};
  min-height: 96vh;


  .ant-menu {
    background: ${colorCode.professionalBlue};
    color: ${colorCode.white};

    // 1px分元々の影の部分でずれるで追加
    border-right: solid 1px ${colorCode.professionalBlue};
  }
`
export const Menu = styled(AntMenu)``

export const Content = styled(AntLayout.Content)`
  overflow-x: scroll;
  background: ${colorCode.white};
`

export const Header = styled(AntLayout.Header)`
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  z-index: 99;
  background: ${colorCode.professionalBlue};
`


export const LogoContainer = styled.div`
  img {
    width: 180px;
    @media (${mq.md}) {
      width: 210px;
    }
`


