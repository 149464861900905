import * as React from 'react'
import {useEffect, useState} from 'react'
import './RegisterQuestionnaire.less'
import {useLocation} from 'react-router-dom'
import * as config from "config"
import useBreadcrumbs from "hooks/hooks.useBreadcrumb"
import {getSubTitleByPath, getTitleByPath} from "helper/helper.breadcrumb"
import CsvUpload from 'components/CsvUpload'
import {message} from "antd"
import {useHistory} from "react-router-dom"
import axios from "axios"
import {createFormData} from "helper/helper.argo"

export interface IRegisterQuestionnaireProps {
}

const RegisterQuestionnaire: React.FC<IRegisterQuestionnaireProps> = props => {
  const location = useLocation()
  const history = useHistory()
  const [fileList, setFileList] = useState<[]>([])
  const [loading, setLoading] = useState(false)

  const {setBreadcrumbs, setTitle, setSubTitle} = useBreadcrumbs()

  useEffect(() => {
    setSubTitle(getSubTitleByPath(location.pathname))
    setTitle(getTitleByPath(location.pathname))
    setBreadcrumbs([{
      path: config.setting.paths.staffRegisterQuestionnaire,
      breadcrumbName: getTitleByPath(config.setting.paths.staffRegisterQuestionnaire)
    }])
  }, [location.pathname, setBreadcrumbs, setSubTitle, setTitle])

  // 配信リストのリストの重複チェック
  const handleUpload = async () => {
    try {
      setLoading(true)
      const formData = await createFormData(fileList)
      await axios.post(config.setting.endpoints.questionnaireRegisterFile, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      message.success('アンケートを登録しました。')
      history.push(config.setting.paths.staffQuestionnaireList)
    } catch (e) {
      message.error('アンケートの登録に失敗しました。')
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <CsvUpload<any>
      fileList={fileList}
      setFileList={setFileList}
      callback={handleUpload}
      loading={loading}
    />
  )
}

export default RegisterQuestionnaire
