import styled from 'styled-components'
import { Table } from 'antd'

interface IAntTableProps {
  paddingTopAndBottom: number
}

export const AntTable = styled(Table)<IAntTableProps>`
  .ant-table-thead > tr > th {
    background: #fff;
  }

  .ant-table-tbody > tr > td {
    //padding: 16px 16px 通常;
    padding: ${(props) => `${props.paddingTopAndBottom}px  16px`};
  }
`
