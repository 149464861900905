import * as React from 'react'
import { useRef, useState } from 'react'
import { Button, message } from 'antd'
import * as $apis from 'apis'
import { CSVLink } from 'react-csv'

export interface ICsvExportProps {
  questionnaire: QuestionnaireDelivery
}

const CsvExport: React.FC<ICsvExportProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)

  const questionnaireTitle = props.questionnaire.title

  const sentAt = props.questionnaire.sentAt.toString().split('T')[0].replace('-', '').replace('-', '')

  const ref = useRef<any>(null)
  const [data, setData] = useState<any>([])
  const fetchData = async () => {
    if (!props.questionnaire.groupHash) {
      return message.error('エラーが発生しました。')
    }
    setLoading(true)
    try {
      const response = await $apis.delivery.csvReport(props.questionnaire.groupHash)
      setData(response.data)
      setTimeout(() => {
        if (ref.current) {
          ref.current.link.click()
        }
      }, 100)
    } catch (e) {
      console.log(e)
      message.error('CSVの出力に失敗しました。')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Button type="default" onClick={fetchData} loading={loading} style={{ width: 100 }}>
        CSV出力
      </Button>
      <CSVLink
        ref={ref}
        data={data}
        filename={`${sentAt}_${questionnaireTitle}.csv`}
        className="hidden"
        target="_blank"
      />
    </>
  )
}

export default CsvExport
