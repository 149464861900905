import * as React from 'react'
import { Modal, Radio } from 'antd'

const smsType = 'sms'
const mailType = 'mail'

export interface IShowMailTextModalProps {
  isShowNotificationPreviewModal: boolean
  setIsShowNotificationPreviewModal: React.Dispatch<React.SetStateAction<boolean>>
  selectQuestionnaire: Questionnaire | null
  setSelectQuestionnaire: React.Dispatch<React.SetStateAction<Questionnaire | null>>
  mailHtml: string
  setMailHtml: React.Dispatch<React.SetStateAction<string>>
  smsText: string
  setSmsText: React.Dispatch<React.SetStateAction<string>>
}

// カスタマイズメールを表示さえるモーダル
const ShowNotificationPreviewModal: React.FC<IShowMailTextModalProps> = (props) => {
  const {
    isShowNotificationPreviewModal,
    setIsShowNotificationPreviewModal,
    selectQuestionnaire,
    setSelectQuestionnaire,
    mailHtml,
    setMailHtml,
    smsText,
    setSmsText,
  } = props

  const [selectedType, setSelectType] = React.useState<string>(mailType)

  const onChange = (e: any) => {
    setSelectType(e.target.value)
  }

  const handleCancel = (e: any) => {
    setIsShowNotificationPreviewModal(false)

    // 前表示したメール本文が残ってしまうのでリセットする
    setMailHtml('')
    setSmsText('')
    setTimeout(() => {
      setSelectQuestionnaire(null)
    }, 100)
  }

  const renderContent = (tab: string) => {
    switch (tab) {
      case mailType:
        return (
          <iframe
            title="メール文面プレビュー"
            srcDoc={mailHtml}
            style={{ width: '100%', height: '100%', border: '0'}}
          />
        )
      case smsType:
        return <div style={{ whiteSpace: 'pre-line', flex: 1, width: '100%', overflowY: 'scroll', padding: '24px' }}>
          {smsText}
        </div>
    }
  }

  return (
    <>
      <Modal
        title={
          <>
            <div>{selectQuestionnaire?.title ?? ''}の文面プレビュー</div>
            <Radio.Group
              buttonStyle="solid"
              onChange={onChange}
              value={selectedType}
              style={{ marginTop: '16px', textAlign: 'center' }}
            >
              <Radio.Button style={{ width: '120px' }} value={mailType}>
                メール文面
              </Radio.Button>
              <Radio.Button style={{ width: '120px' }} value={smsType}>
                SMS 文面
              </Radio.Button>
            </Radio.Group>
          </>
        }
        visible={isShowNotificationPreviewModal}
        onCancel={handleCancel}
        footer={null}
        width={'60vw'}
        bodyStyle={{ height: '70vh', display: 'flex', padding: 0}}
      >
          {renderContent(selectedType)}
      </Modal>
    </>
  )
}

export default ShowNotificationPreviewModal
