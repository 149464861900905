import * as React from 'react'
import { useEffect, useState } from 'react'
import './RegisterDistributionList.less'
import { useHistory, useLocation } from 'react-router-dom'
import axios from 'axios'
import * as $apis from 'apis'
import * as config from 'config'
import useBreadcrumbs from 'hooks/hooks.useBreadcrumb'
import { getSubTitleByPath, getTitleByPath } from 'helper/helper.breadcrumb'
import CsvUpload from 'components/CsvUpload'
import { message, Typography } from 'antd'
import moment, { Moment } from 'moment'
import TimePicker from 'components/TimePicker'
import DateOnlyPicker from 'components/DateOnlyPicker'
import { momentParse } from 'helper/helper.momentParse'
import QuestionnaireDeliveryDuplicateAlertModal from 'components/QuestionnaireDeliveryDuplicateAlertModal'
import { createFormData } from 'helper/helper.argo'

const { Text } = Typography

export interface IRegisterDistributionListProps {
  match: {
    params: {
      hash: string
    }
  }
}

const RegisterDistributionList: React.FC<IRegisterDistributionListProps> = (props) => {
  const location = useLocation<{ questionnaire: Questionnaire }>()
  const questionnaire = location.state?.questionnaire
  const history = useHistory()
  const [fileList, setFileList] = useState<[]>([])
  const [loading, setLoading] = useState(false)
  const [sentAt, setSentAt] = useState<Moment>(moment().startOf('day').add(16, 'h'))
  const [isDatePickerBlank, setIsDatePickerBlank] = useState<boolean>(true) // datePickerが操作されたか確認
  const [questionnaireTitle, setQuestionnaireTitle] = useState<string>('') // アンケートのタイトル

  const [deliveryDuplicateCsv, setDeliveryDuplicateCsv] = useState<Answerer[] | []>([]) // 配信リストのCSVの重複しているアンケート
  const [deliveryDuplicateDb, setDeliveryDuplicateDb] = useState<Answerer[] | []>([]) // 配信リストのデータベースの重複のしているアンケート

  // 配信リストの登録の際に、重複エラーが起きたときのモーダル
  const [isShowQuestionnaireDeliveryDuplicateAlertModal, setIsShowQuestionnaireDeliveryDuplicateAlertModal] = useState<
    boolean
  >(false)

  const { setBreadcrumbs, setTitle, setSubTitle } = useBreadcrumbs()
  const title = location.state?.questionnaire.title

  useEffect(() => {
    //リロードした際にquestionnaireデータがないと、登録した際にエラーになるので前のページに遷移させる
    if (!questionnaire) {
      return history.goBack()
    }
  }, [history, location.pathname, questionnaire, setBreadcrumbs, setSubTitle, setTitle])

  // リロードした時にタイトルが消えてしまうので初回レンダリングで追加
  useEffect(() => {
    // アンケートに紐づく顧客情報を取得するため、groupPathが必要なため
    const hash = props.match.params.hash
    const path = location.pathname.replace(`/${hash}`, '')

    setSubTitle(getSubTitleByPath(path))
    setTitle(getTitleByPath(path))
    setBreadcrumbs([
      {
        path: config.setting.paths.staffRegisterQuestionnaire,
        breadcrumbName: getTitleByPath(config.setting.paths.staffRegisterQuestionnaire),
      },
    ])
  }, [])

  // アンケートの名がリロードし時に消えてしまうので、取得する
  useEffect(() => {
    const res = $apis.questionnaire.getByHash(props.match.params.hash).then((res) => {
      setQuestionnaireTitle(res.data.title)
    })
  }, [])

  const datePickCallback = (momentProps: Moment | null) => {
    setIsDatePickerBlank(false) //  日付がブランクの場合にエラーのアラートを出すために追加

    if (momentProps) {
      // 現在登録してある、hour, minute の値を抽出する
      const { hour, minute } = momentParse(sentAt)
      const newDate = moment({
        year: Number(momentProps?.format('YYYY')),
        month: Number(momentProps?.format('MM')) - 1, // timePickerを操作したらなぜか+されてしまうので-1する
        day: Number(momentProps?.format('DD')),
        hour: hour,
        minute: minute,
      })
      setSentAt(newDate)
    }
  }

  // 時間選択のOKボタンを押したときに呼ばれるコールバック
  const timePickerCallback = (momentProps: Moment | null) => {
    if (momentProps) {
      // 現在登録してある、year, month,dayの値を抽出する
      const { year, month, day } = momentParse(sentAt)
      const newDate = moment({
        year: year,
        month: month - 1, // timePickerを操作したらなぜか+されてしまうので-1する
        day: day,
        hour: Number(momentProps?.format('HH')),
        minute: Number(momentProps?.format('mm')),
      })

      setSentAt(newDate)
    }
  }

  // Nowを押したときに呼び出されるコールバック関数　※正確には時間を変更されたら呼ばれる関数
  const nowButtonCallback = () => {
    const roundUpMin = Math.ceil(Number(moment()?.format('mm')) * 0.1) // 切り上げ処理

    // 現在登録してある、year, month,dayの値を抽出する
    const { year, month, day } = momentParse(sentAt)

    const currentTime = moment({
      year: year,
      month: month - 1, // timePickerを操作したらなぜか+されてしまうので-1する
      day: day,
      // 51分だった場合、切り上げ処理を行った場合に６になり、1時間プラスにする
      hour: roundUpMin === 6 ? Number(moment()?.format('HH')) + 1 : Number(moment()?.format('HH')),
      minute: roundUpMin === 6 ? 0 : roundUpMin * 10, //　上記で0.1倍したので、10倍する
    })

    setSentAt(currentTime)
  }

  const handleUpload = async () => {
    try {
      setLoading(true)
      const formData = await createFormData(fileList, { sentAt: sentAt.toDate(), data: questionnaire })
      await axios.post(config.setting.endpoints.questionnaireDelivery, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      message.success('配信リストを登録しました。')
      history.push(config.setting.paths.staffDeliveryList)
    } catch (e) {
      message.error(e.response?.data?.message ?? '配信リストの登録に失敗しました。')
    } finally {
      setLoading(false)
    }
  }

  // 配信リストのリストの重複チェック
  const handleUploadDistributionList = async () => {
    try {
      if (isDatePickerBlank) {
        message.error('配信予定日が登録されていません')
        return
      }

      setLoading(true)
      const formData = await createFormData(fileList, { sentAt: sentAt.toDate(), data: questionnaire })
      const res = await axios.post<any, { data: { checkCsv: Answerer[]; checkDb: Answerer[] } }>(
        config.setting.endpoints.questionnaireDeliveryDuplicateCheck,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      const checkCsvList = res.data.checkCsv
      const checkDbList = res.data.checkDb
      setDeliveryDuplicateCsv(checkCsvList)
      setDeliveryDuplicateDb(checkDbList)

      if (checkCsvList.length || checkDbList.length) {
        setIsShowQuestionnaireDeliveryDuplicateAlertModal(true)
      } else {
        await axios.post(config.setting.endpoints.questionnaireDelivery, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        message.success('配信リストを登録しました。')
        history.push(config.setting.paths.staffDeliveryList)
      }
    } catch (e) {
      message.error(e.response?.data?.message ?? '配信リストの登録に失敗しました。')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="register-distribution">
      <div className="register-distribution-title">
        <Text>アンケート名： {title ?? questionnaireTitle}</Text>
      </div>

      <div className={'register-distribution-date-picker'}>
        <Text style={{ marginRight: 24 }}>配信予定日：</Text>

        <div className={'register-distribution-date-picker-row'}>
          <DateOnlyPicker
            callback={datePickCallback}
            disabledDate={(current) => {
              return current < moment().add(-1, 'day')
            }}
          />
          {/* 過去の時間でOKボタンが押せないように現在の日付をクリック */}
          <TimePicker callback={timePickerCallback} nowButtonCallback={nowButtonCallback} sentAt={sentAt} />
        </div>
      </div>

      <div className="register-distribution-list">
        <Text>配信リストの登録</Text>
        <CsvUpload<Questionnaire>
          fileList={fileList}
          setFileList={setFileList}
          loading={loading}
          callback={handleUploadDistributionList}
        />
      </div>

      {/*配信リストを表示させたときに、重複があれば表示するアラート*/}
      <QuestionnaireDeliveryDuplicateAlertModal
        isShowQuestionnaireDeliveryDuplicateAlertModal={isShowQuestionnaireDeliveryDuplicateAlertModal}
        setIsShowQuestionnaireDeliveryDuplicateAlertModal={setIsShowQuestionnaireDeliveryDuplicateAlertModal}
        deliveryDuplicateCsv={deliveryDuplicateCsv}
        deliveryDuplicateDb={deliveryDuplicateDb}
        handleUpload={handleUpload}
        loading={loading}
      />
    </div>
  )
}

export default RegisterDistributionList
