import * as React from 'react'
import { Modal } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
import { questionnaireDisplayName } from '../../constance'
import { message } from 'antd'
import * as $apis from 'apis'

export interface IRemoveConfirmModalButtonProps {
  isDisplayModal: boolean
  setIsDisplayModal: React.Dispatch<React.SetStateAction<boolean>>
  selectTag: Tag
  getTags: () => void
}

const RemoveConfirmModalButton: React.FC<IRemoveConfirmModalButtonProps> = (props) => {
  const [confirmLoading, setConfirmLoading] = React.useState(false)

  const handleOk = async () => {
    setConfirmLoading(true)
    try {
      await $apis.tag.remove(String(props.selectTag.id))
      message.success('LMD連携タグを削除しました。')
      await props.getTags()
    } catch (e) {
      message.error(e.response.data)
    } finally {
      props.setIsDisplayModal(false)
      setConfirmLoading(false)
    }
  }

  const handleCancel = (e: any) => {
    props.setIsDisplayModal(false)
  }

  return (
    <div className="remove-confirm-modal-button">
      <Modal
        title={<div style={{ maxWidth: 480 }}>{props.selectTag?.name} タグを削除しますか？</div>}
        visible={props.isDisplayModal}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        cancelText="キャンセル"
        okText={'削除する'}
      >
        {/*モーダル*/}
        <ul className="modal-confirm-content">
          <li>
            <Paragraph>
              <span>{questionnaireDisplayName.title}</span>
              <span>{props.selectTag?.questionnaire?.title}</span>
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              <span>{questionnaireDisplayName.tag}</span>
              <span>{props.selectTag?.name}</span>
            </Paragraph>
          </li>
        </ul>
      </Modal>
    </div>
  )
}

export default RemoveConfirmModalButton
