import * as React from 'react'
import { message, Modal } from 'antd'
import { questionnaireDisplayName } from 'constance/constance.display'
import Paragraph from 'antd/es/typography/Paragraph'
import * as $apis from '../../../../apis'

import { QuestionnaireType, questionnaireTypeName } from 'constance/constance.display'

export interface IRemoveBtnModalProps {
  isRemoveBtnModal: boolean
  setIsRemoveBtnModal: React.Dispatch<React.SetStateAction<boolean>>
  effectQuery: () => Promise<void>
  choiceQuestionnaire: Questionnaire | null
  setChoiceQuestionnaire: React.Dispatch<React.SetStateAction<Questionnaire | null>>
}

// 削除ボタンのモーダル
const RemoveBtnModal: React.FC<IRemoveBtnModalProps> = (props) => {
  const { isRemoveBtnModal, setIsRemoveBtnModal, choiceQuestionnaire, effectQuery, setChoiceQuestionnaire } = props
  const [confirmLoading, setConfirmLoading] = React.useState(false)

  const handleOk = async (e: any) => {
    setConfirmLoading(true)
    try {
      if (!choiceQuestionnaire?.hash) return
      await $apis.questionnaire.remove(choiceQuestionnaire.hash)
      setTimeout(() => {
        setChoiceQuestionnaire(null)
      }, 100)
      setIsRemoveBtnModal(false)
      message.success('削除しました。')
      effectQuery()
    } catch (e) {
      message.error('削除に失敗しました。')
    } finally {
      setConfirmLoading(false)
    }
  }

  const handleCancel = (e: any) => {
    console.log(e)
    setIsRemoveBtnModal(false)
    setTimeout(() => {
      setChoiceQuestionnaire(null)
    }, 100)
  }

  return (
    <>
      <Modal
        title={`${choiceQuestionnaire?.title ?? ''}を削除しますか`}
        visible={isRemoveBtnModal}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="キャンセル"
        confirmLoading={confirmLoading}
        okText="削除する"
      >
        <ul className="modal-confirm-content">
          <li>
            <Paragraph>
              <span>{questionnaireDisplayName.title}</span>
              <span>{choiceQuestionnaire?.title ?? ''}</span>
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              <span>{questionnaireDisplayName.type}</span>
              <span>{questionnaireTypeName[choiceQuestionnaire?.type ?? QuestionnaireType.Seller]}</span>
            </Paragraph>
          </li>
        </ul>
      </Modal>
    </>
  )
}

export default RemoveBtnModal
