import * as React from 'react'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Alert, Button, Card, Form, Skeleton, Typography } from 'antd'
import { ValidateErrorEntity, FieldData } from 'rc-field-form/lib/interface'
import { AnswerStatus, QuestionType } from 'constance/constance.display'
import './Questionnaire.less'
import _ from 'lodash'
import RadioComponent from './components/Radio'
import CheckBoxComponent from './components/CheckBox'
import TextAreaComponent from './components/TextArea'
import SliderComponent from './components/Slider'
import { useHistory } from 'react-router-dom'
import FlowComponent from './components/Flow'
import InputComponent from './components/Input'

const { Text } = Typography

export interface IQuestionnaireProps {
  preview: boolean
  loading: boolean
  error: boolean
  answererStatus?: AnswerStatus
  questionnaire: Questionnaire | null
  onFinish: (values: AnswerQuestionnaire) => void

  onFinishFailed: (errorInfo: ValidateErrorEntity<AnswerQuestionnaire>) => void
  alerts?: ValidateErrorEntity<AnswerQuestionnaire> | null
  setAlerts?: Dispatch<SetStateAction<ValidateErrorEntity<AnswerQuestionnaire> | null>>
}

export const questionnaireData = {
  title: 'お客様アンケートにご記入のお願い',
  description:
    'この度は、弊社をご利用いただき誠にありがとうございます。弊社では、お客様へ「ひとつ上の満足」をご提供できるよう、顧客満足度の向上に取り組んでおり、、、',
  sections: [
    {
      text: 'Q1. ご案内やご説明についてどのようにお感じになりましたか。当てはまる項目にチェックしてください。',
      no: 1,
      questions: [
        {
          no: 1,
          text:
            '① ご契約締結後からお引渡しまでの期間、お住いに関するご不明点や疑問点に対し、担当者は親身になってお答えしておりましたか。',
          questionFormats: [
            {
              no: 1,
              type: QuestionType.Radio,
              text: '思わない',
            },
            {
              no: 2,
              type: QuestionType.Radio,
              text: '思う',
            },
            {
              no: 3,
              type: QuestionType.OptionalTextArea,
              text: 'どちらでもない',
            },
          ],
        },
        {
          no: 2,
          text: '② お引渡しまでのお手続きのご案内は、タイミング良くわかりやすいものでしたか。',
          questionFormats: [
            {
              no: 1,
              type: QuestionType.Radio,
              text: '思わない',
            },
            {
              no: 2,
              type: QuestionType.Radio,
              text: '思う',
            },
            {
              no: 3,
              type: QuestionType.Radio,
              text: 'どちらでもない',
            },
          ],
        },
        {
          no: 3,
          text:
            '③ 最終的なご資金計画や持ち分の決定に際し、担当者は必要に応じて適切なアドバイスを差し上げておりましたか。',
          questionFormats: [
            {
              no: 1,
              type: QuestionType.Radio,
              text: '2',
            },
            {
              no: 2,
              type: QuestionType.Radio,
              text: '3',
            },
          ],
        },
        {
          no: 4,
          text: '④ どのようにして、弊社のサービスを利用されるようになりましたか。',
          questionFormats: [
            {
              no: 1,
              type: QuestionType.CheckBox,
              text: '新聞',
            },
            {
              no: 2,
              type: QuestionType.CheckBox,
              text: 'ラジオ',
            },
            {
              no: 3,
              type: QuestionType.CheckBox,
              text: 'インターネット',
            },
          ],
        },
      ],
    },
    {
      text: 'Q3. あなたは東急リバブルを友人や知人にすすめる可能性は、どのくらいありますか？',
      no: 3,
      questions: [
        {
          no: 1,
          text: '',
          questionFormats: [
            {
              no: 1,
              type: QuestionType.Slider,
              text: 'すすめる可能性はまったくない',
            },
            {
              no: 10,
              type: QuestionType.Slider,
              text: 'すすめる可能性は極めて高い',
            },
          ],
        },
      ],
    },
    {
      text:
        'Q4. その他お感じになられたこと（印象、ご満足いただけた点、お困りになったことなど）を自由にご記入ください。',
      no: 4,
      questions: [
        {
          no: 1,
          text: '',
          questionFormats: [
            {
              no: 1,
              type: QuestionType.OptionalTextArea,
              text: 'min',
            },
          ],
        },
      ],
    },
  ],
} as Questionnaire

type FormattedSection = Omit<Section, 'questions'> & {
  isVisible: boolean
  questions: Array<
    Question & {
      isVisible: boolean
      formats: Array<{
        type: QuestionType
        data: Array<QuestionFormat>
      }>
    }
  >
}

const { Title } = Typography

function QuestionComponent(
  format: { type: QuestionType; data: QuestionFormat[] },
  sectionNo: number,
  questionNo: number,
  questionText: string,
  isLastQuestion: boolean
) {
  switch (format.type) {
    case QuestionType.Radio:
      return (
        <RadioComponent<QuestionFormat>
          list={format.data}
          sectionNo={sectionNo}
          questionNo={questionNo}
          questionText={questionText}
        />
      )
    case QuestionType.CheckBox:
      return (
        <CheckBoxComponent<QuestionFormat>
          list={format.data}
          sectionNo={sectionNo}
          questionNo={questionNo}
          questionText={questionText}
        />
      )
    case QuestionType.OptionalEmail:
      return (
        <InputComponent
          questionType={QuestionType.OptionalEmail}
          type="email"
          required={false}
          maxLength={200}
          sectionNo={sectionNo}
          questionNo={questionNo}
          questionText={questionText}
        />
      )
    case QuestionType.OptionalTextArea:
      return (
        <TextAreaComponent required={false} sectionNo={sectionNo} questionNo={questionNo} questionText={questionText} />
      )
    case QuestionType.Slider:
      return (
        <SliderComponent<QuestionFormat>
          list={format.data}
          sectionNo={sectionNo}
          questionNo={questionNo}
          questionText={questionText}
        />
      )
    case QuestionType.Flow:
      return (
        <FlowComponent<QuestionFormat>
          isLastQuestion={isLastQuestion}
          list={format.data}
          sectionNo={sectionNo}
          questionNo={questionNo}
          questionText={questionText}
        />
      )
    default:
      throw new Error('Invalid format type')
  }
}

function ComputeVisibleQuestions(sections: FormattedSection[], changedFields: FieldData[], allFields: FieldData[]) {
  // フィールドをMapに変換
  const filledValueMap = new Map<number, Map<number, any>>()
  allFields.forEach((field) => {
    const name = field.name as number[]
    const sectionNo = name[0]
    const questionNo = name[1]
    if (!filledValueMap.has(sectionNo)) {
      filledValueMap.set(sectionNo, new Map<number, any>())
    }
    filledValueMap.get(sectionNo)?.set(questionNo, field.value)
  })

  // 問題の表示・非表示を計算
  // section と question はソーティングされている前提
  let isFillingSectionFound = false
  sections.forEach((section) => {
    let isAnyQuestionVisible = false
    let isAllRequiredQuestionFilled = true
    let isAllQuestionOptional = true
    section.questions.forEach((question) => {
      const type = question.formats[0].type
      if (question.questionBranch) {
        const branch = question.questionBranch
        const branchTargetValue = filledValueMap.get(branch.sectionNo)?.get(branch.questionNo)
        question.isVisible = branchTargetValue === branch.answerNo
        isAnyQuestionVisible = isAnyQuestionVisible || question.isVisible
        if (!question.isVisible) return
      } else {
        isAnyQuestionVisible = true
      }

      const isOptional = type === QuestionType.OptionalEmail || type === QuestionType.OptionalTextArea
      isAllQuestionOptional = isAllQuestionOptional && isOptional

      if (isOptional) return

      const isFilled = typeof filledValueMap.get(section.no)?.get(question.no) !== 'undefined'
      isAllRequiredQuestionFilled = isAllRequiredQuestionFilled && isFilled
    })

    // すべての問題が非表示の場合、セクションも非表示
    if (!isAnyQuestionVisible) {
      section.isVisible = false
      return
    }

    // すべての必須問題が回答済みの場合、かつすべての問題がオプションではないの場合、セクションを表示
    if (isAllRequiredQuestionFilled && !isAllQuestionOptional) {
      section.isVisible = true
      return
    }

    // 一部未記入のセクションが見つかった場合
    if (!isFillingSectionFound) {
      if (!isAllQuestionOptional) {
        // 記入中のセクションを見つけた
        isFillingSectionFound = true
      }
      section.isVisible = true
      return
    }

    // 全く未記入のセクション
    section.isVisible = false
  })

  return [...sections]
}

const Questionnaire: React.FC<IQuestionnaireProps> = (props) => {
  const history = useHistory()
  const [form] = Form.useForm();
  const { preview, loading, error, questionnaire, answererStatus, alerts, setAlerts } = props
  const [sections, setSections] = useState<FormattedSection[]>([])

  useEffect(() => {
    form.resetFields();
    const formattedSections: FormattedSection[] =
      questionnaire?.sections.map((section) => ({
        ...section,
        isVisible: true,
        questions: [
          ...section.questions.map((question) => ({
            ...question,
            isVisible: question.questionBranch ? false : true,
            formats: _.chain(question.questionFormats)
              .groupBy((questionFormat) => questionFormat.type)
              .map((questionFormats, type) => ({
                type: parseInt(type) as QuestionType,
                data: questionFormats,
              }))
              .value(),
          })),
        ],
      })) ?? []

    setSections(ComputeVisibleQuestions(formattedSections, [], []))
  }, [form, questionnaire])

  let previousChangedFields: FieldData[] = []
  const onFieldsChange = (changedFields: FieldData[], allFields: FieldData[]) => {
    // 一つの入力に対して、onFieldsChange は複数回呼ばれるため、変更がない場合は処理をスキップ
    // https://4x.ant.design/components/form/#Why-does-onFieldsChange-trigger-three-times-on-change-when-field-sets-rules?
    if (_.isEqual(changedFields, previousChangedFields)) return

    setSections(ComputeVisibleQuestions(sections, changedFields, allFields))
    previousChangedFields = changedFields
  }

  const isAnswered = answererStatus === AnswerStatus.Answered
  const isMobile = window.innerWidth < 480

  return (
    <div className="questionnaire-root">
      {alerts && setAlerts && (
        <div style={{ marginBottom: 16 }}>
          <Alert
            message={(() => {
              const errors = _.uniqBy(alerts?.errorFields ?? [], (e) => {
                return JSON.stringify(e.name)
              })
              let errorMessage = `入力項目に${errors.length}箇所入力もれがあります`
              /*alerts?.errorFields?.forEach(errorField => {
                      errorField.errors.forEach(error => {
                        errorMessage += error + '\n'
                      })
                    })*/
              return errorMessage
            })()}
            type="error"
            closable
            showIcon={true}
            onClose={() => setAlerts(null)}
          />
        </div>
      )}
      <Card className="questionnaire-title">
        <div className="questionnaire-title__header" />
        {!error ? (
          <Skeleton loading={loading} active>
            {isAnswered ? (
              <>
                <Title level={isMobile ? 5 : 2}>回答ありがとうございました。</Title>
                <Text strong>このアンケートは回答いただいています</Text>
              </>
            ) : (
              <>
                <Title level={2}>{questionnaire?.title}</Title>
                <Text strong>{questionnaire?.description}</Text>
              </>
            )}
          </Skeleton>
        ) : (
          <>
            <Title level={2}>エラーが発生しました。</Title>
            <Text strong>お手数をおかけいたしますが、CS事務局（メール内記載）へお問い合わせください。</Text>
          </>
        )}
      </Card>
      <Form<AnswerQuestionnaire>
        form={form}
        onFinish={props.onFinish}
        onFinishFailed={props.onFinishFailed}
        onFieldsChange={onFieldsChange}
      >
        {!error &&
          !loading &&
          sections
            .filter((section) => section.isVisible)
            .map((section, index) => {
              const sectionNo = section.no
              return (
                <Card key={index} style={{ marginBottom: 16, borderRadius: 8 }}>
                  <Title level={5} style={{ whiteSpace: 'pre-line' }}>
                    {section.text}
                  </Title>
                  {section.questions
                    .filter((question) => question.isVisible)
                    .map((question, index) => {
                      const questionText = question.text
                      const questionNo = question.no
                      const isLastQuestion = section.questions.length - 1 === index
                      const isFlow = question.formats.find((format) => format.type === QuestionType.Flow)
                      return (
                        <Skeleton key={index} loading={loading} active>
                          <div className="questionnaire-form-content" style={{ marginBottom: isFlow ? 8 : 32 }}>
                            {question.formats.map((format, index) => {
                              return (
                                <div key={index} className="questionnaire-form-item">
                                  {QuestionComponent(format, sectionNo, questionNo, questionText, isLastQuestion)}
                                </div>
                              )
                            })}
                          </div>
                        </Skeleton>
                      )
                    })}
                </Card>
              )
            })}
        {!loading &&
          !error &&
          (preview ? (
            <Form.Item>
              <Button type="primary" htmlType="button" onClick={() => history.goBack()}>
                戻る
              </Button>
            </Form.Item>
          ) : !isAnswered ? (
            <Form.Item>
              <Button type="primary" htmlType="submit">
                送信する
              </Button>
            </Form.Item>
          ) : null)}
      </Form>
    </div>
  )
}

export default Questionnaire
