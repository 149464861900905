import * as React from 'react'
import { Button, Modal } from 'antd'
import './QuestionnaireDeliveryDuplicateAlertModal.less'
import DeliveryDuplicateAlertTable from './components/DeliveryDuplicateAlertTable'

export interface IQuestionnaireDeliveryDuplicateAlertModalProps {
  isShowQuestionnaireDeliveryDuplicateAlertModal: boolean
  setIsShowQuestionnaireDeliveryDuplicateAlertModal: React.Dispatch<React.SetStateAction<boolean>>
  deliveryDuplicateCsv: Answerer[] | [] // 配信リストのCSVの重複しているアンケート
  deliveryDuplicateDb: Answerer[] | [] // 配信リストのデータベースの重複のしているアンケート
  handleUpload: () => Promise<void>
  loading: boolean
}

const QuestionnaireDeliveryDuplicateAlertModal: React.FC<IQuestionnaireDeliveryDuplicateAlertModalProps> = (props) => {
  const {
    isShowQuestionnaireDeliveryDuplicateAlertModal,
    setIsShowQuestionnaireDeliveryDuplicateAlertModal,
    deliveryDuplicateCsv,
    deliveryDuplicateDb,
    handleUpload,
    loading
  } = props

  const handleOk = async (e: any) => {
    await handleUpload()
  }

  const handleCancel = (e: any) => {
    console.log(e)
    setIsShowQuestionnaireDeliveryDuplicateAlertModal(false)
  }

  return (
    <div className="questionnaire-delivery-duplicate-alert-modal">
      <Modal
        title={'配信リストの重複のエラー'}
        visible={isShowQuestionnaireDeliveryDuplicateAlertModal}
        onCancel={handleCancel}
        width={'50vw'}
        bodyStyle={{ height: '72vh', overflowY: 'scroll' }}
        footer={[
          <Button key="back" onClick={handleCancel}>
            キャンセル
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
            登録する
          </Button>,
        ]}
      >
        <DeliveryDuplicateAlertTable
          deliveryDuplicateCsv={deliveryDuplicateCsv}
          deliveryDuplicateDb={deliveryDuplicateDb}
        />
      </Modal>
    </div>
  )
}

export default QuestionnaireDeliveryDuplicateAlertModal
