import React, {PropsWithChildren} from "react"
import {Form, Input, Typography} from "antd"
import {QuestionType} from "constance/constance.display"

type IProps = {
  sectionNo: number
  questionNo: number
  questionText: string
  required?: boolean
}

const {Text} = Typography

const TextAreaComponent = ({sectionNo, questionNo, questionText, required = true}: PropsWithChildren<IProps>) => {
  return (
      <>
        <div className="questionnaire-form__text-wrapper">
          <Text className="questionnaire-form__text">{questionText}</Text>
        </div>

        <Form.Item
            className="questionnaire-form-item"
            name={[sectionNo, questionNo, QuestionType.OptionalTextArea]}
            rules={[{required, message: '必ず入力してください。'}]}
        >
          <Input.TextArea
              className="questionnaire-form__textarea"
              placeholder="自由記述"
              autoSize={{minRows: 5, maxRows: 500}}
          />
        </Form.Item>
      </>
  )
}

export default TextAreaComponent