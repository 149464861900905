import * as React from 'react'
import { useState } from 'react'
import { Button, message, Upload } from 'antd'
import csvIcon from 'assets/csv-icon.png'
import './CsvUpload.less'
import axios from "axios"

export interface ICsvUploadProps<T> {
  fileList: [],
  setFileList: any,
  loading?: boolean // DatePickerがBlankかどうか
  callback: any
}

const { Dragger } = Upload

const CsvUpload = <T,>(props: ICsvUploadProps<T>) => {
  const { loading } = props

  const draggerProps = {
    onRemove: (file: never) => {
      const index = props.fileList.indexOf(file)
      const newFileList = props.fileList.slice()
      newFileList.splice(index, 1)
      props.setFileList(newFileList)
    },
    beforeUpload: (file: never) => {
      console.log(file)
      props.setFileList([file])
      return false
    },
  } as any

  return (
    <div className="csv-upload">
      <div className="csv-upload__content">
        <Dragger {...draggerProps} fileList={props.fileList}>
          <p className="ant-upload-drag-icon">
            <img width={40} src={csvIcon} alt="csv-icon" />
          </p>
          <p className="ant-upload-text">クリックもしくはドラックをして、CSVファイルを選択してください。</p>
          <p className="ant-upload-hint">※ CSVファイル以外は選択しないでください。</p>
        </Dragger>
      </div>
      <div className="csv-upload__footer">
        <Button
          block
          type="primary"
          className="purchase-confirm-button"
          onClick={props.callback}
          loading={loading}
        >
          {loading ? 'アップロード中...' : '登録する'}
        </Button>
      </div>
    </div>
  )
}

export default CsvUpload
