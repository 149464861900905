import Service from 'helper/helper.service'
import config from 'config/config.setting'

/**
 * @extends {Service}
 */
class TagService extends Service {
  /**
   *
   * @param hash
   * @param params
   */

  // async getByHash<T = DeliveryResponseDto>(hash: string, params?: QueryParams) {
  //   const encoded = encodeURIComponent(hash)
  //   return await this.get<T>(config.endpoints.questionnaireDeliveryAnswer(encoded), params)
  // }

  /**
   * @returns
   */
  async getList<T = TagsResponse>(params?: QueryParams) {
    return await this.get<T>(config.endpoints.tag, params)
  }

  /**
   * @returns
   */
  async create<T = TagCreateResponse, U = TagCreateRequest>(data: U, params?: QueryParams) {
    return await this.post<T, U>(config.endpoints.tag, data, params)
  }

  /**
   * @returns
   */
  async update<T = TagUpdateResponse, U = TagUpdateRequest>(data: U, params?: QueryParams) {
    return await this.put<T, U>(config.endpoints.tag, data, params)
  }

  /**
   * @returns
   */
  async remove<T = TagUpdateResponse>(id: string, params?: QueryParams) {
    return await this.delete<T>(config.endpoints.tag, id, params)
  }

  /**
   * @returns
   */
  async deactivate(data: TagUpdateRequest, params?: QueryParams) {
    return await this.patch<TagUpdateResponse, TagUpdateRequest>(config.endpoints.tagDeactivated, data, params)
  }

  /**
   *
   * @param data
   * @param params
   */
  async activate(data: TagUpdateRequest, params?: QueryParams) {
    return await this.patch<TagUpdateResponse, TagUpdateRequest>(config.endpoints.tagActivated, data, params)
  }

  // 同期できなかったタグ(ファイル等)を取得
  async getUnSyncedTagList<T = TagUnSyncedResponse>(params?: QueryParams) {
    return await this.get<T>(config.endpoints.tagUnSynced, params)
  }

  // タグの同期の実行
  async synchronizeExec<T = SynchronizeExecResponse, U = { fileName: string }>(fileName: string, params?: QueryParams) {
    return await this.post<T, string>(config.endpoints.tagSynchronizeExec(fileName), fileName, params)
  }

  // タグの同期をしないをい実行する
  async unSynchronizeExec<T = SynchronizeExecResponse, U = { fileName: string }>(
    fileName: string,
    params?: QueryParams
  ) {
    return await this.post<T, string>(config.endpoints.tagUnSynchronizeExec(fileName), fileName, params)
  }
}

export default new TagService()
