import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { message } from 'antd'
import {
  CustomerServiceOutlined,
  FileTextOutlined,
  LogoutOutlined,
  MailOutlined,
  TagsOutlined,
} from '@ant-design/icons'
import Footer from 'components/Footer'
import PageHeader from 'components/PageHeader'
import * as config from 'config'
import storage from 'helper/helper.storage'
import Logo from '../assets/logo.png'
import { Layout, Header, Sider, Content, LogoContainer, Menu } from './AuthorizedLayout.style'

const selectOpenKeys = (pathname: string) => {
  switch (pathname) {
    case config.setting.paths.staffQuestionnaireList:
      return ['sub1']
    case config.setting.paths.staffRegisterQuestionnaire:
      return ['sub1']
    case config.setting.paths.staffAnswerList:
      return ['sub1']
    case config.setting.paths.staffDeliveryList:
      return ['sub1']
    default:
      return []
  }
}

const AuthorizedLayout: React.FC<any> = ({ children }) => {
  const history = useHistory()
  const location = useLocation()
  const [selectedKeys, setSelectedKeys] = useState<string[]>([location.pathname])
  const [selectedOpenKeys, setSelectedOpenKeys] = useState<string[]>(selectOpenKeys(location.pathname))

  const selectMenu = useCallback(
    (key: string) => {
      if (key === config.setting.paths.logout) {
        message.success('ログアウトしました')
        // $apis.auth.logout({}).then(console.log)
        storage.clear().then(() => {
          history.push(config.setting.paths.login)
          window.location.reload()
        })
      } else {
        if (history.location.pathname !== key) history.push(key)

        setSelectedKeys([key])
        setSelectedOpenKeys(selectOpenKeys(key))
      }
    },
    [history]
  )

  const handleClick = (current: any) => {
    selectMenu(current.key)
  }

  useEffect(() => {
    selectMenu(location.pathname)
  }, [location, selectMenu])

  return (
    <Layout>
      <Header>
        <LogoContainer>
          <Link to={config.setting.paths.staffQuestionnaireList}>
            <img src={Logo} alt="Logo" />
          </Link>
        </LogoContainer>
      </Header>
      <Layout>
        <Sider
          width={200}
          theme="light"
          breakpoint="md"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            console.log(broken)
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type)
          }}
        >
          <Menu mode="inline" defaultOpenKeys={selectedOpenKeys} selectedKeys={selectedKeys} onClick={handleClick}>
            <Menu.Item key={config.setting.paths.staffQuestionnaireList} icon={<FileTextOutlined />}>
              アンケート
            </Menu.Item>
            <Menu.Item key={config.setting.paths.staffDeliveryList} icon={<MailOutlined />}>
              アンケート配信
            </Menu.Item>
            <Menu.Item key={config.setting.paths.staffHiddenQuestionnaireList} icon={<FileTextOutlined />}>
              非表示アンケート
            </Menu.Item>
            <Menu.Item key={config.setting.paths.staffAnswererList} icon={<CustomerServiceOutlined />}>
              お客様配信一覧
            </Menu.Item>
            <Menu.Item key={config.setting.paths.staffTagList} icon={<TagsOutlined />}>
              LMD連携タグ一覧
            </Menu.Item>
            <Menu.Item key={config.setting.paths.logout} icon={<LogoutOutlined />}>
              ログアウト
            </Menu.Item>
          </Menu>
        </Sider>

        <Layout style={{ padding: '0 24px 24px' }}>
          <div style={{ margin: '16px 0' }}>
            <PageHeader />
          </div>
          <Content
            // className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            {children}
          </Content>
          <Footer />
        </Layout>
      </Layout>
    </Layout>
  )
}

export default AuthorizedLayout
