import Service from 'helper/helper.service'
import config from 'config/config.setting'

/**
 * @extends {Service}
 */
class DeliveryService extends Service {

  /**
   *
   * @param hash
   * @param params
*/

  async getByHash<T = DeliveryResponseDto>(hash: string, params?: QueryParams) {
    const encoded = encodeURIComponent(hash)
    return await this.get<T>(config.endpoints.questionnaireDeliveryAnswer(encoded), params)
  }

  /**
   * @returns
   */
  async getList<T = DeliveryListResponseDto>(params?: QueryParams) {
    return await this.get<T>(config.endpoints.questionnaireDeliveries, params)
  }

  /**
   *
   * @param hash
   * @param params
   */
  async csvReport<T = DeliveryReportCsvResponseDto>(hash: string, params?: QueryParams) {
    const encoded = encodeURIComponent(hash)
    return await this.get<T>(config.endpoints.questionnaireDeliverCSV(encoded), params)
  }

  /**
   * @returns
   */
  async cancel<T = DeliveryCancelResponseDto, U = DeliveryCancelRequestDto>(data: U, params?: QueryParams) {
    return await this.post<T, U>(config.endpoints.questionnaireDeliveryCancel, data, params)
  }

  /**
   *
   * @param data
   * @param params
   */
  async answer<T = DeliveryAnswerResponseDto, U = DeliveryAnswerRequestDto>(data: U, params?: QueryParams) {
    return await this.post<T, U>(config.endpoints.questionnaireAnswer, data, params)
  }
}

export default new DeliveryService()
