import * as React from 'react'
import { Modal, message } from 'antd'
import * as $apis from 'apis'
import Paragraph from 'antd/es/typography/Paragraph'
import { useState } from 'react'
import { formatDate } from 'helper/helper.moment'
import { Text } from './style'

export interface ISyncedConfirmModalProps {
  isDisplayModal: boolean
  setIsDisplayModal: React.Dispatch<React.SetStateAction<boolean>>
  selectUnSyncedTag: UnSyncedTag
  getUnSyncedTags: () => void
}

// 同期確認のモーダル
const SyncedConfirmModal: React.FC<ISyncedConfirmModalProps> = (props) => {
  const [isSynchronizeExecFin, setIsSynchronizeExecFin] = useState<boolean>(false) // 同期が完了した時に表示するもの
  const [finSynchronizedTag, setFinSynchronizedTag] = useState<FinSynchronizeTag>() // タグの同期が完了した後のレスポンス
  const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false)

  const synchronizeExec = async () => {
    setConfirmLoading(true)
    try {
      const res = await $apis.tag.synchronizeExec(props.selectUnSyncedTag?.fileName)
      setFinSynchronizedTag(res.data)
      message.success(`${props.selectUnSyncedTag?.fileName}ファイルの同期に成功しました`)
      setIsSynchronizeExecFin(true)

      await props.getUnSyncedTags()
    } catch (e) {
      message.error(e.response.data)
    } finally {
      setConfirmLoading(false)
    }
  }

  // 同期が完了した後にokボタンを押したときの関数
  const fin = () => {
    props.setIsDisplayModal(false)
    setIsSynchronizeExecFin(false)
  }

  const handleCancel = (e: any) => {
    props.setIsDisplayModal(false)
    setIsSynchronizeExecFin(false)
  }

  return (
    <div className="synced-confirm-modal">
      <Modal
        title={
          isSynchronizeExecFin ? (
            <div style={{ maxWidth: 460 }}>{props.selectUnSyncedTag?.fileName}ファイルの同期に完了しました。</div>
          ) : (
            <div style={{ maxWidth: 460 }}>{props.selectUnSyncedTag?.fileName}ファイルを同期しますか？</div>
          )
        }
        visible={props.isDisplayModal}
        onOk={isSynchronizeExecFin ? fin : synchronizeExec}
        onCancel={handleCancel}
        cancelText={<></>}
        confirmLoading={confirmLoading}
        okText={isSynchronizeExecFin ? '閉じる' : '同期する'}
      >
        {/*モーダル*/}
        {isSynchronizeExecFin || (
          <ul className="modal-confirm-content">
            <li>
              <Paragraph>
                <span>未連携ファイル名</span>
                <span>{props.selectUnSyncedTag?.fileName}</span>
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <span>推奨タグ名</span>
                <span>{props.selectUnSyncedTag?.format}</span>
              </Paragraph>
            </li>
          </ul>
        )}

        {isSynchronizeExecFin && (
          <>
            <Text mb={20} fw={700}>
              以下同期された{props.selectUnSyncedTag?.fileName}ファイルの詳細情報。
            </Text>

            <ul className="modal-confirm-content">
              <li>
                <Paragraph>
                  <span>ラベル名</span>
                  <span>{props.selectUnSyncedTag?.format}</span>
                </Paragraph>
              </li>

              <li>
                <Paragraph>
                  <span>アンケート名</span>
                  <span>{finSynchronizedTag?.title}</span>
                </Paragraph>
              </li>

              <li>
                <Paragraph>
                  <span>登録人数</span>
                  <span>{finSynchronizedTag?.answererCount}</span>
                </Paragraph>
              </li>

              <li>
                <Paragraph>
                  <span>登録日</span>
                  <span>{formatDate(finSynchronizedTag?.answerers[0]?.createdAt)}</span>
                </Paragraph>
              </li>
            </ul>
          </>
        )}
      </Modal>
    </div>
  )
}

export default SyncedConfirmModal
