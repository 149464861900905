import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  background: #f6f6f6;
  position: relative;
`

export const Contents = styled.div`
  //background: pink;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  width: 90%;

  @media (min-width: 768px) {
    font-size: 32px;
    top: 40%;
  }
`

export const Title = styled.h3`
  font-size: 24px;
  text-align: center;
  font-weight: 700;

  @media (min-width: 768px) {
    font-size: 32px;
  }
`

export const Text = styled.p<{ mt?: number }>`
  font-size: 16px;
  font-weight: 400;
  margin-top: ${(props) => props.mt}px;
  margin-bottom: 0;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`

export const BrSp = styled.br`
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
`

export const BrPc = styled.br`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`

export const SPNone = styled.span`
  display: none;

  @media (min-width: 768px) {
    display: inline-block;
  }
`
