import { Role } from 'constance/constance.role'
import Error404 from 'presenters/Error/404'
import * as config from 'config'
import Error500 from 'presenters/Error/500'
import Maintenance from 'presenters/Error/Maintenance'
import { IRoute } from './index'
import UnAuthorizedLayout from '../layouts/UnAuthorizedLayout'

const publicRoutes = [
  {
    exact: true,
    role: Role.All,
    layout: UnAuthorizedLayout,
    component: Error404,
    path: config.setting.paths.error404,
  },
  {
    exact: true,
    role: Role.All,
    layout: UnAuthorizedLayout,
    component: Error500,
    path: config.setting.paths.error500,
  },
] as IRoute[]

export default publicRoutes
