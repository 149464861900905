import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import Table from 'components/Table'
import UnSyncedTagTable from 'components/UnSyncedTagTable'
import { TagFieldsContext } from 'contexts'
import * as $apis from 'apis'
import { getSubTitleByPath, getTitleByPath } from 'helper/helper.breadcrumb'
import * as config from 'config'
import useBreadcrumbs from 'hooks/hooks.useBreadcrumb'
import { useHistory, useLocation } from 'react-router-dom'
import { Button } from 'antd'
import styled from 'styled-components'
import { Tag as AntTag } from 'antd'
import { formatDate } from '../../../helper/helper.moment'
import ConfirmModalButton from 'components/ConfirmModalButton'
import RemoveConfirmModalButton from 'components/RemoveConfirmModalButton'
import { SyncOutlined } from '@ant-design/icons'

export interface ITagListProps {}

const Container = styled.div``
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  > * {
    margin-left: 24px;
  }
`

const TagList: React.FC<ITagListProps> = (props) => {
  const location = useLocation()
  const history = useHistory()
  const [list, setList] = useState<Tag[]>([])
  const [loading, setLoading] = useState(true)
  const { setBreadcrumbs, setTitle, setSubTitle } = useBreadcrumbs()
  const { setCsvFormat } = useContext(TagFieldsContext)

  const [selectTag, setSelectTag] = useState<Tag | any>(undefined) // ボタンをクリックして選択されたタグ
  const [isDisplayUnSyncedTagList, setIsDisplayUnSyncedTagList] = useState<boolean>(false) // 同期できてないタグリスト
  // const [isDisplayUnSyncedTagList, setIsDisplayUnSyncedTagList] = useState<boolean>(true) // 開発中はtrue 同期できてないタグリスト
  const [isDisplayTagStatusModal, setIsDisplayTagStatusModal] = useState<boolean>(false) // ボタンを押したときに有効・無効の確認モーダルを表示するかどうか
  const [isDisplayTagRemoveModal, setIsDisplayTagRemoveModal] = useState<boolean>(false) // ボタンを押したときに削除モーダルを表示するかどうか

  const [tagName, setTagName] = useState<string>('') //タグの名前 modal内で使用
  const [questionnaireTitle, setQuestionnaireTitle] = useState<string>('') //アンケートの名前 modal内で使用
  const [isDeactivated, setIsDeactivated] = useState<boolean>(false) // これからタグが有効・無効どちらにする予定か

  const getTags = async () => {
    try {
      const response = await $apis.tag.getList()
      setList(response.data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getTags()
  }, [])

  useEffect(() => {
    setSubTitle(getSubTitleByPath(config.setting.paths.staffTagList))
    setTitle(getTitleByPath(config.setting.paths.staffTagList))
    setBreadcrumbs([
      {
        path: config.setting.paths.staffTagList,
        breadcrumbName: getTitleByPath(config.setting.paths.staffTagList),
      },
    ])
  }, [location.pathname, setBreadcrumbs, setSubTitle, setTitle])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      filterMultiple: false,
      onFilter: (value: any, tag: Tag) => false,
    },
    {
      title: 'ラベル名',
      dataIndex: 'name',
      key: 'name',
      filterMultiple: false,
      onFilter: (value: any, tag: Tag) => false,
      // sorter: (a: Tag, b: Tag) => orderByText(a.name, b.name),
    },
    {
      title: 'タグ名',
      dataIndex: 'csvFormat',
      key: 'csvFormat',
      filterMultiple: false,
      onFilter: (value: any, tag: Tag) => false,
    },
    {
      title: 'ステータス',
      dataIndex: 'deactivated',
      key: 'deactivated',
      filterMultiple: false,
      render: (_: string, tag: Tag) => {
        const text = !tag.deactivated ? '有効' : '無効'
        const color = !tag.deactivated ? 'cyan' : 'magenta'
        return <AntTag color={color}>{text}</AntTag>
      },
    },
    {
      title: 'アンケート',
      dataIndex: 'questionnaire',
      key: 'questionnaire',
      filterMultiple: false,
      onFilter: (value: any, tag: Tag) => false,
      render: (text: string, tag: Tag) => {
        return (
          <p>
            {tag.questionnaire?.id}: {tag.questionnaire?.title}
          </p>
        )
      },
    },
    {
      title: '作成日',
      dataIndex: 'createdAt',
      key: 'createdAt',
      filterMultiple: false,
      onFilter: (value: any, tag: Tag) => false,
      render: (text: string, tag: Tag) => {
        return <p>{formatDate(tag.createdAt)}</p>
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      filterMultiple: false,
      onFilter: (value: any, tag: Tag) => false,
      render: (_: string, tag: Tag) => {
        const text = !tag.deactivated ? '無効' : '有効'
        const type = !tag.deactivated ? 'default' : 'primary'
        const danger = !tag.deactivated

        const onClick = () => {
          setSelectTag(tag)
          setIsDeactivated(tag.deactivated)
          setIsDisplayTagStatusModal(true)
          setQuestionnaireTitle(tag.questionnaire?.title ?? '')
          setTagName(tag.name ?? '')
        }

        return (
          <div>
            <Button danger={danger} type={type} htmlType={'button'} onClick={onClick}>
              {text}
            </Button>
          </div>
        )
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      filterMultiple: false,
      onFilter: (value: any, tag: Tag) => false,
      render: (_: string, tag: Tag) => {
        const onClick = () => {
          setSelectTag(tag)
          setIsDisplayTagRemoveModal(true)
          setQuestionnaireTitle(tag.questionnaire?.title ?? '')
          setTagName(tag.name ?? '')
        }

        return (
          <div>
            <Button danger={true} type={'primary'} htmlType={'button'} onClick={onClick}>
              削除
            </Button>
          </div>
        )
      },
    },
  ]

  const onClick = () => {
    history.push(config.setting.paths.staffTagCreate)
    // 同期されていないタグリストから、タグを登録した場合にCSVフォーマットのデータが残っている場合があるので初期化
    setCsvFormat('')
  }

  return (
    <Container>
      <ConfirmModalButton
        isDisplayModal={isDisplayTagStatusModal}
        setIsDisplayModal={setIsDisplayTagStatusModal}
        selectTag={selectTag}
        getTags={getTags}
      />

      <RemoveConfirmModalButton
        isDisplayModal={isDisplayTagRemoveModal}
        setIsDisplayModal={setIsDisplayTagRemoveModal}
        selectTag={selectTag}
        getTags={getTags}
      />

      <ButtonContainer>
        {isDisplayUnSyncedTagList ? (
          <Button htmlType="button" onClick={() => setIsDisplayUnSyncedTagList(false)}>
            <SyncOutlined />
            LMD連携タグ一覧を表示
          </Button>
        ) : (
          <Button htmlType="button" onClick={() => setIsDisplayUnSyncedTagList(true)}>
            <SyncOutlined />
            LMD連携の同期されていないタグ一覧を表示
          </Button>
        )}

        <Button type="primary" htmlType="button" onClick={onClick}>
          LMD連携タグを登録する
        </Button>
      </ButtonContainer>

      {isDisplayUnSyncedTagList ? (
        <UnSyncedTagTable />
      ) : (
        <Table loading={loading} columns={columns} data={list} rowKey={(record) => record.id.toString()} />
      )}
    </Container>
  )
}

export default TagList
