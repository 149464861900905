import moment from 'moment'
import storage from 'helper/helper.storage'

class AuthService {

  constructor() {
  }

  async logout() {
  }

  /**
   * ログイン状態の定義
   * ① sessionが有効かつtokenが有効
   * → sessionの有効性→walletインスタンスが作成できるか
   * → tokenの有効性→有効期限通りか
   * ② defaultAddress で session、tokenが取得できない→ ログアウト状態
   */
  isLoggedIn(): boolean {
    const accessToken = storage.token;
    if (accessToken) {
      try {
        // const sessionAvailable = await this.isSessionOk(session);
        const loggedin = this.isAccessTokenOk(accessToken); /*&& sessionAvailable*/
        if (!loggedin) {
          this.clearSession();
        }
        return loggedin;
      } catch (e: any) {
        // 3. 不正なトークンである
        this.clearSession();
        return false;
      }
    } else {
      // 1. トークンもしくはセッションが存在しない
      this.clearSession();
      return false;
    }
  }

  clearSession() {
    // storage.clear().then(console.log);
  }

  isAccessTokenOk = (token: string): boolean => {
    if (token) {
      const encodedToken = Buffer.from(token.split('.')[1], 'base64').toString();
      const expiredUnixTime = JSON.parse(encodedToken).exp - 60 * 5; // 5 min
      return expiredUnixTime > Number(moment().format('X'));
    } else {
      return false;
    }
  };
}

export default new AuthService()
