import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Button, Typography } from 'antd'
import { useLocation } from 'react-router-dom'
import * as config from 'config'
import * as $apis from 'apis'
import useBreadcrumbs from 'hooks/hooks.useBreadcrumb'
import { getSubTitleByPath, getTitleByPath } from 'helper/helper.breadcrumb'
import { formatDate } from 'helper/helper.moment'
import './ClientDeliveryList.less'
import AnswererList from 'components/AnswererList'
import CancelDeliveryModal from 'components/CancelDeliveryModal/CancelDeliveryModal'
import { SubscribeType } from 'constance'
import styled from 'styled-components'

export interface IClientDeliveryListProps {
  match: {
    params: {
      hash: string
    }
  }
}

const { Text } = Typography

const TipsContainer = styled.div`
  textalign: left;
  margin-bottom: 16px;
  font-size: 12px;

  .ant-btn-sm {
    font-size: 11px;
  }
`

const ClientDeliveryList: React.FC<IClientDeliveryListProps> = (props) => {
  const location = useLocation<{ questionnaire: Questionnaire }>()
  const [list, setList] = useState<Answerer[]>([])
  const [answerer, setAnswerer] = useState<Answerer | undefined>(undefined)
  const [visible, setVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const { setBreadcrumbs, setTitle, setSubTitle } = useBreadcrumbs()
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(50)
  const [totalItemCount, setTotalItemCount] = useState<number>(0)
  const [cancelDeliveryType, setCancelDeliveryType] = useState<SubscribeType>(SubscribeType.Email)
  // アンケートに紐づく顧客情報を取得するため、groupPathが必要なため
  const hash = props.match.params.hash

  const onPaginationChange = async (pageNumber: number, pageSize?: number) => {
    try {
      setLoading(true)
      const res = await $apis.client.getList(hash, {
        pageSize,
        pageNumber,
      })
      setPageNumber(res.data.pageNumber)
      setPageSize(res.data.pageSize)
      setList(res.data.contents)
      setTotalItemCount(res.data.totalItemCount)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const showCancelDeliveryModal = (client: Answerer, subscribeType: SubscribeType) => {
    setVisible(true)
    setAnswerer(client)
    setCancelDeliveryType(subscribeType)
  }

  useEffect(() => {
    // hashを削除したエンドポイントを取得する
    const path = location.pathname.replace(`/${hash}`, '')
    setSubTitle(getSubTitleByPath(path))
    setTitle(getTitleByPath(path))
    setBreadcrumbs([
      {
        path: config.setting.paths.staffQuestionnaireList,
        breadcrumbName: getTitleByPath(config.setting.paths.staffQuestionnaireList),
      },
    ])
  }, [hash, location.pathname, setBreadcrumbs, setSubTitle, setTitle])

  const effectQuery = useCallback(async () => {
    try {
      setLoading(true)
      const res = await $apis.client.getList(hash, { pageSize, pageNumber })
      setList(res.data.contents)
      setPageNumber(res.data.pageNumber)
      setPageSize(res.data.pageSize)
      setTotalItemCount(res.data.totalItemCount)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [hash, pageSize, pageNumber])

  useEffect(() => {
    effectQuery().then((r) => console.log(''))
  }, [])

  return (
    <>
      <CancelDeliveryModal
        subscribeType={cancelDeliveryType}
        visible={visible}
        setVisible={setVisible}
        callback={effectQuery}
        setAnswerer={setAnswerer}
        answerer={answerer}
      />
      <div className={'client-delivery-list-questionnaire-title'}>
        <Text>アンケート名： {list[0]?.questionnaire.title}</Text>
      </div>
      <div className={'client-delivery-list-questionnaire-delivery-date'}>
        <Text>配信登録日：{formatDate(list[0]?.createdAt)}</Text>
      </div>
      <div className={'client-delivery-list-questionnaire-delivery-date'}>
        <Text>配信予定日：{formatDate(list[0]?.sentAt)}</Text>
      </div>
      <TipsContainer>
        <Button danger size="small" shape="circle" style={{ cursor: 'default' }}>
          ■
        </Button>
        &nbsp;を押すことで、メールまたはSMSの配信を停止することができます。
      </TipsContainer>
      <AnswererList
        list={list}
        loading={loading}
        pageSize={pageSize}
        pageNumber={pageNumber}
        showCancelDeliveryModal={showCancelDeliveryModal}
        totalItemCount={totalItemCount}
        onChange={onPaginationChange}
      />
    </>
  )
}

export default ClientDeliveryList
