import {Role} from "constance/constance.role"
import * as config from "config"
import UnAuthorizedLayout from "layouts/UnAuthorizedLayout"
import Login from "presenters/Auth/Login"
import {IRoute} from "./index"


const unAuthorizedRoutes = [
  {
    exact: true,
    role: Role.StaffGuest,
    layout: UnAuthorizedLayout,
    component: Login,
    path: config.setting.paths.login
  }
] as IRoute[]


export default unAuthorizedRoutes
