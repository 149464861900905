import Service from 'helper/helper.service'
import config from 'config/config.setting'

/**
 * @extends {Service}
 */
class EnvironmentService extends Service {
  /**
   * @returns
   */
  async environment<T = EnvironmentResponse>() {
    return await this.get<T>(config.endpoints.environment)
  }

  /**
   * @returns
   */
  async signUp<T = SignUpResponseDto, U = SignUpRequestDto>(data: U, params?: QueryParams) {
    return await this.post<T, U>(config.endpoints.signUp, data)
  }

  /**
   * @returns
   */
  async logout<T = LogoutResponseDto, U = LogoutRequestDto>(data: U, params?: QueryParams) {
    return await this.post<T, U>(config.endpoints.logout, data, params)
  }
}

export default new EnvironmentService()
