import Service from 'helper/helper.service'
import config from 'config/config.setting'

/**
 * @extends {Service}
 */
class ContractService extends Service {
  /**
   * @returns
   */
  async getList<T = ContractsResponseDto>(params?: QueryParams) {
    return await this.get<T>(config.endpoints.contracts, params)
  }

  /**
   *
   */
  async getById<T = ContractResponseDto>(id: string) {
    return await this.get<T>(config.endpoints.contract(id))
  }
}

export default new ContractService()
