import styled from 'styled-components'
import { Layout as AntLayout } from 'antd'
import { colorCode } from "constance/constance.color"
import { mq } from "constance/constance.breakPoint"


export const Layout = styled(AntLayout)`
  // rgba表記なのでベタうち 透明度はUIをみながら調整
  background: ${colorCode.professionalLightBlue};
`


export const Header = styled(AntLayout.Header)`
  background: ${colorCode.professionalBlue};
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  z-index: 99;
  width: 100%;
`

export const Content = styled(AntLayout.Content)`
  margin: 16px 0;

  @media (${mq.md}) {
    margin: 96px 0;
  }

`


export const LogoContainer = styled.div`
  img {
    width: 180px;
    @media (${mq.md}) {
      width: 210px;
    }
  }
`



