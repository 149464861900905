import * as React from 'react'
import './Login.css'
import { useHistory } from 'react-router-dom'
import { Button, Form, Input, message } from 'antd'
import { LockOutlined, MailOutlined } from '@ant-design/icons'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import axios from 'axios'
import { auth as API } from 'apis'
import * as config from 'config'
import storage from 'helper/helper.storage'
import { useCallback, useEffect } from 'react'

export interface ILoginProps {}

const Login = () => {
  const history = useHistory()

  const [loading, setLoading] = React.useState<boolean>(false)

  const onFinish = async (values: IAuth) => {
    console.log('Success:', values)
    setLoading(true)
    try {
      const response = await API.login(values)
      console.log(response)
      const { accessToken } = response.data
      storage.token = accessToken
      axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`
      message.success('ログインしました')
      history.push(config.setting.paths.staffQuestionnaireList)
      window.location.reload()
    } catch (e) {
      message.error('ログインに失敗しました')
      console.log(e)
    } finally {
      setLoading(false)
    }
    // storage.token = 'test'
    // storage.role = String(Role.Staff)
    // history.push(config.setting.paths.staffQuestionnaireList)
    // window.location.reload()
  }

  const checkAuth = useCallback(() => {
    if (storage.token) {
      history.replace(config.setting.paths.staffQuestionnaireList)
      window.location.reload()
    }
  }, [history])

  useEffect(() => {
    checkAuth()
  }, [checkAuth])

  const onFinishFailed = (errorInfo: ValidateErrorEntity<IAuth>) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Form<IAuth>
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item name="email" rules={[{ required: true, message: 'メールアドレスを入力してください' }]}>
        <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="メールアドレス" />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: 'パスワードを入力してください' }]}>
        <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="パスワード" />
      </Form.Item>

      {/*<Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>記憶する</Checkbox>
        </Form.Item>

        <Link className="login-form-forgot" to="password-forgot">
          パスワードをお忘れの方はこちら
        </Link>
      </Form.Item>*/}

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
          ログイン
        </Button>
        {/*もしくは <Link to={config.setting.paths.signUp}>新規登録はこちら</Link>*/}
      </Form.Item>
    </Form>
  )
}

export default Login
