import * as React from 'react'
import { DatePicker } from 'antd'
import moment, { Moment } from 'moment'

export interface IDateOnlyPickerProps {
  disabledDate?:  (date: any) => boolean
  callback: (moment: Moment | null) => void
}

const DateOnlyPicker: React.FC<IDateOnlyPickerProps> = (props) => {
  const {
    callback,
    disabledDate
  } = props
  return (
    <DatePicker
      onChange={callback}
      format="YYYY年MM月DD日"
      style={{ marginRight: 16 }}
      disabledDate={disabledDate}
    />
  )
}

export default DateOnlyPicker
