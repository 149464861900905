import * as React from 'react'
import { useEffect } from 'react'
import Routes from './routes'
import BreadcrumbProvider from './providers/BreadcrumbProvider'
import { TagFieldsProvider } from './contexts/contexts.tagFields'
import { HashRouter as Router, useHistory } from 'react-router-dom'
import storage from 'helper/helper.storage'
import * as config from './config'
import moment from 'moment'
import Environment from './components/Environment'
import { Role } from './constance/constance.role'
import { Env } from 'constance'

function App() {
  const history = useHistory()
  useEffect(() => {
    if (storage.token) {
      try {
        const token = storage.token
        const encodedToken = Buffer.from(token.split('.')[1], 'base64').toString()
        const expiredUnixTime = JSON.parse(encodedToken).exp - 60 * 5 // 5 min
        if (expiredUnixTime < Number(moment().format('X'))) {
          storage.clear().then(() => {
            history?.push(config.setting.paths.login)
            window.location.reload()
          })
        }
      } catch (e) {
        storage.clear().then(() => {
          history?.push(config.setting.paths.login)
          window.location.reload()
        })
      }
    }
  }, [history])
  return (
    <BreadcrumbProvider>
      <TagFieldsProvider>
        <Router>{Routes()}</Router>
      </TagFieldsProvider>
      {config.setting.role === Role.Answerer && config.setting.env === Env.Production ? null : <Environment />}
    </BreadcrumbProvider>
  )
}

export default App
