import { Role } from 'constance/constance.role'
import * as config from 'config'
import { IRoute } from './index'
import AuthorizedLayout from 'layouts/AuthorizedLayout'
import Questionnaire from 'presenters/Staff/Questionnaire'
import QuestionnaireList from 'presenters/Staff/QuestionnaireList'
import RegisterQuestionnaire from 'presenters/Staff/RegisterQuestionnaire'
import UnAuthorizedLayout from 'layouts/UnAuthorizedLayout'
import Deliveries from 'presenters/Staff/Deliveries'
import ClientDeliveryList from 'presenters/Staff/ClientDeliveryList'
import RegisterDistributionList from 'presenters/Staff/RegisterDistributionList'
import HiddenQuestionnaireList from '../presenters/Staff/HiddenQuestionnaireList'
import AnswererList from '../presenters/Staff/AnswererList'
import TagList from 'presenters/Staff/TagList'
import TagCreate from 'presenters/Staff/TagCreate'

const staffRoutes = [
  {
    exact: true,
    role: Role.Staff,
    layout: AuthorizedLayout,
    component: QuestionnaireList,
    path: config.setting.paths.staffQuestionnaireList,
  },
  {
    exact: true,
    role: Role.Staff,
    layout: AuthorizedLayout,
    component: HiddenQuestionnaireList,
    path: config.setting.paths.staffHiddenQuestionnaireList,
  },
  {
    exact: true,
    role: Role.Staff,
    layout: AuthorizedLayout,
    component: Deliveries,
    path: config.setting.paths.staffDeliveryList,
  },
  {
    exact: true,
    role: Role.Staff,
    layout: AuthorizedLayout,
    component: ClientDeliveryList,
    path: `${config.setting.paths.staffClientDeliveryList}/:hash`,
  },
  {
    exact: true,
    role: Role.Staff,
    layout: AuthorizedLayout,
    component: RegisterDistributionList,
    path: `${config.setting.paths.staffRegisterDistributionList}/:hash`,
  },
  {
    exact: true,
    role: Role.Staff,
    layout: AuthorizedLayout,
    component: RegisterQuestionnaire,
    path: config.setting.paths.staffRegisterQuestionnaire,
  },
  /*{
    exact: true,
    role: Role.Staff,
    layout: AuthorizedLayout,
    component: SignUp,
    path: config.setting.paths.signUp
  },*/ {
    role: Role.Staff,
    layout: UnAuthorizedLayout,
    component: Questionnaire,
    path: `${config.setting.paths.staffQuestionnaireDetail}/:hash`,
  },
  {
    exact: true,
    role: Role.Staff,
    layout: AuthorizedLayout,
    component: AnswererList,
    path: config.setting.paths.staffAnswererList,
  },
  {
    exact: true,
    role: Role.Staff,
    layout: AuthorizedLayout,
    component: TagList,
    path: config.setting.paths.staffTagList,
  },
  {
    exact: true,
    role: Role.Staff,
    layout: AuthorizedLayout,
    component: TagCreate,
    path: config.setting.paths.staffTagCreate,
  },
] as IRoute[]

export default staffRoutes
