import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import * as config from 'config'
import authService from 'helper/helper.auth'
import { Role } from 'constance/constance.role'
import answererRoutes from './routes.answerer'
import staffRoutes from './routes.staff'
import publicRoutes from './routes.public'
import staffUnAuthorizedRoutes from './routes.unauthorized'
import storage from 'helper/helper.storage'
import UnAuthorizedLayout from '../layouts/UnAuthorizedLayout'
import Error404 from '../presenters/Error/404'

import { Mode } from 'constance/constance.mode'
import Maintenance from '../presenters/Error/Maintenance'

export type IRoute = {
  layout?: any
  role: Role
  component: any
  path: string
  exact: boolean
}

const token = storage.token

/**
 * 回答者: アンケート回答 + アンケート完了 + 404エラー
 * スタッフ：
 *  ログイン前：Loginページにリダイレクト
 *  ログイン後：質問一覧にリダイレクト
 */

const authorizedRoutes = config.setting.role === Role.Staff ? [...staffRoutes] : []
const unAuthorizedRoutes =
  config.setting.role === Role.Staff
    ? [...staffUnAuthorizedRoutes, ...publicRoutes]
    : [...answererRoutes, ...publicRoutes]

const StaffRedirectRoute = () => {
  return <Redirect exact={true} to={config.setting.paths.login} />
}

const Auth = (props: { children: React.ReactElement }): React.ReactElement => {
  return authService.isLoggedIn() ? props.children : <StaffRedirectRoute />
}

function Routes() {
  // メンテナンス中の場合
  if (config.setting.mode === Mode.Maintenance) {
    return (
      <Switch>
        <Route
          path={config.setting.paths.noMatch}
          exact={true}
          render={(props) => {
            return (
              <>
                <Maintenance {...props} />
              </>
            )
          }}
        />
      </Switch>
    )
  }

  // 通常のルーティング
  return (
    <Switch>
      {unAuthorizedRoutes.map((route, index) => {
        return (
          <Route
            key={index}
            path={`${route.path}`}
            exact={route.exact}
            render={(props) => {
              return route.layout ? (
                <route.layout>
                  <route.component {...props} />
                </route.layout>
              ) : (
                <route.component {...props} />
              )
            }}
          />
        )
      })}

      {/*認証があるのは、管理画面のみなので条件分岐*/}
      {config.setting.role === Role.Staff ? (
        <Auth>
          <Switch>
            {authorizedRoutes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={`${route.path}`}
                  exact={route.exact}
                  render={(props) => {
                    return route.layout ? (
                      <route.layout>
                        <route.component {...props} />
                      </route.layout>
                    ) : (
                      <route.component {...props} />
                    )
                  }}
                />
              )
            })}

            <Route path={config.setting.paths.noMatch}>
              <UnAuthorizedLayout>
                <Error404 />
              </UnAuthorizedLayout>
            </Route>
          </Switch>
        </Auth>
      ) : null}

      {config.setting.role === Role.Staff ? <StaffRedirectRoute /> : null}

      <Route path={config.setting.paths.noMatch}>
        <UnAuthorizedLayout>
          <Error404 />
        </UnAuthorizedLayout>
      </Route>

      {config.setting.role === Role.Staff ? <StaffRedirectRoute /> : null}
      {config.setting.role !== Role.Staff ? (
        <Route path={config.setting.paths.noMatch}>
          <UnAuthorizedLayout>
            <Error404 />
          </UnAuthorizedLayout>
        </Route>
      ) : null}
    </Switch>
  )
}

export default Routes
