import React, { useState, useEffect } from 'react'
import Table from './Table'
import { formatDate } from 'helper/helper.moment'
import { Typography } from 'antd'

interface IDeliveryDuplicateAlertTableProps {
  deliveryDuplicateCsv: Answerer[]
  deliveryDuplicateDb: Answerer[]
}

const DeliveryDuplicateAlertTable: React.FC<IDeliveryDuplicateAlertTableProps> = (props) => {
  const { deliveryDuplicateCsv, deliveryDuplicateDb } = props

  const [deliveryDuplicateCsvList, setDeliveryDuplicateCsvList] = useState<Answerer[] | []>([]) // アンケート配信で、csvにおいて重複があるアンケート
  const [deliveryDuplicateDbList, setDeliveryDuplicateDbList] = useState<Answerer[] | []>([]) // アンケート配信で、dvにおいて、重複があるアンケート

  useEffect(() => {
    setDeliveryDuplicateCsvList(deliveryDuplicateCsv)
    setDeliveryDuplicateDbList(deliveryDuplicateDb)
  }, [deliveryDuplicateCsv, deliveryDuplicateDb])

  const duplicateCsvColumns = [
    {
      title: 'アンケートNo',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: '顧客名',
      dataIndex: 'name',
      key: 'name',
      render: (_: string, questionnaire: Answerer) => {
        return <span>{questionnaire.changedName ? questionnaire.changedName : questionnaire.name}</span>
      },
    },
  ]

  const duplicateDbColumns = [
    {
      title: 'アンケートNo',
      dataIndex: 'no',
      key: 'no',
    },

    {
      title: '顧客名',
      dataIndex: 'name',
      key: 'name',
      render: (_: string, questionnaire: Answerer) => {
        return <span>{questionnaire.changedName ? questionnaire.changedName : questionnaire.name}</span>
      },
    },

    {
      title: '配信登録日',
      dataIndex: 'title',
      key: 'title',
      render: (text: string, questionnaire: Answerer) => {
        return (
          <>
            <div>
              <Typography.Text>{formatDate(questionnaire.sentAt)}</Typography.Text>
            </div>
          </>
        )
      },
    },
  ]
  return (
    <>
      <p
        className={
          deliveryDuplicateCsv?.length
            ? 'questionnaire-delivery-duplicate-alert-modal-alert-title'
            : 'questionnaire-delivery-duplicate-alert-modal-title'
        }
      >
        ①同じCSVに同じアンケートNo
      </p>
      {deliveryDuplicateCsv.length ? (
        <Table<Answerer> columns={duplicateCsvColumns} data={deliveryDuplicateCsvList} />
      ) : (
        <p>重複したアンケートはありませんでした。</p>
      )}

      <p
        className={
          deliveryDuplicateDb.length
            ? 'questionnaire-delivery-duplicate-alert-modal-second-title'
            : 'questionnaire-delivery-duplicate-alert-modal-title'
        }
        style={{ marginTop: 32 }}
      >
        ②過去に登録したことのあるアンケートNo
      </p>
      {deliveryDuplicateDb.length ? (
        <Table<Answerer> columns={duplicateDbColumns} data={deliveryDuplicateDbList} />
      ) : (
        <p>重複したアンケートはありませんでした。</p>
      )}
    </>
  )
}

export default DeliveryDuplicateAlertTable
