import * as React from 'react'
import { message, Modal } from 'antd'
import { questionnaireDisplayName } from 'constance/constance.display'
import Paragraph from 'antd/es/typography/Paragraph'
import * as $apis from '../../../../apis'

import { QuestionnaireType, questionnaireTypeName } from 'constance/constance.display'

export interface IReDisplayBtnModalProps {
  isReDisplayBtnModal: boolean
  setIsReDisplayBtnModal: React.Dispatch<React.SetStateAction<boolean>>
  effectQuery: () => Promise<void>
  choiceQuestionnaire: Questionnaire | null
  setChoiceQuestionnaire: React.Dispatch<React.SetStateAction<Questionnaire | null>>
}

// 再表示ボタンのモーダル
const ReDisplayBtnModal: React.FC<IReDisplayBtnModalProps> = (props) => {
  const [confirmLoading, setConfirmLoading] = React.useState(false)
  const {
    isReDisplayBtnModal,
    setIsReDisplayBtnModal,
    choiceQuestionnaire,
    setChoiceQuestionnaire,
    effectQuery,
  } = props

  const handleOk = async (e: any) => {
    setConfirmLoading(true)
    try {
      if (!choiceQuestionnaire?.hash) return
      await $apis.questionnaire.reDisplay(null, { hash: choiceQuestionnaire.hash })
      setTimeout(() => {
        setChoiceQuestionnaire(null)
      }, 100)
      setIsReDisplayBtnModal(false)
      message.success('再表示しました。')
      effectQuery()
    } catch (e) {
      message.error('再表示に失敗しました。')
      console.log(e)
    } finally {
      setConfirmLoading(false)
    }
  }

  const handleCancel = (e: any) => {
    console.log(e)

    setIsReDisplayBtnModal(false)
    setTimeout(() => {
      setChoiceQuestionnaire(null)
    }, 100)
  }

  return (
    <>
      <Modal
        title={`${choiceQuestionnaire?.title ?? ''}を再表示にしますか`}
        visible={isReDisplayBtnModal}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="キャンセル"
        confirmLoading={confirmLoading}
        okText="再表示する"
      >
        <ul className="modal-confirm-content">
          <li>
            <Paragraph>
              <span>{questionnaireDisplayName.title}</span>
              <span>{choiceQuestionnaire?.title ?? ''}</span>
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              <span>{questionnaireDisplayName.type}</span>
              <span>{questionnaireTypeName[choiceQuestionnaire?.type ?? QuestionnaireType.Seller]}</span>
            </Paragraph>
          </li>
        </ul>
      </Modal>
    </>
  )
}

export default ReDisplayBtnModal
