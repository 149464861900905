import * as React from 'react'
import { Button, DatePicker, Form, Modal, message, TimePicker } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
import styled from 'styled-components'
import moment, { Moment } from 'moment'

export interface INonVisualBtnModalProps {
  closeModal: any
  searchCsvModalVisible: boolean
  questionnaire: Questionnaire
  csvDownload: (data: { from: string | undefined; to: string | undefined }) => void
  downloading: boolean
}

const Description = styled.div`
  font-weight: bold;
  span {
    display: block;
    font-weight: lighter;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  > * {
    margin-right: 16px;
  }
`

const FormItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ArrowWrapper = styled.div`
  font-size: 18px;
  margin-right: 16px;
`

const SearchCSVModal: React.FC<INonVisualBtnModalProps> = (props) => {
  const { searchCsvModalVisible } = props

  const onFinish = (values: {
    from: Moment | undefined
    fromTime: Moment | undefined
    to: Moment | undefined
    toTime: Moment | undefined
  }) => {
    // 開始日、終了日の時間と分を抽出
    const fromTimeH = values.fromTime?.format('H,m').split(',')[0]
    const fromTimeM = values.fromTime?.format('H,m').split(',')[1]

    const toTimeH = values.toTime?.format('H,m').split(',')[0]
    const toTimeM = values.toTime?.format('H,m').split(',')[1]

    const data = {
      from: values.from?.hour(Number(fromTimeH ?? 0)).minute(Number(fromTimeM ?? 0)),
      to: values.to?.hour(Number(toTimeH ?? 0)).minute(Number(toTimeM ?? 0)),
    }

    // 以下がマイナスの場合、開始日 > 終了日 になっている場合はエラーアラート
    const isFromFuture = data.to?.diff(data.from, 'minute') as number
    if (isFromFuture <= 0) {
      message.error('終了日が開始日よりも過去の時間で設定されています。')
      return
    }

    props.csvDownload({ from: data.from?.format('YYYY/MM/DD HH:mm:ss'), to: data.to?.format('YYYY/MM/DD HH:mm:ss') })
  }
  const onFinishFailed = (error: any) => {
    console.log(error)
  }
  return (
    <Modal
      title={`回答結果を出力する。`}
      visible={searchCsvModalVisible}
      onCancel={props.closeModal}
      cancelText="キャンセル"
      okText="CSVを出力する"
      footer={null}
    >
      <ul className="modal-confirm-content">
        <Paragraph>
          <Description>
            出力する回答結果の回答日の範囲を入力してください。
            <span>※入力がない場合はすべての範囲で検索します。</span>
          </Description>
        </Paragraph>
        <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <FormItemWrapper>
            <div>
              <Form.Item name="from" label={'開始日'} labelCol={{ span: 24 }} style={{ marginBottom: 16 }}>
                <DatePicker format="YYYY年MM月DD日" />
              </Form.Item>

              <Form.Item name="fromTime">
                <TimePicker
                  style={{ width: 156, marginTop: -8 }}
                  format={'HH時mm分'}
                  defaultValue={moment('00:00:00', 'HH:mm:ss')}
                />
              </Form.Item>
            </div>

            <ArrowWrapper>→</ArrowWrapper>
            <div>
              <Form.Item name="to" label={'終了日'} labelCol={{ span: 24 }} style={{ marginBottom: 16 }}>
                <DatePicker format="YYYY年MM月DD日" />
              </Form.Item>
              <Form.Item name="toTime">
                <TimePicker
                  style={{ width: 156, marginTop: -8 }}
                  format={'HH時mm分'}
                  defaultValue={moment('00:00:00', 'HH:mm:ss')}
                />
              </Form.Item>
            </div>
          </FormItemWrapper>
          <ButtonWrapper>
            <Button danger onClick={props.closeModal}>
              キャンセル
            </Button>
            <Button type="default" htmlType="submit" loading={props.downloading}>
              回答結果を出力
            </Button>
          </ButtonWrapper>
        </Form>
      </ul>
    </Modal>
  )
}

export default SearchCSVModal
