import numeral from 'numeral'
import {conditionData, purposeData} from './constance.setting'

export const questionnaireDisplayName = {
  id: 'アンケートID',
  tag: 'ラベル名',
  title: 'アンケート名',
  type: '種別',
  category: 'カテゴリー',
  remindSpan: 'リマインド',
  createdAt: '作成日',
  updatedAt: '更新日',
} as { [key in keyof Questionnaire]: string }

export const answerDisplayName = {
  id: 'ID',
  destinationId: 'アンケートID',
  createdAt: '作成日',
} as { [key in keyof Answer]: string }

export enum QuestionnaireType {
  Contract = 1,
  Delivery = 2,
  Seller = 3,
  Buyer = 4,
  Borrower = 5,
  Lender = 6,
  GrantactBuyer = 7,
  GrantactSeller = 8,
}

// アンケートのカテゴリー
export enum Category {
  NotSet = 0,         // なし
  AfterValuation = 1, // 査定後
  OnSale = 2,         // 販売中
  AfterContract = 4   // 成約後
}

export enum MethodType {
  Mail = 1,
}

// アンケート送信のステータス
// export enum StatusType {
//   Unsent = 1,
//   Sent = 2,
//   UndoSend = 3,
//   SendingFailed = 4,
//   Unanswered = 5,
//   Answered = 6,
// }

// アンケート送信のステータス
export enum SendStatus {
  Unsent = 0, //未送信
  SendSuccessful = 1, //送信成功
  SendingFailure = 2, //送信失敗
  ReceivingFailure = 3, //受信失敗
}

// アンケートの回答状況
export enum AnswerStatus {
  Unanswered = 0, //未回答
  Answered = 1, //回答済
}

export enum SubscribeType {
  Email = 1, // メール
  Sms = 2, // SMS
}

// アンケート送信キャンセルstatus
export enum CancelStatus {
  NotCancel = 0, //未キャンセル
  SendCancel = 1, //キャンセル
}

export enum QuestionType {
  Radio = 1,
  CheckBox = 2,
  OptionalEmail = 3,
  OptionalTextArea = 4,
  Slider = 8,
  Flow = 16,
}

// export enum AnswererStatus {
//   Unsent = 1,
//   Sent = 2,
//   UndoSend = 3,
//   SendingFailed = 4,
//   Unanswered = 5,
//   Answered = 6,
// }

export const questionnaireTypeColor = {
  [QuestionnaireType.Contract]: 'geekblue',
  [QuestionnaireType.Delivery]: 'cyan',
  [QuestionnaireType.Seller]: 'volcano',
  [QuestionnaireType.Buyer]: 'magenta',
  [QuestionnaireType.Borrower]: 'purple',
  [QuestionnaireType.Lender]: 'gold',
  [QuestionnaireType.GrantactBuyer]: 'orange',
  [QuestionnaireType.GrantactSeller]: 'yellow',
}

// アンケートのカテゴリー
export const categoryTypeColor = {
  [Category.NotSet]: 'magenta',         // なし
  [Category.AfterValuation]: 'purple',  // 査定後
  [Category.OnSale]: 'volcano',         // 販売中
  [Category.AfterContract]: 'geekblue', // 成約後
}


export const answerStatusColor = {
  [AnswerStatus.Unanswered]: 'orange',
  [AnswerStatus.Answered]: 'green',
}

export const sendStatusColor = {
  [SendStatus.Unsent]: 'orange',
  [SendStatus.SendSuccessful]: 'green',
  [SendStatus.SendingFailure]: 'red',
  [SendStatus.ReceivingFailure]: 'magenta',
}

export const cancelStatusColor = {
  [CancelStatus.NotCancel]: 'green',
  [CancelStatus.SendCancel]: 'red',
}

export const answerStatusTypeName = {
  [AnswerStatus.Unanswered]: '未回答',
  [AnswerStatus.Answered]: '回答済',
}

export const sendStatusTypeName = {
  [SendStatus.Unsent]: '未送信',
  [SendStatus.SendSuccessful]: '送信成功',
  [SendStatus.SendingFailure]: '送信失敗',
  [SendStatus.ReceivingFailure]: '受信失敗',
}

export const mailSendStatusTypeName = {
  [SendStatus.Unsent]: 'メール未送信',
  [SendStatus.SendSuccessful]: 'メール送信成功',
  [SendStatus.SendingFailure]: 'メール送信失敗',
  [SendStatus.ReceivingFailure]: 'メール受信失敗',
}

export const smsSendStatusTypeName = {
  [SendStatus.Unsent]: 'SMS未送信',
  [SendStatus.SendSuccessful]: 'SMS送信成功',
  [SendStatus.SendingFailure]: 'SMS送信失敗',
  [SendStatus.ReceivingFailure]: 'SMS受信失敗',
}


export const cancelStatusTypeName = {
  [CancelStatus.NotCancel]: 'アクティブ',
  [CancelStatus.SendCancel]: '停止済み',
}

export const subscribeTypeName = {
  [SubscribeType.Email]: 'メール',
  [SubscribeType.Sms]: 'SMS',
}

export const questionnaireTypeName = {
  [QuestionnaireType.Contract]: '契約後',
  [QuestionnaireType.Delivery]: '引渡後',
  [QuestionnaireType.Seller]: '売主様',
  [QuestionnaireType.Buyer]: '買主様',
  [QuestionnaireType.Borrower]: '貸主様',
  [QuestionnaireType.Lender]: '借主様',
  [QuestionnaireType.GrantactBuyer]: 'G売主様',
  [QuestionnaireType.GrantactSeller]: 'G買主様',
}


// アンケートのカテゴリー名
export const categoryTypeName = {
  [Category.NotSet]: 'なし',
  [Category.AfterValuation]: '査定後',
  [Category.OnSale]: '販売中',
  [Category.AfterContract]: '成約後',
}

export const displayNumber = (number: number, unit?: string): string => {
  return `${numeral(number).format('0,0')}${unit ? ` ${unit}` : ''}`
}

export const conditionDataName = (number: number): string => {
  if (!number) return ''
  return conditionData.find((data) => data.id === number)?.text ?? ''
}

export const purposeDataName = (number: number): string => {
  if (!number) return ''
  return purposeData.find((data) => data.id === number)?.text ?? ''
}
