import * as React from 'react'
import { useState } from 'react'
import QuestionnaireComponent from 'components/Questionnaire'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import * as config from 'config'
import { useHistory } from 'react-router-dom'
import { message, Button } from 'antd'
import * as $apis from 'apis'
import { useDelivery } from 'hooks/hooks.useDelivery'
import { formatAnswerForm } from 'components/Questionnaire/Questionnaire.utils'
import { ExclamationCircleOutlined } from '@ant-design/icons/lib'
import { ConfirmModal } from "./Questionnaire.style"

export interface IQuestionnaireProps {}

const Questionnaire: React.FC<IQuestionnaireProps> = (props) => {
  const history = useHistory()
  const { answererStatus, loading, error, questionnaire, hash } = useDelivery()
  const [alerts, setAlerts] = useState<ValidateErrorEntity<AnswerQuestionnaire> | null>(null)
  const [isSubmitProcessing, setIsSubmitProcessing] = useState<boolean>(false);
  const [isDisplayConfirmModal, setIsDisplayConfirmModal] = useState<boolean>(false)
  const [answerQuestionnaire, setAnswerQuestionnaire] = useState<AnswerQuestionnaire>()


  const submit = async () => {
    try {
      setIsSubmitProcessing(true)
      const data = formatAnswerForm(answerQuestionnaire!)
      const response = await $apis.delivery.answer<DeliveryAnswerResponseDto, DeliveryAnswerRequestDto>({ ...data, hash })
      const office = response.data.url ? response.data : null
      message.success('アンケートの回答を完了しました。')
      history.replace({ pathname: config.setting.paths.answererQuestionnaireThanks, state: { questionnaire, office } })
    } catch (e) {
      message.error('ご回答保存時にエラーが発生いたしました。お手数ですが再度ご確認のうえ保存をしてください。')
      console.log(e)
    } finally {
      setIsSubmitProcessing(false)
      setIsDisplayConfirmModal(false)
    }
  }

  const onFinishFailed = (error: ValidateErrorEntity<AnswerQuestionnaire>) => {
    window.scrollTo(0, 0)
    setAlerts(error)
    console.log('Failed:', error)
  }

  const handleCancel = () => {
    setIsDisplayConfirmModal(false);
  };

  const onFinish = (values: AnswerQuestionnaire) => {
    setAnswerQuestionnaire(values)
    setIsDisplayConfirmModal(true)
  }

  return (
    <>
      <QuestionnaireComponent
        preview={false}
        answererStatus={answererStatus}
        loading={loading}
        error={error}
        alerts={alerts}
        setAlerts={setAlerts}
        questionnaire={questionnaire}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      />
      <ConfirmModal
        visible={isDisplayConfirmModal}
        title={
          <div style={{ display: "flex" }}>
            <ExclamationCircleOutlined style={{ color: "#faad14", fontSize: 24, marginRight: 16 }}/>
            <div style={{ marginTop: 2 }}>アンケートの回答内容を送信します。よろしいですか？</div>
          </div>
        }
        bodyStyle={{ display: 'none' }}
        closable={false}
        footer={[
          <Button key="fix" onClick={handleCancel} disabled={isSubmitProcessing}>
            回答を修正する
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isSubmitProcessing}
            onClick={submit}
          >
            回答を送信する
          </Button>,
        ]}
      />
    </>
  )
}

export default Questionnaire
