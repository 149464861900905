import * as React from 'react'
import { message, Modal, Typography } from 'antd'
import * as $apis from 'apis'
import { SubscribeType, subscribeTypeName } from 'constance'

export interface ICancelDeliveryModalProps {
  subscribeType: SubscribeType
  answerer?: Answerer
  visible: boolean
  setAnswerer: any
  setVisible: any
  callback: any
}

const { Paragraph } = Typography

const CancelDeliveryModal: React.FC<ICancelDeliveryModalProps> = (props) => {
  const [confirmLoading, setConfirmLoading] = React.useState(false)
  const { subscribeType, answerer, visible, setAnswerer } = props
  const subscribeTypeNameStr = subscribeTypeName[subscribeType]

  // 配信リストから、顧客を削除する
  const handleOk = async (e: any) => {
    const clientId = answerer?.id
    try {
      setConfirmLoading(true)
      if (!clientId) return
      const res = await $apis.client.cancel(null, { clientId, subscribeType })
      props.setVisible(false)
      setTimeout(() => {
        setAnswerer(null)
      }, 100)
      message.success(`${subscribeTypeNameStr}配信を停止しました。`)
      props.callback(res.data)
    } catch (e) {
      console.log(e)
    } finally {
      setConfirmLoading(false)
    }
  }

  const handleCancel = (e: any) => {
    props.setVisible(false)
    setTimeout(() => {
      setAnswerer(null)
    }, 100)
  }

  return (
    <Modal
      title={`${answerer?.changedName ? answerer?.changedName : answerer?.name ?? ''}の${subscribeTypeNameStr}配信を停止しますか`}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      cancelText="キャンセル"
      okText={`${subscribeTypeNameStr}配信を停止する`}
    >
      <ul className="modal-confirm-content">
        <li>
          <Paragraph>
            <span>アンケートNo</span>
            <span>{answerer?.questionnaireId ?? ''}</span>
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <span>顧客名</span>
            <span>{answerer?.changedName ? answerer?.changedName : answerer?.name ?? ''}</span>
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <span>センター名</span>
            <span>{answerer?.officeName ? answerer?.officeName : answerer?.representative?.office?.name}</span>
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <span>引渡日</span>
            <span>{answerer?.deliveryDate ?? ''}</span>
          </Paragraph>
        </li>
      </ul>
    </Modal>
  )
}

export default CancelDeliveryModal
