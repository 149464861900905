import React from 'react'
import {Route} from "antd/lib/breadcrumb/Breadcrumb"

interface IBreadcrumbContext {
  setBreadcrumbs: (items: Route[]) => void;
  breadcrumbs: Route[];
  setTitle: (title: string) => void;
  title: string;
  setSubTitle: (subTitle: string) => void;
  subTitle: string
}

export const BreadcrumbContext = React.createContext<IBreadcrumbContext>({
  setBreadcrumbs: () => [],
  breadcrumbs: [],
  setTitle: () => '',
  title: '',
  setSubTitle: () => '',
  subTitle: ''
})
