export const purposeData = [{
  id: 1,
  value: 0.1,
  text: '雑貨',
}, {
  id: 2,
  value: 0.2,
  text: '玩具',
}, {
  id: 2,
  value: 0.3,
  text: '文具',
}, {
  id: 2,
  value: 0.15,
  text: '紙媒体',
}, {
  id: 2,
  value: 0.35,
  text: 'デジタル利用',
}]

export const conditionData = [{
  id: 1,
  text: '金額について',
}, {
  id: 2,
  text: '利用方法について',
}, {
  id: 3,
  text: 'キャラについて',
}, {
  id: 4,
  text: 'その他',
}]

