import moment from "moment"

export const initialSection = {
  questionnaireId: '',
  text: '',
  no: 1,
  questions: [],
  createdAt: moment().toDate(),
  updatedAt: moment().toDate(),
}
