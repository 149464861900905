import axios from 'axios'
import { presetsAddPreset } from '@storybook/postinstall'

export default class Service {
  /**
   *
   * @param endpoint
   * @param queries
   */
  public async get<T>(endpoint: string, queries?: { [key in string]?: string }) {
    return await axios.get<T>(queries ? `${endpoint}?${Service.encodeQueryData(queries)}` : endpoint)
  }

  /**
   *
   * @param endpoint
   * @param params
   * @param queries
   */
  public async post<T, U>(endpoint: string, params: U, queries?: { [key in string]: string }) {
    return await axios.post<T>(queries ? `${endpoint}?${Service.encodeQueryData(queries)}` : endpoint, params)
  }

  /**
   *
   * @param endpoint
   * @param params
   * @param queries
   */
  public async patch<T, U>(endpoint: string, params: U, queries?: { [key in string]: string }) {
    return await axios.patch<T>(queries ? `${endpoint}?${Service.encodeQueryData(queries)}` : endpoint, params)
  }

  /**
   *
   * @param endpoint
   * @param params
   * @param queries
   */
  public async put<T, U>(endpoint: string, params: U, queries?: { [key in string]: string }) {
    return await axios.put<T>(queries ? `${endpoint}?${Service.encodeQueryData(queries)}` : endpoint, params)
  }

  /**
   *
   * @param endpoint
   * @param id
   * @param queries
   */
  public async delete<T>(endpoint: string, id?: string, queries?: { [key in string]: string }) {
    return await axios.delete<T>(
      queries && id ? `${endpoint}/${id}?${Service.encodeQueryData(queries)}` : id ? `${endpoint}/${id}` : endpoint
    )
  }

  /**
   * add query parameters to url
   * @param data
   */
  private static encodeQueryData(data: { [key in string]?: string | number }) {
    const ret = []
    for (let d in data) {
      // キャメルケースから、スネークケースの場合変更
      // const key = encodeURIComponent(Service.camelToUnderscore(d))
      const key = encodeURIComponent(d)
      const param = data[d]

      // paramのみだと、値が0の場合にパスパラメータに入らないので0の場合も追加
      if (param || param === 0) {
        const value = encodeURIComponent(param)
        ret.push(key + '=' + value)
      }
    }

    return ret.join('&')
  }

  /**
   * change naming convention camel to snake
   * @param key
   */
  private static camelToUnderscore(key: string) {
    return key.replace(/([A-Z])/g, '_$1').toLowerCase()
  }
}
