import * as React from 'react'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Form, Input, message, Select } from 'antd'
import styled from 'styled-components'
import * as $apis from 'apis'
import { getSubTitleByPath, getTitleByPath } from 'helper/helper.breadcrumb'
import { TagFieldsContext } from 'contexts/contexts.tagFields'
import * as config from 'config'
import { useHistory, useLocation } from 'react-router-dom'
import useBreadcrumbs from 'hooks/hooks.useBreadcrumb'

export interface ITagCreateProps {}

const Container = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  max-width: 640px;
`

const { Option } = Select

const TagCreate: React.FC<ITagCreateProps> = (props) => {
  const [form] = Form.useForm()
  const [list, setList] = useState<Questionnaire[]>([])
  const [loading, setLoading] = useState(false)
  const location = useLocation()

  const { setBreadcrumbs, setTitle, setSubTitle } = useBreadcrumbs()
  const { csvFormat } = useContext(TagFieldsContext)
  const history = useHistory()

  useEffect(() => {
    effectQuery()
  }, [])

  useEffect(() => {
    const type = config.setting.paths.staffTagCreate
    setSubTitle(getSubTitleByPath(type))
    setTitle(getTitleByPath(type))
    setBreadcrumbs([
      {
        path: type,
        breadcrumbName: getTitleByPath(type),
      },
    ])
  }, [location.pathname, setBreadcrumbs, setSubTitle, setTitle])

  const effectQuery = useCallback(async () => {
    try {
      const res = await $apis.questionnaire.getList({})
      setList(res.data)
    } catch (e) {
      console.log(e)
    }
  }, [])

  // タグの入力の項目の初期値を入れる。
  useEffect(() => {
    form.setFieldsValue({ csvFormat: csvFormat })
  }, [])

  const onFinish = async (values: Tag) => {
    setLoading(true)
    try {
      const response = await $apis.tag.create({
        ...values,
        deactivated: false,
        disabled: false,
      })
      console.log(response.data)
      message.success('LMD連携タグの登録に成功しました。')

      history.push(config.setting.paths.staffTagList)
    } catch (e) {
      message.error(e.response.data)
      console.log(e)
    } finally {
      setLoading(false)
    }
  }
  const onFinishFailed = (err: any) => {
    console.log(err)
  }
  return (
    <Container>
      <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item
          name={'name'}
          label="ラベル名"
          rules={[{ required: true, message: '必ず入力してください。' }]}
          labelCol={{ span: 24 }}
          style={{ width: 480 }}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          name={'csvFormat'}
          label="LMD連携時の推奨タグ名"
          rules={[{ required: true, message: '必ず入力してください。' }]}
          labelCol={{ span: 24 }}
          style={{ width: 480 }}
        >
          <Input placeholder="ex.) Seller" />
        </Form.Item>
        <Form.Item
          name="questionnaireId"
          label="アンケート選択"
          rules={[{ required: true, message: '必ず選択してください。' }]}
          labelCol={{ span: 24 }}
          style={{ width: 480 }}
        >
          <Select allowClear style={{ width: '100%' }} defaultActiveFirstOption={false} filterOption={false}>
            {list.map((d: Questionnaire) => (
              <Option key={d.id} value={d.id}>
                {d.id}: {d.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            LMD連携タグを登録する
          </Button>
        </Form.Item>
      </Form>
    </Container>
  )
}

export default TagCreate
