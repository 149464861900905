import * as React from 'react'
import { message, Modal } from 'antd'
import { questionnaireDisplayName } from 'constance/constance.display'
import Paragraph from 'antd/es/typography/Paragraph'
import * as $apis from '../../../../apis'

import { QuestionnaireType, questionnaireTypeName } from 'constance/constance.display'

export interface INonVisualBtnModalProps {
  isNonVisualBtnModal: boolean
  setIsNonVisualBtnModal: React.Dispatch<React.SetStateAction<boolean>>
  effectQuery: () => Promise<void>
  cancelQuestionnaire: Questionnaire | null
  setCancelQuestionnaire: React.Dispatch<React.SetStateAction<Questionnaire | null>>
}

// 非表示ボタンのモーダル
const NonVisualBtnModal: React.FC<INonVisualBtnModalProps> = (props) => {
  const {
    isNonVisualBtnModal,
    setIsNonVisualBtnModal,
    cancelQuestionnaire,
    setCancelQuestionnaire,
    effectQuery,
  } = props

  const [confirmLoading, setConfirmLoading] = React.useState(false)

  const handleOk = async (e: any) => {
    try {
      setConfirmLoading(true)
      if (!cancelQuestionnaire?.hash) return
      await $apis.questionnaire.hide(null, { hash: cancelQuestionnaire.hash })
      setTimeout(() => {
        setCancelQuestionnaire(null)
      }, 100)
      setIsNonVisualBtnModal(false)
      message.success('非表示にしました。')
      effectQuery()
    } catch (e) {
      message.error('非表示に失敗しました。')
      console.log(e)
    } finally {
      setConfirmLoading(false)
    }
  }

  const handleCancel = (e: any) => {
    console.log(e)

    setIsNonVisualBtnModal(false)
    setTimeout(() => {
      setCancelQuestionnaire(null)
    }, 100)
  }

  return (
    <>
      <Modal
        title={`${cancelQuestionnaire?.title ?? ''}を非表示にしますか`}
        visible={isNonVisualBtnModal}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="キャンセル"
        confirmLoading={confirmLoading}
        okText="非表示にする"
      >
        <ul className="modal-confirm-content">
          <li>
            <Paragraph>
              <span>{questionnaireDisplayName.title}</span>
              <span>{cancelQuestionnaire?.title ?? ''}</span>
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              <span>{questionnaireDisplayName.type}</span>
              <span>{questionnaireTypeName[cancelQuestionnaire?.type ?? QuestionnaireType.Seller]}</span>
            </Paragraph>
          </li>
        </ul>
      </Modal>
    </>
  )
}

export default NonVisualBtnModal
