import * as React from 'react'
import QuestionnaireComponent from 'components/Questionnaire'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import { useQuestionnaire } from '../../../hooks/hooks.useQuestionnaire'

export interface IQuestionnaireProps {}

const Questionnaire: React.FC<IQuestionnaireProps> = (props) => {
  const { loading, error, questionnaire } = useQuestionnaire()

  const onFinish = async (values: AnswerQuestionnaire) => {
    try {
      console.log(values)
    } catch (e) {
      console.log(e)
    }
  }

  const onFinishFailed = (errorInfo: ValidateErrorEntity<AnswerQuestionnaire>) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <QuestionnaireComponent
      preview={true}
      loading={loading}
      error={error}
      questionnaire={questionnaire}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    />
  )
}

export default Questionnaire
