const storage = window.localStorage

class Storage {
  private readonly tokenName: string = 'token'
  private readonly registerTokenName: string = 'registertoken'
  private readonly roleName: string = 'role'
  private readonly nameName: string = "name"
  private readonly ramName: string = 'ram'

  get token(): string {
    return storage.getItem(this.tokenName) as string
  }

  set token(newToken: string) {
    storage.setItem(this.tokenName, newToken)
  }

  get registerToken(): string {
    return storage.getItem(this.registerTokenName) as string
  }

  set registerToken(newToken: string) {
    storage.setItem(this.registerTokenName, newToken)
  }

  get role(): string {
    return storage.getItem(this.roleName) as string
  }

  set role(newRole: string) {
    storage.setItem(this.roleName, newRole)
  }

  get name(): string {
    return storage.getItem(this.nameName) as string
  }

  set name(newName: string) {
    storage.setItem(this.nameName, newName)
  }

  get ram(): string[] {
    const current = storage.getItem(this.ramName)
    if (current) {
      return JSON.parse(current) as string[]
    } else {
      return []
    }
  }

  setRam(newRam: string) {
    const current = this.ram
    if (current) {
      if (!current.includes(newRam)) {
        const currentNew = [...current, newRam]
        storage.setItem(this.ramName, JSON.stringify(currentNew))
      }
    } else {
      storage.setItem(this.ramName, JSON.stringify([newRam]))
    }
  }

  clearToken() {
    storage.removeItem(this.tokenName)
  }

  clearRegisterToken() {
    storage.removeItem(this.registerTokenName)
  }

  clear(): Promise<any> {
    return new Promise((resolve) => {
      storage.clear()
      resolve()
    })
  }
}

export default new Storage()
