import styled from 'styled-components'
import { Modal } from 'antd'

export const ConfirmModal = styled(Modal)`
    .ant-modal-header{
      border: none;
      padding-top: 32px;
    }
    
    .ant-modal-footer{
      border: none;
      margin-top: 24px;
      padding-bottom: 24px;
    }
  `
