import { Button, Modal, Result, Typography } from 'antd'
import * as React from 'react'
import { useEffect, useState, useCallback } from 'react'
import * as config from 'config'
import { useLocation } from 'react-router-dom'
import useBreadcrumbs from 'hooks/hooks.useBreadcrumb'
import { getSubTitleByPath, getTitleByPath } from 'helper/helper.breadcrumb'
import { isMobile } from 'react-device-detect'
import './QuestionnairThanks.less'

export interface IQuestionnaireThanksProps {}

type ModalStep = {
  text: string
  imageDesktop?: string
  imageMobile?: string
  requireGoogleLoginStatus: boolean | null
}

const { Text } = Typography
const initModalSteps: ModalStep[] = [
  {
    text: 'ボタンをクリックする',
    imageDesktop: '/images/google-review-tutorial/pc-step1.png',
    imageMobile: '/images/google-review-tutorial/mb-step1.png',
    requireGoogleLoginStatus: null,
  },
  {
    text: 'ログインをクリックする',
    imageDesktop: '/images/google-review-tutorial/pc-step2.png',
    requireGoogleLoginStatus: false,
  },
  {
    text: 'Googleアカウントにログインする',
    imageDesktop: '/images/google-review-tutorial/pc-step3.png',
    imageMobile: '/images/google-review-tutorial/mb-step2.png',
    requireGoogleLoginStatus: false,
  },
  {
    text: '星をつけ、ご感想を記入する',
    imageDesktop: '/images/google-review-tutorial/pc-step4.png',
    imageMobile: '/images/google-review-tutorial/mb-step3.png',
    requireGoogleLoginStatus: null,
  },
  {
    text: '投稿する',
    imageDesktop: '/images/google-review-tutorial/pc-step5.png',
    imageMobile: '/images/google-review-tutorial/mb-step4.png',
    requireGoogleLoginStatus: null,
  },
]

const QuestionnaireThanks: React.FC<IQuestionnaireThanksProps> = (props) => {
  /* const history = useHistory()*/
  const location = useLocation() as {
    pathname: string
    state: {
      questionnaire?: Questionnaire
      office?: DeliveryAnswerResponseDto
    }
  }
  const { setBreadcrumbs, setTitle, setSubTitle } = useBreadcrumbs()
  const [isLoggedInToGoogle, setIsLoggedInToGoogle] = useState<boolean>(true)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalStep, setModalStep] = useState<number>(0)

  const [modalSteps, setModalSteps] = useState(initModalSteps)

  useEffect(() => {
    setSubTitle(getSubTitleByPath(location.pathname))
    setTitle(getTitleByPath(location.pathname))
    setBreadcrumbs([
      {
        path: config.setting.paths.answererQuestionnaireThanks,
        breadcrumbName: getTitleByPath(config.setting.paths.answererQuestionnaireThanks),
      },
    ])
  }, [location.pathname, setBreadcrumbs, setSubTitle, setTitle])

  useEffect(() => {
    setModalSteps(
      initModalSteps.filter(
        (step) =>
          (step.requireGoogleLoginStatus === null || step.requireGoogleLoginStatus === isLoggedInToGoogle) &&
          (isMobile ? step.imageMobile : step.imageDesktop)
      )
    )
  }, [isLoggedInToGoogle])

  useEffect(() => {
    if (!location.state?.questionnaire ?? true) {
      window.location.href = config.setting.paths.homepage
    }
  }, [location.state?.questionnaire])

  const closeModal = useCallback(() => {
    setModalOpen(false)
    setModalStep(0)
  }, [setModalOpen, setModalStep])

  const googleReviewUrl = location.state?.office?.url

  return (
    <>
      {/* https://github.com/robinlinus/socialmedia-leak/ */}
      <img
        className="hidden"
        alt="google-login-check"
        referrerPolicy="no-referrer"
        src="https://accounts.google.com/ServiceLogin?passive=true&continue=https%3A%2F%2Fwww.google.com%2Ffavicon.ico&uilel=3&hl=en&service=mail"
        onLoad={() => setIsLoggedInToGoogle(true)}
        onError={() => setIsLoggedInToGoogle(false)}
      />
      <Result
        status="success"
        title={
          <Text className="questionnaire-thanks-title">
            アンケートのご回答
            <wbr />
            ありがとうございました。
            <br />
            {googleReviewUrl ? (
              <Text className="questionnaire-thanks-subtext">
                お客様のご意見を参考に、サービス向上に努めます。
                <br />
                また、Googleマップでのご感想も
                <br className="wbr" />
                お待ちしております。
                <br />
                今後ともよろしくお願い申し上げます。
              </Text>
            ) : (
              <Text className="questionnaire-thanks-subtext">
                お客様のご意見を参考に、
                <br className="wbr" />
                ご期待に添えるサービス体制を
                <br className="wbr" />
                確立してまいりたいと存じます。
                <br />
                今後ともよろしくお願い申し上げます。
              </Text>
            )}
          </Text>
        }
        className="questionnaire-thanks"
        extra={[
          googleReviewUrl ? (
            <React.Fragment key="console">
              <Button type="primary" href={googleReviewUrl} target="_blank">
                <span>
                  Googleマップでのご感想はこちらから
                  {!isLoggedInToGoogle && isMobile && (
                    <small>
                      <br />
                      Googleのログイン画面に遷移します
                    </small>
                  )}
                </span>
              </Button>
              <Button type="text" className="questionnaire-thanks-procedure" onClick={() => setModalOpen(true)}>
                投稿までの詳細な手順について <span className="rounded">?</span>
              </Button>
              <Modal
                centered
                className="questionnaire-thanks-modal"
                visible={modalOpen}
                onOk={closeModal}
                onCancel={closeModal}
                width={window.innerWidth >= 480 ? 708 : 368}
                footer={null}
              >
                <img
                  src={isMobile ? modalSteps[modalStep].imageMobile : modalSteps[modalStep].imageDesktop}
                  alt={modalSteps[modalStep].text}
                  className="block"
                />
                <Text className="description block" strong>
                  <span className="number">0{modalStep + 1}</span>
                  {modalSteps[modalStep].text}
                </Text>
                {modalStep === modalSteps.length - 1 ? (
                  <Button type="primary" block href={googleReviewUrl} target="_blank" onClick={closeModal}>
                    <span>
                      Googleマップでのご感想はこちらから
                      {!isLoggedInToGoogle && isMobile && (
                        <small>
                          <br />
                          Googleのログイン画面に遷移します
                        </small>
                      )}
                    </span>
                  </Button>
                ) : (
                  <Button block type="primary" onClick={() => setModalStep((prev) => prev + 1)}>
                    次へ
                  </Button>
                )}
              </Modal>
            </React.Fragment>
          ) : (
            <Button type="primary" key="console" href={config.setting.paths.homepage}>
              ホームページへ戻る
            </Button>
          ),
        ]}
      />
    </>
  )
}

export default QuestionnaireThanks
