import { Moment } from 'moment'

export const momentParse = (momentProps: Moment) => {
  const year = momentProps?.format('YYYY')
  const month = momentProps?.format('MM')
  const day = momentProps?.format('DD')
  const hour = momentProps?.format('HH')
  const minute = momentProps?.format('mm')
  return { year: Number(year), month: Number(month), day: Number(day), hour: Number(hour), minute: Number(minute) }
}
