import * as React from 'react';
import CustomTable from '../CustomTable'
import {
  CancelStatus, cancelStatusColor,
  cancelStatusTypeName,
  questionnaireDisplayName, QuestionnaireType, questionnaireTypeColor, questionnaireTypeName,
  SendStatus,
  sendStatusColor,
  sendStatusTypeName
} from 'constance'
import { orderByText } from 'helper/helper.argo'
import { Link } from 'react-router-dom'
import * as config from 'config'
import { formatDate } from 'helper/helper.moment'
import { Button, Tag, Typography } from 'antd'
import CsvExport from 'presenters/Staff/Deliveries/components/CsvExport'

export interface IDeliveryListProps {
  loading: boolean
  list: QuestionnaireDelivery[]
  showModal: any
  totalItemCount: number;
  pageNumber: number;
  pageSize: number;
  onChange: (current: number, size?: number) => void
}

const {Text} = Typography

const DeliveryList: React.FC<IDeliveryListProps> = props => {

  const columns = [
    {
      title: questionnaireDisplayName.id,
      dataIndex: 'id',
      key: 'id',
      filterMultiple: false,
      onFilter: (value: string | number | boolean, record: QuestionnaireDelivery) =>
        typeof value === 'string' && record.title.indexOf(value) === 0,
      sorter: (a: QuestionnaireDelivery, b: QuestionnaireDelivery) => {
        return Number(a.id) - Number(b.id)
      },
    },

    {
      title: '顧客件数',
      dataIndex: 'answererCount',
      key: 'answererCount',
      filterMultiple: false,
      onFilter: (value: string | number | boolean, record: QuestionnaireDelivery) =>
        typeof value === 'string' && record.title.indexOf(value) === 0,
      sorter: (a: QuestionnaireDelivery, b: QuestionnaireDelivery) => {
        return Number(a.answererCount) - Number(b.answererCount)
      },
    },

    {
      title: questionnaireDisplayName.title,
      dataIndex: 'title',
      key: 'title',
      filterMultiple: false,
      onFilter: (value: string | number | boolean, record: QuestionnaireDelivery) =>
        typeof value === 'string' && record.title.indexOf(value) === 0,
      sorter: (a: QuestionnaireDelivery, b: QuestionnaireDelivery) => orderByText(a.title, b.title),
      render: (text: string, questionnaire: QuestionnaireDelivery) => {
        return (
          <>
            <Link to={`${config.setting.paths.staffQuestionnaireDetail}/${questionnaire.hash}`}>
              {questionnaire.disabled ? (
                <>
                  {text} <span style={{color: '#f5222d'}}>(アンケート削除済み)</span>
                </>
              ) : (
                text
              )}
            </Link>
            <div>
              <Text>配信登録日：{formatDate(questionnaire.createdAt)}</Text>
            </div>
            <div>
              <Text>配信予定日：{formatDate(questionnaire.sentAt)}</Text>
            </div>
          </>
        )
      },
    },

    {
      title: '送信状況',
      dataIndex: ['sendStatus'],
      key: 'sendStatus',
      filterMultiple: false,
      filters: [
        {text: sendStatusTypeName[SendStatus.Unsent], value: SendStatus.Unsent},
        {text: sendStatusTypeName[SendStatus.SendSuccessful], value: SendStatus.SendSuccessful},
        {text: sendStatusTypeName[SendStatus.SendingFailure], value: SendStatus.SendingFailure},
        {text: sendStatusTypeName[SendStatus.ReceivingFailure], value: SendStatus.ReceivingFailure},
      ],
      onFilter: (value: string | number | boolean, questionnaire: QuestionnaireDelivery) => {
        return value === questionnaire.sentStatus
      },
      render: (_: string, questionnaire: QuestionnaireDelivery) => {
        return (
          <span>
            <Tag color={sendStatusColor[questionnaire.sentStatus]}>
              {sendStatusTypeName[questionnaire.sentStatus]}
            </Tag>
          </span>
        )
      },
    },

    {
      title: '停止状況',
      dataIndex: ['cancelStatus'],
      key: 'cancelStatus',
      filterMultiple: false,
      filters: [
        {text: cancelStatusTypeName[CancelStatus.NotCancel], value: CancelStatus.NotCancel},
        {text: cancelStatusTypeName[CancelStatus.SendCancel], value: CancelStatus.SendCancel},
      ],

      onFilter: (value: string | number | boolean, questionnaire: QuestionnaireDelivery) => {
        return value === questionnaire.cancelStatus
      },

      render: (_: string, questionnaire: QuestionnaireDelivery) => {
        return (
          <span>
            <Tag color={cancelStatusColor[questionnaire.cancelStatus]}>
              {cancelStatusTypeName[questionnaire.cancelStatus]}
            </Tag>
          </span>
        )
      },
    },

    {
      title: questionnaireDisplayName.type,
      dataIndex: 'type',
      key: 'type',
      filterMultiple: false,
      filters: [
        {
          text: questionnaireTypeName[QuestionnaireType.Contract],
          value: QuestionnaireType.Contract,
        },
        {
          text: questionnaireTypeName[QuestionnaireType.Delivery],
          value: QuestionnaireType.Delivery,
        },
        {
          text: questionnaireTypeName[QuestionnaireType.Seller],
          value: QuestionnaireType.Seller,
        },
        {
          text: questionnaireTypeName[QuestionnaireType.Buyer],
          value: QuestionnaireType.Buyer,
        },
        {
          text: questionnaireTypeName[QuestionnaireType.Borrower],
          value: QuestionnaireType.Borrower,
        },
        {
          text: questionnaireTypeName[QuestionnaireType.Lender],
          value: QuestionnaireType.Lender,
        },
        {
          text: questionnaireTypeName[QuestionnaireType.GrantactBuyer],
          value: QuestionnaireType.GrantactBuyer,
        },
        {
          text: questionnaireTypeName[QuestionnaireType.GrantactSeller],
          value: QuestionnaireType.GrantactSeller,
        },
      ],
      render: (_: number, questionnaire: QuestionnaireDelivery) => (
        <span>
          <Tag color={questionnaireTypeColor[questionnaire.type]}>{questionnaireTypeName[questionnaire.type]}</Tag>
        </span>
      ),
      onFilter: (value: string | number | boolean, record: QuestionnaireDelivery) =>
        typeof value === 'number' && record.type === value,
    },
    {
      title: 'レポート',
      filterMultiple: false,
      render: (_: string, questionnaire: QuestionnaireDelivery) => (
        <span>
          <CsvExport questionnaire={questionnaire}/>
        </span>
      ),
    },
    {
      title: '',
      filterMultiple: false,
      render: (_: string, questionnaire: QuestionnaireDelivery) => {
        return (
          <span>
            <Button
              danger
              disabled={questionnaire.cancelStatus === CancelStatus.SendCancel || questionnaire.disabled}
              onClick={() => props.showModal(questionnaire)}
            >
              停止
            </Button>
          </span>
        )
      },
    },
    {
      title: '',
      filterMultiple: false,
      render: (_: string, questionnaire: QuestionnaireDelivery) => {
        return (
          <span>
            <Link
              to={{
                pathname: `${config.setting.paths.staffClientDeliveryList}/${questionnaire.groupHash}`,
                state: {questionnaire},
              }}
            >
              <Button
                danger
                disabled={questionnaire.cancelStatus === CancelStatus.SendCancel || questionnaire.disabled}
              >
                顧客ごとに停止
              </Button>
            </Link>
          </span>
        )
      },
    },
  ]

  return (
    <div className="delivery-list">
      <CustomTable<QuestionnaireDelivery>
        pageSize={props.pageSize}
        pageNumber={props.pageNumber}
        loading={props.loading}
        columns={columns}
        data={props.list}
        rowKey={(record) => record.groupHash ?? record.hash}
        totalItemCount={props.totalItemCount}
        onChange={props.onChange}
      />
    </div>
  )
};

export default DeliveryList;
