import * as React from 'react'
import { Button } from 'antd'

import { Container, Title, Contents, ButtonWrapper, Text, BrSp, BrPc, SPNone } from './style'

export interface IMaintenanceProps {}

const Maintenance: React.FC<IMaintenanceProps> = (props) => {
  // メンテナンスの完了予定日
  const maintenanceCompleteExpectedDate = '～3月15日（火）23:59'

  return (
    <Container>
      <Contents>
        <Title>ただいまメンテナンス中です。</Title>
        <Title>
          メンテナンス完了予定日<SPNone>：</SPNone>
          <BrSp />
          {maintenanceCompleteExpectedDate}
        </Title>
        <Text>
          お客様アンケートのご協力ありがとうございます。
          <BrPc />
          誠に勝手ながら、システムのメンテナンスを行っております。
          <BrPc />
          恐れ入りますが、時間をおいて再度アクセスいただけますと幸いです。
        </Text>

        <Text mt={54}>
          時間をおいても表示が切り替わらない場合は、お手数ですが一度画面を閉じていただき、 <BrPc />
          メール内のURLからアクセスをお試しください。
        </Text>
        <ButtonWrapper>
          <Button type={'primary'} onClick={() => window.location.reload()}>
            再読み込み
          </Button>
        </ButtonWrapper>
      </Contents>
    </Container>
  )
}

export default Maintenance
