import config from 'config/config.setting'

export const breadcrumbTitle = {
  [config.paths.answererQuestionnaire]: '',
  [config.paths.staffRegisterQuestionnaire]: 'アンケートの登録',
  [config.paths.staffEditQuestionnaire]: 'アンケートの編集',
  [config.paths.staffQuestionnaireList]: 'アンケート',
  [config.paths.staffDeliveryList]: 'アンケート配信',
  [config.paths.staffClientDeliveryList]: '顧客別配信状況',
  [config.paths.staffRegisterDistributionList]: '配信リスト登録',
  [config.paths.staffAnswerList]: 'アンケート回答',
  [config.paths.staffAnswerDetail]: '契約詳細',
  [config.paths.staffHiddenQuestionnaireList]: '非表示アンケート',
  [config.paths.staffAnswererList]: 'お客様配信一覧',
  [config.paths.staffTagList]: 'LMD連携タグ一覧',
  [config.paths.staffTagCreate]: 'LMD連携タグの新規作成',
} as { [key in string]: string }

export const breadcrumbSubTitle = {
  [config.paths.answererQuestionnaire]: '',
  [config.paths.staffRegisterQuestionnaire]: 'アンケート情報を登録いただけます。',
  [config.paths.staffEditQuestionnaire]: 'アンケート情報を編集いただけます。',
  [config.paths.staffQuestionnaireList]: 'アンケートをご確認いただけます。',
  [config.paths.staffDeliveryList]: 'アンケートの配信リストをご確認いただけます。',
  [config.paths.staffClientDeliveryList]: 'アンケートに紐づく顧客情報を確認することができます。',
  [config.paths.staffRegisterDistributionList]: '配信リストをご登録いただけます。',
  [config.paths.staffAnswerList]: 'アンケート回答をご確認いただけます。',
  [config.paths.staffAnswerDetail]: '契約詳細をご確認いただけます。',
  [config.paths.staffHiddenQuestionnaireList]: '非表示アンケートをご確認いただけます。',
  [config.paths.staffAnswererList]: 'お客様への配信の一覧をご確認いただけます。',
  [config.paths.staffTagList]: '連携に必要となるLMD連携タグの一覧をご確認いただけます。',
  [config.paths.staffTagCreate]: '連携に必要となるLMD連携タグの作成いただけます。',
} as { [key in string]: string }
