import {Button, Result} from 'antd'
import * as React from 'react'
import * as config from "config"
import {Role} from "../../../constance/constance.role"
import {useHistory} from "react-router-dom"

export interface I404Props {
}

const Error404: React.FC<I404Props> = props =>
{
  const history = useHistory()
  return (
    <Result
      status="404"
      title="404 エラー"
      subTitle="ページが見つかりませんでした。"
      extra={config.setting.role === Role.Staff ? (
        <Button type="primary" onClick={() => history.replace(config.setting.paths.login)}>
          トップページへ戻る
        </Button>
      ) : (
        <Button type="primary" onClick={() => window.location.href = config.setting.paths.homepage}>
          ホームページへ戻る
        </Button>
      )}
    />
  )
}

export default Error404
