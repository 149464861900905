import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import * as $apis from 'apis'
import { message } from 'antd'
import { AnswerStatus } from 'constance/constance.display'

export const useDelivery = () => {
  const { hash } = useParams() as { hash: string }
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [questionnaire, setQuestionnaire] = useState<Questionnaire | null>(null)
  const [answererStatus, setAnswererStatus] = useState<AnswerStatus>()

  const effectQuery = useCallback(async () => {
    try {
      const response = await $apis.delivery.getByHash(hash)

      if (response.data.answerStatus !== AnswerStatus.Answered) {
        setQuestionnaire(response.data.questionnaire)
      }
      setAnswererStatus(response.data.answerStatus)
    } catch (e) {
      console.log(e)
      message.error('アンケートの取得に失敗しました。')
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [hash])

  useEffect(() => {
    effectQuery()
  }, [effectQuery])

  return { error, loading, questionnaire, answererStatus, hash }
}
