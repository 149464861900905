import Service from 'helper/helper.service'

/**
 * @extends {Service}
 */
class FileService extends Service {
  /**
   * @returns
   */
  async remove<T = FileRemoveResponseDto>(params: FileRemoveRequestDto) {
    return await this.delete<T>(params.url)
  }
}

export default new FileService()
