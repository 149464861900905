import * as React from 'react'
import {Layout} from 'antd'

export interface IFooterProps {
}

const {Footer} = Layout

const FooterWrapper: React.FC<IFooterProps> = props => {
  return <Footer style={{textAlign: 'center', background:"rgba(0,174,239,0.01)"}}>© TOKYU LIVABLE,INC.All Right Reserved.</Footer>
}

export default FooterWrapper
