import {PageHeader} from 'antd'
import * as React from 'react'
import useBreadcrumbs from "hooks/hooks.useBreadcrumb"

export interface IPageHeaderProps {
}

const PageHeaderWrapper: React.FC<IPageHeaderProps> = props => {

  /*
  const {breadcrumbs, title, subTitle} = useBreadcrumbs()
  */
  const {title, subTitle} = useBreadcrumbs()

  return (
    <PageHeader
      className="site-page-header"
      title={title}
      // breadcrumb={{routes: breadcrumbs}}
      subTitle={subTitle}
    />
  )
}

export default PageHeaderWrapper
