import * as React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import useBreadcrumbs from 'hooks/hooks.useBreadcrumb'
import { getSubTitleByPath, getTitleByPath } from 'helper/helper.breadcrumb'
import * as config from 'config'
import * as $apis from 'apis'
import {
  questionnaireDisplayName,
  QuestionnaireType,
  questionnaireTypeColor,
  questionnaireTypeName,
} from 'constance/constance.display'
import CsvExport from 'components/CsvExport'
import { orderByText } from 'helper/helper.argo'
import { formatDate } from 'helper/helper.moment'
import { Button, Tag } from 'antd'
import Table from 'components/Table'
import ReDisplayBtnModal from './components/ReDisplayBtnModal'
import RemoveBtnModal from './components/RemoveBtnModal'

export interface IHiddenQuestionnaireListProps {}

const HiddenQuestionnaireList: React.FC<IHiddenQuestionnaireListProps> = (props) => {
  const location = useLocation()
  const [list, setList] = useState<Questionnaire[]>([])
  const [loading, setLoading] = useState(true)
  const { setBreadcrumbs, setTitle, setSubTitle } = useBreadcrumbs()

  // 再表示ボタンを押したときのモーダル
  const [isReDisplayBtnModal, setIsReDisplayBtnModal] = useState<boolean>(false)

  // 削除ボさンを押したときのモーダル
  const [isRemoveBtnModal, setIsRemoveBtnModal] = useState<boolean>(false)

  // 再表示・削除にするアンケート
  const [choiceQuestionnaire, setChoiceQuestionnaire] = useState<Questionnaire | null>(null)

  // 再表示ボタンを押したときの関数
  const reDisplayBtnModal = (questionnaire: Questionnaire) => {
    setIsReDisplayBtnModal(true)
    setChoiceQuestionnaire(questionnaire)
  }

  // 削除ボタンを押したときの関数
  const showRemoveBtnModal = (questionnaire: Questionnaire) => {
    setIsRemoveBtnModal(true)
    setChoiceQuestionnaire(questionnaire)
  }

  useEffect(() => {
    setSubTitle(getSubTitleByPath(location.pathname))
    setTitle(getTitleByPath(location.pathname))
    setBreadcrumbs([
      {
        path: config.setting.paths.staffQuestionnaireList,
        breadcrumbName: getTitleByPath(config.setting.paths.staffQuestionnaireList),
      },
    ])
  }, [location.pathname, setBreadcrumbs, setSubTitle, setTitle])

  const effectQuery = useCallback(async () => {
    try {
      const res = await $apis.questionnaire.getHiddenList()
      setList(res.data)
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    effectQuery().then((r) => console.log(''))
  }, [effectQuery])

  const columns = [
    {
      title: questionnaireDisplayName.id,
      dataIndex: 'id',
      key: 'id',
      filterMultiple: false,
      onFilter: (value: string | number | boolean, record: Questionnaire) =>
        typeof value === 'string' && record.title.indexOf(value) === 0,
      sorter: (a: Questionnaire, b: Questionnaire) => {
        return Number(a.id) - Number(b.id)
      },
    },
    {
      title: questionnaireDisplayName.title,
      dataIndex: 'title',
      key: 'title',
      render: (text: string, questionnaire: Questionnaire) => {
        return (
          <>
            <Link to={`${config.setting.paths.staffQuestionnaireDetail}/${questionnaire.hash}`}>{text}</Link>
            <p>作成日：{formatDate(questionnaire.createdAt)}</p>
          </>
        )
      },
      filterMultiple: false,
      onFilter: (value: string | number | boolean, record: Questionnaire) =>
        typeof value === 'string' && record.title.indexOf(value) === 0,
      sorter: (a: Questionnaire, b: Questionnaire) => orderByText(a.title, b.title),
    },
    {
      title: questionnaireDisplayName.type,
      dataIndex: 'type',
      key: 'type',
      filterMultiple: false,
      filters: [
        { text: questionnaireTypeName[QuestionnaireType.Contract], value: QuestionnaireType.Contract },
        { text: questionnaireTypeName[QuestionnaireType.Delivery], value: QuestionnaireType.Delivery },
        { text: questionnaireTypeName[QuestionnaireType.Seller], value: QuestionnaireType.Seller },
        { text: questionnaireTypeName[QuestionnaireType.Buyer], value: QuestionnaireType.Buyer },
        { text: questionnaireTypeName[QuestionnaireType.Borrower], value: QuestionnaireType.Borrower },
        { text: questionnaireTypeName[QuestionnaireType.Lender], value: QuestionnaireType.Lender },
        { text: questionnaireTypeName[QuestionnaireType.GrantactBuyer], value: QuestionnaireType.GrantactBuyer },
        { text: questionnaireTypeName[QuestionnaireType.GrantactSeller], value: QuestionnaireType.GrantactSeller },
      ],
      render: (_: number, questionnaire: Questionnaire) => (
        <span>
          <Tag color={questionnaireTypeColor[questionnaire.type]}>{questionnaireTypeName[questionnaire.type]}</Tag>
        </span>
      ),
      onFilter: (value: string | number | boolean, record: Questionnaire) =>
        typeof value === 'number' && record.type === value,
    },
    {
      title: '回答結果',
      filterMultiple: false,
      render: (_: string, questionnaire: Questionnaire) => {
        return (
          <span>
            <CsvExport questionnaire={questionnaire} />
          </span>
        )
      },
    },
    {
      title: '',
      filterMultiple: false,
      render: (_: string, questionnaire: Questionnaire) => {
        return (
          <span>
            <Button danger onClick={() => reDisplayBtnModal(questionnaire)}>
              再表示
            </Button>
          </span>
        )
      },
    },
    {
      title: '',
      filterMultiple: false,
      render: (_: string, questionnaire: Questionnaire) => {
        return (
          <span>
            <Button
              danger
              // disabled={questionnaire.answerers[0].status === StatusType.UndoSend}
              onClick={() => showRemoveBtnModal(questionnaire)}
            >
              削除
            </Button>
          </span>
        )
      },
    },
  ]

  return (
    <>
      {/*アンケート再表示 モーダル*/}
      <ReDisplayBtnModal
        isReDisplayBtnModal={isReDisplayBtnModal}
        setIsReDisplayBtnModal={setIsReDisplayBtnModal}
        choiceQuestionnaire={choiceQuestionnaire}
        setChoiceQuestionnaire={setChoiceQuestionnaire}
        effectQuery={effectQuery}
      />

      {/*アンケート削除ボタンモーダル*/}
      <RemoveBtnModal
        isRemoveBtnModal={isRemoveBtnModal}
        setIsRemoveBtnModal={setIsRemoveBtnModal}
        choiceQuestionnaire={choiceQuestionnaire}
        setChoiceQuestionnaire={setChoiceQuestionnaire}
        effectQuery={effectQuery}
      />
      <Table<Questionnaire> loading={loading} columns={columns} data={list} rowKey={(record) => record.hash} />
    </>
  )
}

export default HiddenQuestionnaireList
