import * as React from 'react'
import * as $apis from 'apis'
import { Modal } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
import { questionnaireDisplayName } from '../../constance'
import { message } from 'antd'

export type IConfirmModalButtonProps = {
  isDisplayModal: boolean
  setIsDisplayModal: React.Dispatch<React.SetStateAction<boolean>>
  selectTag: Tag
  getTags: () => void
}

// タグを有効・無効にする前の確認モーダル
const ConfirmModalButton = (props: IConfirmModalButtonProps) => {
  const [confirmLoading, setConfirmLoading] = React.useState(false)

  const handleOk = async () => {
    setConfirmLoading(true)
    try {
      if (props.selectTag?.deactivated) {
        await $apis.tag.activate(props.selectTag)
        message.success('LMD連携タグを有効にしました。')
      } else {
        await $apis.tag.deactivate(props.selectTag)
        message.success('LMD連携タグを無効にしました。')
      }
      await props.getTags()
    } catch (e) {
      console.log(e)
    } finally {
      setConfirmLoading(false)
      props.setIsDisplayModal(false)
    }
  }

  const handleCancel = (e: any) => {
    props.setIsDisplayModal(false)
  }

  return (
    <>
      <Modal
        title={
          <div style={{ maxWidth: 460 }}>
            {props.selectTag?.name} タグを{props.selectTag?.deactivated ? '有効' : '無効'}にしますか？
          </div>
        }
        visible={props.isDisplayModal}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="キャンセル"
        confirmLoading={confirmLoading}
        okText={`${props.selectTag?.deactivated ? '有効' : '無効'}にする`}
      >
        {/*モーダル*/}
        <ul className="modal-confirm-content">
          <li>
            <Paragraph>
              <span>{questionnaireDisplayName.title}</span>
              <span>{props.selectTag?.questionnaire?.title}</span>
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              <span>{questionnaireDisplayName.tag}</span>
              <span>{props.selectTag?.name}</span>
            </Paragraph>
          </li>
        </ul>
      </Modal>
    </>
  )
}

export default ConfirmModalButton
