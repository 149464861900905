import React, {FC, HTMLAttributes, useState} from 'react'
import {BreadcrumbContext} from './BreadcrumbContext'
import {Route} from "antd/lib/breadcrumb/Breadcrumb"

const BreadcrumbProvider: FC<HTMLAttributes<HTMLElement>> = ({children,}) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Route[]>([])
  const [title, setTitle] = useState<string>('')
  const [subTitle, setSubTitle] = useState<string>('')
  return (
    <BreadcrumbContext.Provider value={{setBreadcrumbs, breadcrumbs, setTitle, title, setSubTitle, subTitle}}>
      {children}
    </BreadcrumbContext.Provider>
  )
}

export default BreadcrumbProvider
