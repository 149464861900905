import React, { PropsWithChildren } from 'react'
import { Checkbox, Form, Typography } from 'antd'
import { QuestionType } from '../../../constance/constance.display'

type IProps<T> = {
  sectionNo: number
  questionNo: number
  list: T[]
  questionText: string
  required?: boolean
}

const { Text } = Typography

const CheckBoxComponent = <T extends { no: number; text: string }>({
  sectionNo,
  questionNo,
  list,
  questionText,
  required = true,
}: PropsWithChildren<IProps<T>>) => {
  return (
    <>
      <div className="questionnaire-form__text-wrapper">
        <Text className="questionnaire-form__text">{questionText}</Text>
      </div>

      <Form.Item
        className="questionnaire-form-item"
        name={[sectionNo, questionNo, QuestionType.CheckBox]}
        rules={[{ required, message: '必ず1つ以上チェックしてください。' }]}
      >
        <Checkbox.Group>
          {list.map((t, index: number) => {
            return (
              <Checkbox key={index} value={t.no} style={checkboxStyle}>
                {t.text}
              </Checkbox>
            )
          })}
        </Checkbox.Group>
      </Form.Item>
    </>
  )
}

const checkboxStyle = {
  display: 'flex',
  lineHeight: '30px',
  marginBottom: '8px',
  marginLeft: 0,
}

export default CheckBoxComponent
