import * as React from 'react'
import { message, Modal } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
import * as $apis from '../../apis'

export interface IUnSyncedConfirmModalProps {
  isDisplayModal: boolean
  setIsDisplayModal: React.Dispatch<React.SetStateAction<boolean>>
  selectUnSyncedTag: UnSyncedTag
  getUnSyncedTags: () => void
}

// 同期をしないを押したときのボタン
const UnSyncedConfirmModal: React.FC<IUnSyncedConfirmModalProps> = (props) => {
  const [confirmLoading, setConfirmLoading] = React.useState(false)

  const unSynchronizeExec = async () => {
    setConfirmLoading(true)
    try {
      const res = await $apis.tag.unSynchronizeExec(props.selectUnSyncedTag?.fileName)
      // setFinSynchronizedTag(res.data)
      message.success(`指定の配信リストを同期せずに保管しました。`)
      // setIsSynchronizeExecFin(true)

      await props.getUnSyncedTags()
    } catch (e) {
      message.error(e.response.data)
    } finally {
      props.setIsDisplayModal(false)
      setConfirmLoading(false)
    }
  }

  const handleCancel = (e: any) => {
    props.setIsDisplayModal(false)
  }

  return (
    <div className="un-synced-confirm-modal">
      <Modal
        title={`${props.selectUnSyncedTag?.fileName}ファイルを同期しませんか？`}
        visible={props.isDisplayModal}
        onOk={unSynchronizeExec}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        cancelText={<></>}
        okText={'同期しない'}
      >
        {/*モーダル*/}
        <ul className="modal-confirm-content">
          <li>
            <Paragraph>
              <span>未連携ファイル名</span>
              <span>{props.selectUnSyncedTag?.fileName}</span>
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              <span>推奨タグ名</span>
              <span>{props.selectUnSyncedTag?.format}</span>
            </Paragraph>
          </li>
        </ul>
      </Modal>
    </div>
  )
}

export default UnSyncedConfirmModal
