import React, {PropsWithChildren, useEffect} from "react"
import {Col, Form, Row, Radio, Typography} from "antd"
import {QuestionType} from "../../../constance/constance.display"
import {ArrowRightOutlined, ArrowLeftOutlined, CaretDownOutlined} from '@ant-design/icons';
import "../../Questionnaire/Questionnaire.less"

type IProps<T> = {
  isLastQuestion: boolean
  sectionNo: number
  questionNo: number
  list: T[]
  required?: boolean
  questionText: string
}

const {Text} = Typography
const FlowComponent = <T extends { no: number, text: string }, >
({
   isLastQuestion,
   sectionNo,
   questionNo,
   questionText,
   list,
   required = true,
 }: PropsWithChildren<IProps<T>>) => {

  useEffect(() => {
    document.getElementById(`${sectionNo}-${questionNo}`)?.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.key === "ArrowLeft" || e.key === "ArrowRight" || e.key === "ArrowDown" || e.key === "ArrowUp") {
        e.preventDefault()
      }
    })
  }, [questionNo, sectionNo])

  return (
      <>
        <div className="questionnaire-form-flow">
          <div className="questionnaire-form-flow__text-wrapper">
            <Text className="questionnaire-form__text">{questionText}</Text>
          </div>

          <Form.Item
              className="questionnaire-form-flow-item"
              name={[sectionNo, questionNo, QuestionType.Flow]}
              rules={[{required, message: `必ず1つ選択してください。`}]}
          >
            <Row justify="center">
              <Col xs={24} md={12}>
                <Radio.Group id={`${sectionNo}-${questionNo}`} className="questionnaire-form-flow-item__radio">
                  {list.map((t, index: number) => {
                    return (
                      <Radio.Button
                        key={index}
                        className="questionnaire-form-flow-item__button"
                        value={Number(t.no)}>
                        {t.no}
                      </Radio.Button>
                    )
                  })}
                </Radio.Group>
              </Col>
            </Row>
          </Form.Item>

          <Row justify="center" align="middle">
            <Col xs={24} md={12}>
              <Row justify="space-between" align="middle">
                <Text>低</Text>
                <ArrowLeftOutlined style={{fontSize: 16}}/>
                <Text>満足度</Text>
                <ArrowRightOutlined style={{fontSize: 16}}/>
                <Text>高</Text>
              </Row>
            </Col>
          </Row>
        </div>

        {
          !isLastQuestion ? (
              <div className="questionnaire-form-flow-item__triangle">
                <CaretDownOutlined style={{fontSize: 32}}/>
              </div>
          ) : null
        }
      </>
  )
}

export default FlowComponent
