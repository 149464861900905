import Service from 'helper/helper.service'
import config from 'config/config.setting'
import { SubscribeType } from 'constance'

/**
 * @extends {Service}
 */
class ClientService extends Service {
  /**
   * @returns
   */
  async getList<T = ClientResponseDto>(hash: string, params: QueryParams) {
    const encoded = encodeURIComponent(hash)
    return await this.get<T>(config.endpoints.client(encoded), params)
  }
  
  /**
   * @returns
   */
  async cancel<T = null, U = null>(data: U, params: QueryParams) {
    return await this.post<T, U>(config.endpoints.clientDeliveryCancel(params.clientId, params.subscribeType), data)
  }
}

export default new ClientService()
