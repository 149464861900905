import Service from 'helper/helper.service'

/**
 * @class MemberService
 * @extends {Service}
 */
class MemberService extends Service {
}

export default new MemberService()
