import * as React from 'react'
import { TimePicker as AntTimePicker } from 'antd'
import moment, { Moment } from 'moment'
import './TimePicker.less'
import { momentParse } from 'helper/helper.momentParse'

export interface ITimePickerProps {
  callback: (moment: Moment | null) => void
  nowButtonCallback: () => void
  defaultTime?: Moment
  sentAt: Moment
  width?: number
  mt?: number
}

const TimePicker: React.FC<ITimePickerProps> = (props) => {
  return (
    <div className="time-picker" style={{ marginTop: props.mt }}>
      <AntTimePicker
        style={{ width: props.width }}
        onOk={props.callback} // OKボタンを押すとトリガーが発動する
        onChange={props.nowButtonCallback} // 選択している時間が変更される時にトリガーが発動する　nowボタンを押したときに時間が変更されるので呼ばれる
        defaultValue={props.defaultTime ? props.defaultTime : moment('16:00:00', 'HH:mm:ss')}
        value={props.sentAt}
        format={'HH時mm分'}
        minuteStep={10}
        disabledDate={(current: Moment) => {
          // 現在登録してある、year, month,dayの値を抽出して、現在選択されている時間を抽出する
          const { year, month, day } = momentParse(props.sentAt)
          const timeSelectedCurrent = moment({
            year: year,
            month: month - 1, // timePickerを操作したらなぜか+されてしまうので-1する
            day: day,
            hour: Number(current?.format('HH')),
            minute: Number(current?.format('mm')),
          })
          // 送信時間 < 現在の時間 なら 「ok」ボタンが押せないようにする
          return timeSelectedCurrent < moment().add(-1, 'minute')
        }}
      />
    </div>
  )
}

export default TimePicker
