import * as React from 'react'
import { useState } from 'react'
import { Button, Col, Row } from 'antd'
import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface'
import { AntTable } from './style'

export interface ITableProps<T = any> {
  data: T[]
  columns: Partial<ColumnGroupType<T> & ColumnType<T>>[]
  availableClearFilters?: boolean
  availableClearAll?: boolean
  loading?: boolean
}

const TableWrapper = <T extends object = any>(props: ITableProps<T>) => {
  const [state, setState] = useState<{
    filteredInfo: null | { [key in keyof T]: string[] }
    sortedInfo: null | { [key in keyof T]: string[] }
  }>({
    filteredInfo: null,
    sortedInfo: null,
  })

  function onChange(pagination: any, filters: any, sorter: any, extra: any) {
    console.log('params', pagination, filters, sorter, extra)
    setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }

  function clearFilters() {
    setState({ ...state, filteredInfo: null })
  }

  function clearAll() {
    setState({
      filteredInfo: null,
      sortedInfo: null,
    })
  }

  const columns = props.columns.map((column) => {
    if (column.filters && column.filters.length > 0 && column.dataIndex) {
      const key = column.dataIndex as keyof T
      const filteredValue = state.filteredInfo?.[key] ?? null
      return { ...column, filteredValue }
    } else {
      return column
    }
  })

  return (
    <>
      <Row gutter={[0, 12]} justify="end">
        <Col>
          {props.availableClearFilters && <Button onClick={clearFilters}>フィルターをはずす</Button>}
          {props.availableClearAll && <Button onClick={clearAll}>フィルターとソートをはずす</Button>}
        </Col>
      </Row>
      <AntTable<any>
        loading={props.loading}
        ant-table-thead
        columns={columns}
        dataSource={props.data}
        onChange={onChange}
        pagination={false}
        paddingTopAndBottom={10}
      />
    </>
  )
}

export default TableWrapper
