import * as React from 'react'
import {useEffect, useState} from 'react'
import styled from 'styled-components'
import * as $apis from 'apis'

export interface IEnvironmentProps {
}

const Container = styled.div`
  display: flex;
  padding: 4px 16px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  background: #CCCCCC;
`

const Item = styled.div`
  margin-right: 24px;
`

enum EnvName {
  Staging = "ステージング",
  Production = "本番",
  Test = "テスト",
  Development = "開発",
}

const Environment: React.FC<IEnvironmentProps> = props => {
  const [environment, setEnvironment] = useState<Environment>()
  const getEnvironment = async () => {
    const response = await $apis.setting.environment()
    setEnvironment(response.data)
  }

  const formatTime = (time: string) => {
    const times = time.split(':')
    const hour = Number(times[0])
    const minute = Number(times[1])
    const second = Number(times[2])
    let formatted = ""
    if (hour) {
      formatted += hour + "時間"
    }
    if (minute) {
      formatted += minute + "分"
    }
    if (second) {
      formatted += second + "秒"
    }
    return formatted
  }

  const formatDate = (interval: string | undefined) => {

    if (!interval) {
      return null
    }
    const data = interval.split('.')
    if (data.length > 1) {
      return data[0] + "日" + formatTime(data[1]) + "後"
    } else {
      return formatTime(data[0]) + "後"
    }
  }

  useEffect(() => {
    getEnvironment()
  }, [])

  return (
    <Container>
      <Item>
        環境：{environment?.environment ? EnvName[environment?.environment] : ""}
      </Item>
      <Item>
        接続先URL：{environment?.questionnaireBaseUrl}
      </Item>
      <Item>
        再送までの期間：{environment?.retransmissionInterval ? formatDate(environment?.retransmissionInterval) : ""}
      </Item>
    </Container>
  )
}

export default Environment
