import React, { useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom"
import { Card, Skeleton } from "antd"
import * as $apis from "apis"

export interface IBeforeRedirectProps {
}

const BeforeRedirect: React.FC<IBeforeRedirectProps> = props => {
  const history = useHistory()
  const params: {"id": string} = useParams();

  const init = async () => {
    $apis.questionnaire.getQuestionnairePass(params.id)
      .then((res) => {
        history.replace(res.data)
      }).catch(e => {
      console.log(e)
    });
  };

  useEffect(() => {
    init()
  }, [])


  return (
    <div className="questionnaire-root">
      <Card className="questionnaire-title">
        <div className="questionnaire-title__header"/>
        <Skeleton loading={true} active>
        </Skeleton>
      </Card>
    </div>
  )
}


export default BeforeRedirect;
