import { Table, Pagination  } from 'antd';
import * as React from 'react';
import styled from "styled-components"
import {ColumnGroupType, ColumnType} from "antd/lib/table/interface"

export interface ICustomTableProps<T> {
  loading: boolean
  data: T[]
  rowKey: (row: T) => string
  columns: Partial<(ColumnGroupType<T> & ColumnType<T>)>[]
  onChange: (current: number, size?: number) => void
  totalItemCount: number
  pageNumber: number
  pageSize: number
}

const Container = styled.div``
const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
const TableContainer = styled.div`
  margin-bottom: 24px
`

const CustomTable = <T extends object = any, >(props: ICustomTableProps<T>) => {
  return (
    <Container>
      <TableContainer>
        <Table
          columns={props.columns}
          loading={props.loading}
          dataSource={props.data}
          rowKey={props.rowKey}
          pagination={false}
        />
      </TableContainer>
      <PaginationContainer>
        <Pagination
          showSizeChanger
          pageSize={props.pageSize}
          current={props.pageNumber}
          onChange={props.onChange}
          onShowSizeChange={props.onChange}
          total={props.totalItemCount}
        />
      </PaginationContainer>
    </Container>
  );
};

export default CustomTable;
