import {Button, Result} from 'antd'
import * as React from 'react'
import {useHistory} from 'react-router-dom'

export interface I500Props {
}

const Error500: React.FC<I500Props> = props => {
  const history = useHistory()
  return (
    <Result
      status="500"
      title="500"
      subTitle="エラーが発生しました。管理者にお問い合わせください。"
      extra={<Button type="primary" onClick={() => history.goBack()}>戻る</Button>}
    />
  )
}

export default Error500
