import React, { PropsWithChildren } from 'react'
import { Form, Input, Typography } from 'antd'
import { QuestionType } from 'constance/constance.display'

type IProps = {
  sectionNo: number
  questionNo: number
  questionText: string
  required?: boolean
  type?: string
  maxLength?: number
  questionType: QuestionType
}

const { Text } = Typography

const InputComponent = ({
  sectionNo,
  questionNo,
  questionText,
  required = true,
  type = 'text',
  maxLength,
  questionType,
}: PropsWithChildren<IProps>) => {
  return (
    <>
      <div className="questionnaire-form__text-wrapper">
        <Text className="questionnaire-form__text">{questionText}</Text>
      </div>

      <Form.Item
        className="questionnaire-form-item"
        name={[sectionNo, questionNo, questionType]}
        rules={[{ required, message: '必ず入力してください。' }]}
      >
        <Input className="questionnaire-form__input" placeholder="メールアドレス" type={type} maxLength={maxLength} />
      </Form.Item>
    </>
  )
}

export default InputComponent
