import {useParams} from "react-router-dom"
import {useCallback, useEffect, useState} from "react"
import * as $apis from "apis"
import {message} from "antd"

export const useQuestionnaire = () => {

  const {hash} = useParams() as { hash: string }
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [questionnaire, setQuestionnaire] = useState<Questionnaire | null>(null)

  const effectQuery = useCallback(async () => {
    try {
      const response = await $apis.questionnaire.getByHash(hash)
      const data = response.data;
      setQuestionnaire(data)
    } catch (e) {
      console.log(e)
      message.error('アンケートの取得に失敗しました。')
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [hash])

  useEffect(() => {
    effectQuery()
  }, [effectQuery])

  return {error, loading, questionnaire, hash}
}
