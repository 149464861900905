import axios from 'axios'
import { Env } from 'constance/constance.env'
import { Role } from 'constance/constance.role'
import storage from 'helper/helper.storage'
import { Mode } from 'constance/constance.mode'
import { SubscribeType } from 'constance'

const DEFAULT_ENDPOINT = `http://localhost:5000`
const roleStr = process.env.REACT_APP_ROLE as keyof typeof Role
const envStr = process.env.REACT_APP_ENV as keyof typeof Env
const env = Env[envStr]
const role = Role[roleStr]

const modeStr = process.env.REACT_APP_MODE as keyof typeof Mode
const mode = Mode[modeStr]

let apiBaseUrl = ''
if (role === Role.Staff) {
  apiBaseUrl = process.env.REACT_APP_CJ_API_DOMAIN || DEFAULT_ENDPOINT
} else if (role === Role.Answerer) {
  apiBaseUrl = process.env.REACT_APP_CS_API_DOMAIN || DEFAULT_ENDPOINT
}

const settings = {
  role,
  env,
  mode,
  requestType: 'rest',
  endpoints: {
    login: `/api/auth/login`,
    signUp: `/api/auth/signup`,
    logout: `/api/auth/logout`,
    questionnaires: `/api/questionnaires`,
    questionnaire: (id: string) => `/api/questionnaire/${id}`,
    questionnairePass: (hash: string) => `/api/answer/${hash}`, // ショートURLのパスパラメーターから、アンケートの通常URLを取得
    questionnaireCSV: (hash: string) => `/api/questionnaire/csv/${hash}`,
    questionnaireMailText: (hash: string) => `/api/questionnaire/${hash}/mailText`,
    questionnaireSmsText: (hash: string) => `/api/questionnaire/${hash}/smsText`,
    questionnaireRegisterFile: `/api/questionnaire`,
    questionnaireDeliveries: `/api/deliveries`,
    questionnaireDeliveryAnswer: (hash: string) => `/api/answer/questionnaire/${hash}`,
    questionnaireDelivery: `/api/delivery`,
    questionnaireDeliverCSV: (hash: string) => `/api/delivery/csv/${hash}`,
    questionnaireDeliveryCancel: `/api/delivery/cancel`,
    questionnaireAnswer: `/api/answer`, // アンケートの回答を保存
    client: (hash: string) => `/api/delivery/answerers/${hash}`, // アンケートに紐づく顧客の配信を停止する
    clientDeliveryCancel: (clientId: string, type: SubscribeType) => `/api/delivery/cancel/${clientId}/${type}`, // 非表示のアカウントリスト
    archiveQuestionnaires: '/api/questionnaires/archive',
    questionnaireDeliveryDuplicateCheck: '/api/delivery/check', //配信アンケートのダブルチェック
    environment: '/api/environment',
    tag: `/api/tags`, // LMD連携タグ
    tagActivated: `/api/tags/activated`, // LMD連携タグ
    tagDeactivated: `/api/tags/deactivated`, // LMD連携タグ
    tagUnSynced: '/api/tags/UnSync/Files', // LMD連携（同期)できていないファイルを取得
    tagSynchronizeExec: (fileName: string) => `/api/tags/Sync/File/${fileName}`, //タグの同期を実行
    tagUnSynchronizeExec: (fileName: string) => `/api/tags/unSync/File/${fileName}`, //タグの同期を実行しない
    // unused
    contract: (id: string) => `/contracts/${id}`,
    contracts: '/contracts',
    contractArticle: '/contracts/article',
  },
  paths: {
    root: '/',
    homepage: 'https://www.livable.co.jp/',
    baibaipage: 'https://www.livable.co.jp/shiritai/user/baibai/',
    chintaipage: 'https://www.livable.co.jp/shiritai/user/chintai/',
    // guest
    login: '/login',
    logout: '/logout',
    signUp: '/signup',
    // staff
    staffQuestionnaireList: '/staff/questionnaires',
    staffHiddenQuestionnaireList: '/staff/hiddenQuestionnaires',
    staffQuestionnaireDetail: '/staff/questionnaires/detail',
    staffRegisterQuestionnaire: '/staff/questionnaires/register',
    staffEditQuestionnaire: '/staff/questionnaires/edit',
    staffRegisterDistributionList: '/staff/deliveries/register',
    staffDeliveryList: '/staff/deliveries',
    staffClientDeliveryList: '/staff/deliveries/clientList', // 配信リストに紐づく顧客リスト
    staffAnswerList: '/staff/answers',
    staffAnswerDetail: '/staff/answers/detail',
    staffAnswererList: '/staff/answerers',
    staffTagList: '/staff/tags',
    staffTagCreate: '/staff/tags/create',
    // Answerer
    answererQuestionnaire: '/answerer/questionnaire',
    answererQuestionnaireConfirm: '/answerer/questionnaires/confirm',
    answererQuestionnaireThanks: '/answerer/questionnaires/thanks',
    answererBeforeRedirect: (hash: string) => `/r/${hash}`, // ショートURLをクリックした時に表示されるページ
    // all
    myPage: '/my-page',
    error404: '/error/404',
    error500: '/error/500',
    maintenance: '/maintenance',
    noMatch: '*',
  },
}

export default settings

axios.defaults.baseURL = `${apiBaseUrl}`

axios.defaults.headers.post['Content-Type'] = 'application/json'

if (storage.token) {
  axios.defaults.headers['Authorization'] = `Bearer ${storage.token}`
}
