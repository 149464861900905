import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { message, Modal, Typography } from 'antd'
import { useLocation } from 'react-router-dom'
import { questionnaireDisplayName, QuestionnaireType, questionnaireTypeName } from 'constance/constance.display'
import * as config from 'config'
import * as $apis from 'apis'
import useBreadcrumbs from 'hooks/hooks.useBreadcrumb'
import { getSubTitleByPath, getTitleByPath } from 'helper/helper.breadcrumb'
import './Deliveries.less'
import DeliveryList from '../../../components/DeliveryList'

export interface IDeliveriesProps {}

const { Paragraph } = Typography

const Deliveries: React.FC<IDeliveriesProps> = (props) => {
  const location = useLocation()
  const [cancellableQuestionnaire, setCancelQuestionnaire] = useState<Questionnaire | null>(null)
  const [visible, setVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const { setBreadcrumbs, setTitle, setSubTitle } = useBreadcrumbs()

  const [pagination, setPagination] = useState<DeliveryListResponseDto>({
    pageCount: 1,
    totalItemCount: 0,
    pageNumber: 1,
    pageSize: 50,
    hasPreviousPage: false,
    hasNextPage: false,
    isFirstPage: true,
    isLastPage: true,
    firstItemOnPage: 1,
    lastItemOnPage: 1,
    contents: [],
  })

  const onPaginationChange = async (pageNumber: number, pageSize?: number) => {
    try {
      setLoading(true)
      const res = await $apis.delivery.getList({
        pageNumber,
        pageSize,
      })

      setPagination(res.data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const showModal = (questionnaire: Questionnaire) => {
    setVisible(true)
    setCancelQuestionnaire(questionnaire)
  }

  useEffect(() => {
    setSubTitle(getSubTitleByPath(location.pathname))
    setTitle(getTitleByPath(location.pathname))
    setBreadcrumbs([
      {
        path: config.setting.paths.staffQuestionnaireList,
        breadcrumbName: getTitleByPath(config.setting.paths.staffQuestionnaireList),
      },
    ])
  }, [location.pathname, setBreadcrumbs, setSubTitle, setTitle])

  const effectQuery = useCallback(async () => {
    try {
      setLoading(true)
      const res = await $apis.delivery.getList({
        pageNumber: pagination.pageNumber,
        pageSize: pagination.pageSize,
      })

      setPagination(res.data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [pagination.pageNumber, pagination.pageSize])

  useEffect(() => {
    effectQuery().then((r) => console.log(''))
  }, [])

  // 「停止する」ボタンを押したときのローディング状況
  const [confirmLoading, setConfirmLoading] = React.useState(false)

  const handleOk = async (e: any) => {
    setConfirmLoading(true)
    try {
      if (!cancellableQuestionnaire?.id) return
      // const response = await $apis.delivery.cancel(cancellableQuestionnaire)
      await $apis.delivery.cancel(cancellableQuestionnaire)
      setTimeout(() => {
        setCancelQuestionnaire(null)
      }, 100)
      setVisible(false)
      message.success('停止しました。')
      effectQuery()
    } catch (e) {
      console.log(e)
    } finally {
      setConfirmLoading(false)
    }
  }

  const handleCancel = (e: any) => {
    console.log(e)
    setVisible(false)
    setTimeout(() => {
      setCancelQuestionnaire(null)
    }, 100)
  }

  return (
    <>
      <Modal
        title={`${cancellableQuestionnaire?.title ?? ''}の配信を停止しますか`}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="キャンセル"
        confirmLoading={confirmLoading}
        okText="停止する"
      >
        <ul className="modal-confirm-content">
          <li>
            <Paragraph>
              <span>{questionnaireDisplayName.title}</span>
              <span>{cancellableQuestionnaire?.title ?? ''}</span>
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              <span>{questionnaireDisplayName.type}</span>
              <span>{questionnaireTypeName[cancellableQuestionnaire?.type ?? QuestionnaireType.Seller]}</span>
            </Paragraph>
          </li>
        </ul>
      </Modal>

      <DeliveryList
        list={pagination.contents}
        loading={loading}
        showModal={showModal}
        pageNumber={pagination.pageNumber}
        pageSize={pagination.pageSize}
        totalItemCount={pagination.totalItemCount}
        onChange={onPaginationChange}
      />
    </>
  )
}

export default Deliveries
