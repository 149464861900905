import React, { createContext, useState } from 'react'

interface ITagFieldsContext {
  tagName: string
  csvFormat: string
  setTagName: React.Dispatch<React.SetStateAction<any>>
  setCsvFormat: React.Dispatch<React.SetStateAction<any>>
}

// グローバルで持つデフォルトデータを定義する。
export const TagFieldsContext = createContext<ITagFieldsContext>({
  tagName: '',
  csvFormat: '',
  setTagName: () => '',
  setCsvFormat: () => '',
})

// グローバルで持つデータを作成する。
export const TagFieldsProvider: React.FC = (props) => {
  const { children } = props
  const [tagName, setTagName] = useState<string>('')
  const [csvFormat, setCsvFormat] = useState<string>('')

  return (
    <TagFieldsContext.Provider value={{ tagName, setTagName, csvFormat, setCsvFormat }}>
      {children}
    </TagFieldsContext.Provider>
  )
}
