import * as React from 'react'
import { useState } from 'react'
import { Button, Modal } from 'antd'
import * as $apis from 'apis'
import SearchCSVModal from './SearchCSVModal'
import { message } from 'antd'

export interface ICsvExportProps {
  questionnaire: Questionnaire
}

const CsvExport: React.FC<ICsvExportProps> = (props) => {
  const { questionnaire } = props

  const [searchCsvModalVisible, setSearchCsvModalVisible] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)

  const csvDownload = async (data: { from: string | undefined; to: string | undefined }) => {
    const { from, to } = data
    setLoading(true)
    try {
      await $apis.questionnaire.csvReport(props.questionnaire.hash, { from, to })
      closeSearchCsvModal()
      Modal.success({
        title: '回答結果を生成中です。',
        width: 450,
        content: (
          <p>
            完了したらメールにダウンロードURLが届きます。
          </p>
        ),
        onOk() {},
      })
    } catch (e) {
      console.log(e)
      message.error('回答結果の出力に失敗しました。')
    } finally {
      setLoading(false)
    }
  }
  const openSearchCsvModal = () => {
    setSearchCsvModalVisible(true)
  }
  const closeSearchCsvModal = () => {
    setSearchCsvModalVisible(false)
  }
  return (
    <>
      <Button type="default" onClick={openSearchCsvModal}>
        出力
      </Button>

      <SearchCSVModal
        searchCsvModalVisible={searchCsvModalVisible}
        questionnaire={questionnaire}
        csvDownload={csvDownload}
        closeModal={closeSearchCsvModal}
        downloading={loading}
      />
    </>
  )
}

export default CsvExport
