import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Button, Tag } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import Table from 'components/Table'
import {
  Category,
  categoryTypeColor,
  categoryTypeName,
  questionnaireDisplayName,
  QuestionnaireType,
  questionnaireTypeColor,
  questionnaireTypeName,
} from 'constance/constance.display'
import * as config from 'config'
import * as $apis from 'apis'
import { orderByText } from 'helper/helper.argo'
import useBreadcrumbs from 'hooks/hooks.useBreadcrumb'
import { getSubTitleByPath, getTitleByPath } from 'helper/helper.breadcrumb'
import './QuestionnaireList.less'
import RegisterHeader from '../../../components/RegisterHeader'
import { formatDate } from '../../../helper/helper.moment'
import CsvExport from 'components/CsvExport'
import NonVisualBtnModal from './components/NonVisualBtnModal'
import RemoveBtnModal from './components/RemoveBtnModal'
import ShowNotificationPreviewModal from './components/ShowNotificationPreviewModal'

export interface IIPListProps {}

/*
const defaultQuestionnaire = [
  {
    id: '1',
    hash: '123',
    title: 'テストアンケート', //Questionnaire名
    description: '',
    type: QuestionnaireType.Seller, //公開状況
    sections: [],
    answerers: [],
    createdAt: moment().toDate(), //作成日
    updatedAt: moment().toDate(), //更新日
  },
  {
    id: '2',
    hash: '123',
    title: 'テストアンケート', //Questionnaire名
    description: '',
    type: QuestionnaireType.Buyer, //公開状況
    sections: [],
    answerers: [],
    createdAt: moment().toDate(), //作成日
    updatedAt: moment().toDate(), //更新日
  },
  {
    id: '3',
    hash: '123',
    title: 'テストアンケート', //Questionnaire名
    description: '',
    type: QuestionnaireType.Seller, //公開状況
    sections: [],
    answerers: [],
    createdAt: moment().toDate(), //作成日
    updatedAt: moment().toDate(), //更新日
  }
] as Questionnaire[]
*/

const QuestionnaireList: React.FC<IIPListProps> = (props) => {
  const location = useLocation()
  const [list, setList] = useState<Questionnaire[]>([])
  const [loading, setLoading] = useState(true)
  const { setBreadcrumbs, setTitle, setSubTitle } = useBreadcrumbs()

  const [isShowNotificationPreviewModal, setIsShowNotificationPreviewModal] = useState<boolean>(false) // 通知文面プレビューを表示させるプロパティ
  const [isNonVisualBtnModal, setIsNonVisualBtnModal] = useState<boolean>(false) // 非表示ボタンを押したときのプロパティ
  const [isRemoveBtnModal, setIsRemoveBtnModal] = useState<boolean>(false) // 削除ボさンを押したときのプロパティ
  const [cancelQuestionnaire, setCancelQuestionnaire] = useState<Questionnaire | null>(null) // 非表示・削除にする質問
  const [selectQuestionnaire, setSelectQuestionnaire] = useState<Questionnaire | null>(null) // 選択されたアンケート
  const [mailHtml, setMailHtml] = useState<string>('') // アンケートに紐づくメール本文
  const [smsText, setSmsText] = useState<string>('') // アンケートに紐づくSMS本文

  const [notificationPreviewLoading, setNotificationPreviewLoading] = useState<{ questionnaireId: string; loading: boolean }>({
    questionnaireId: '0',
    loading: false,
  })

  //　文面プレビューボタンを押した時に使う言語
  const showPreviewTextBtnModal = async (questionnaire: Questionnaire) => {
    setNotificationPreviewLoading({ questionnaireId: questionnaire.id, loading: true })
    try {
      const [mailRes, smsRes] = await Promise.all([
        $apis.questionnaire.getMailHtml(questionnaire.hash),
        $apis.questionnaire.getSmsText(questionnaire.hash),
      ]);
      setMailHtml(mailRes.data.message)
      setSmsText(smsRes.data.message)
    } catch (e) {
      console.log(e)
    } finally {
      setNotificationPreviewLoading({ questionnaireId: questionnaire.id, loading: false })
    }
    setSelectQuestionnaire(questionnaire)
    setIsShowNotificationPreviewModal(true)
  }

  // 非表示ボタンを押したときの関数
  const showNonVisualBtnModal = (questionnaire: Questionnaire) => {
    setIsNonVisualBtnModal(true)
    setCancelQuestionnaire(questionnaire)
  }

  // 削除ボタンを押したときの関数
  const showRemoveBtnModal = (questionnaire: Questionnaire) => {
    setIsRemoveBtnModal(true)
    setCancelQuestionnaire(questionnaire)
  }

  useEffect(() => {
    setSubTitle(getSubTitleByPath(location.pathname))
    setTitle(getTitleByPath(location.pathname))
    setBreadcrumbs([
      {
        path: config.setting.paths.staffQuestionnaireList,
        breadcrumbName: getTitleByPath(config.setting.paths.staffQuestionnaireList),
      },
    ])
  }, [location.pathname, setBreadcrumbs, setSubTitle, setTitle])

  const effectQuery = useCallback(async () => {
    try {
      const res = await $apis.questionnaire.getList({})

      setList(res.data)
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    effectQuery().then((r) => console.log(''))
  }, [effectQuery])

  const columns = [
    {
      title: questionnaireDisplayName.id,
      dataIndex: 'id',
      key: 'id',
      filterMultiple: false,
      onFilter: (value: string | number | boolean, record: Questionnaire) =>
        typeof value === 'string' && record.title.indexOf(value) === 0,
      sorter: (a: Questionnaire, b: Questionnaire) => {
        return Number(a.id) - Number(b.id)
      },
      render: (text: string, questionnaire: Questionnaire) => {
        return (
          <>
            <span>{text}</span>
          </>
        )
      },
    },
    {
      title: questionnaireDisplayName.tag,
      dataIndex: 'tag',
      key: 'tag',
      render: (text: string, questionnaire: Questionnaire) => {
        return questionnaire.tag ? (
          <Tag color={'orange'}>{questionnaire.tag.name}</Tag>
        ) : (
          <Tag color={'pink'}>{'未登録'}</Tag>
        )
      },
    },
    {
      title: questionnaireDisplayName.title,
      dataIndex: 'title',
      key: 'title',
      render: (text: string, questionnaire: Questionnaire) => {
        return (
          <>
            <Link to={`${config.setting.paths.staffQuestionnaireDetail}/${questionnaire.hash}`}>{text}</Link>
            <p>作成日：{formatDate(questionnaire.createdAt)}</p>
          </>
        )
      },
      filterMultiple: false,
      onFilter: (value: string | number | boolean, record: Questionnaire) =>
        typeof value === 'string' && record.title.indexOf(value) === 0,
      sorter: (a: Questionnaire, b: Questionnaire) => orderByText(a.title, b.title),
    },

    {
      title: questionnaireDisplayName.category,
      dataIndex: 'category',
      key: 'category',
      filterMultiple: false,
      filters: [
        { text: categoryTypeName[Category.NotSet], value: Category.NotSet },
        { text: categoryTypeName[Category.AfterValuation], value: Category.AfterValuation },
        { text: categoryTypeName[Category.OnSale], value: Category.OnSale },
        { text: categoryTypeName[Category.AfterContract], value: Category.AfterContract },
      ],
      render: (_: number, questionnaire: Questionnaire) => (
        <span>
          <Tag color={categoryTypeColor[questionnaire.category]}>{categoryTypeName[questionnaire.category]}</Tag>
        </span>
      ),
      onFilter: (value: string | number | boolean, record: Questionnaire) =>
        typeof value === 'number' && record.category === value,
    },
    {
      title: questionnaireDisplayName.type,
      dataIndex: 'type',
      key: 'type',
      filterMultiple: false,
      filters: [
        { text: questionnaireTypeName[QuestionnaireType.Contract], value: QuestionnaireType.Contract },
        { text: questionnaireTypeName[QuestionnaireType.Delivery], value: QuestionnaireType.Delivery },
        { text: questionnaireTypeName[QuestionnaireType.Seller], value: QuestionnaireType.Seller },
        { text: questionnaireTypeName[QuestionnaireType.Buyer], value: QuestionnaireType.Buyer },
        { text: questionnaireTypeName[QuestionnaireType.Borrower], value: QuestionnaireType.Borrower },
        { text: questionnaireTypeName[QuestionnaireType.Lender], value: QuestionnaireType.Lender },
        { text: questionnaireTypeName[QuestionnaireType.GrantactBuyer], value: QuestionnaireType.GrantactBuyer },
        { text: questionnaireTypeName[QuestionnaireType.GrantactSeller], value: QuestionnaireType.GrantactSeller },
      ],
      render: (_: number, questionnaire: Questionnaire) => (
        <span>
          <Tag color={questionnaireTypeColor[questionnaire.type]}>{questionnaireTypeName[questionnaire.type]}</Tag>
        </span>
      ),
      onFilter: (value: string | number | boolean, record: Questionnaire) =>
        typeof value === 'number' && record.type === value,
    },

    {
      title: questionnaireDisplayName.remindSpan,
      filterMultiple: false,
      render: (_: string, questionnaire: Questionnaire) => {
        return (
          <span>
            {questionnaire.remindSpan == 0 || questionnaire.remindCount == 0 ? (
              <Tag color={'pink'}>送信しない</Tag>
            ) : (
              <Tag color={'orange'}>
                {questionnaire.remindSpan}日ごと{questionnaire.remindCount}回
              </Tag>
            )}
          </span>
        )
      },
    },

    {
      title: '',
      filterMultiple: false,
      render: (_: string, questionnaire: Questionnaire) => {
        return (
          <span>
            <Button type="primary" onClick={() => {}}>
              {/*pathname: `${config.setting.paths.staffClientDeliveryList}/${questionnaire.groupHash}`,*/}
              <Link
                to={{
                  pathname: `${config.setting.paths.staffRegisterDistributionList}/${questionnaire.hash}`,
                  state: { questionnaire },
                }}
              >
                配信する
              </Link>
            </Button>
          </span>
        )
      },
    },
    {
      title: '回答結果',
      filterMultiple: false,
      render: (_: string, questionnaire: Questionnaire) => {
        return (
          <span>
            <CsvExport questionnaire={questionnaire} />
          </span>
        )
      },
    },

    {
      title: '文面プレビュー',
      filterMultiple: false,
      render: (_: string, questionnaire: Questionnaire) => {
        return (
          <span>
            <Button
              onClick={() => showPreviewTextBtnModal(questionnaire)}
              loading={questionnaire.id === notificationPreviewLoading.questionnaireId && notificationPreviewLoading.loading}
              style={{ width: 115 }}
            >
              プレビュー
            </Button>
          </span>
        )
      },
    },

    {
      title: '',
      filterMultiple: false,
      render: (_: string, questionnaire: Questionnaire) => {
        return (
          <span>
            <Button danger onClick={() => showNonVisualBtnModal(questionnaire)}>
              非表示
            </Button>
          </span>
        )
      },
    },
    {
      title: '',
      filterMultiple: false,
      render: (_: string, questionnaire: Questionnaire) => {
        return (
          <span>
            <Button
              danger
              // disabled={questionnaire.answerers[0].status === StatusType.UndoSend}
              onClick={() => showRemoveBtnModal(questionnaire)}
            >
              削除
            </Button>
          </span>
        )
      },
    },
  ]

  return (
    <>
      {/*メール本文を表示させるモーダル*/}
      <ShowNotificationPreviewModal
        isShowNotificationPreviewModal={isShowNotificationPreviewModal}
        setIsShowNotificationPreviewModal={setIsShowNotificationPreviewModal}
        selectQuestionnaire={selectQuestionnaire}
        setSelectQuestionnaire={setSelectQuestionnaire}
        mailHtml={mailHtml}
        setMailHtml={setMailHtml}
        smsText={smsText}
        setSmsText={setSmsText}
      />

      {/*アンケート非表示 モーダル*/}
      <NonVisualBtnModal
        isNonVisualBtnModal={isNonVisualBtnModal}
        setIsNonVisualBtnModal={setIsNonVisualBtnModal}
        cancelQuestionnaire={cancelQuestionnaire}
        setCancelQuestionnaire={setCancelQuestionnaire}
        effectQuery={effectQuery}
      />
      {/*アンケート削除ボタンモーダル*/}
      <RemoveBtnModal
        isRemoveBtnModal={isRemoveBtnModal}
        setIsNonVisualBtnModal={setIsRemoveBtnModal}
        cancelQuestionnaire={cancelQuestionnaire}
        setCancelQuestionnaire={setCancelQuestionnaire}
        effectQuery={effectQuery}
      />
      <RegisterHeader />
      <Table<Questionnaire> loading={loading} columns={columns} data={list} rowKey={(record) => record.hash} />
    </>
  )
}

export default QuestionnaireList
