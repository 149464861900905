import React, { PropsWithChildren, useState } from "react"
import {Form, Typography} from "antd"
import {QuestionType} from "constance/constance.display"
import {Slider, FormItem} from "./Slider.style"

type IProps<T> = {
  sectionNo: number
  questionNo: number
  questionText: string
  list: T[]
  required?: boolean
}

const {Text} = Typography

const SliderComponent = <T extends { no: number, text: string }, >
({
   sectionNo,
   questionNo,
   questionText,
   list,
   required = true
 }: PropsWithChildren<IProps<T>>) => {
  const min = list[0]?.no ?? 0
  const max = list[1]?.no ?? 0
  const minText = list[0]?.text ?? ''
  const maxText = list[1]?.text ?? ''
  let marks = {} as { [key: number]: number }
  Array.from({length: max - min + 1}, (_, i) => i + min).forEach(num => {
    marks[num] = num
  })
  const [isFirstChange, setIsFirstChange] = useState<boolean>()

  const handleChange = () => {
    if (!isFirstChange) { return setIsFirstChange(true);}
  }

  return (
      <>
        <div className="questionnaire-form__text-wrapper">
          <Text className="questionnaire-form__text">{questionText}</Text>
        </div>

        <div className="questionnaire-form-item-slider-label">
          <Text>{minText}</Text>
          <Text>{maxText}</Text>
        </div>
        <FormItem
            className="questionnaire-form-item"
            name={[sectionNo, questionNo, QuestionType.Slider]}
            rules={[{required, message: '必ず数字を選択してください。'}]}
        >
          <Slider
              onChange={handleChange}
              min={min}
              max={max}
              step={Object.keys(marks).length}
              marks={marks}
              handleStyle={
                isFirstChange ? {
                  border: "solid 2px #7ae9ff",
                  width: 14,
                  height: 14,
                  marginTop: -5,
                } : {
                  border: "solid 2px #f0f0f0",
                  width: 8,
                  height: 8,
                  marginTop: -2,
                }
              }
          />
        </FormItem>
      </>
  )
}

export default SliderComponent
