import * as React from 'react'
import {
  questionnaireDisplayName,
  questionnaireTypeColor,
  questionnaireTypeName,
  CancelStatus,
  answerStatusColor,
  sendStatusColor,
  answerStatusTypeName,
  mailSendStatusTypeName,
  smsSendStatusTypeName,
  SubscribeType,
  subscribeTypeName,
} from 'constance'
import { Button, message, Tag, Tooltip } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import CustomTable from '../CustomTable'
import './AnswererList.less'

export interface IAnswererListProps {
  loading: boolean
  list: Answerer[]
  showCancelDeliveryModal: (client: Answerer, subscribeType: SubscribeType) => void
  totalItemCount: number
  pageNumber: number
  pageSize: number
  onChange: (current: number, size?: number) => void
}

const AnswererList: React.FC<IAnswererListProps> = (props) => {
  const columns = [
    {
      title: 'アンケートNo',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: '顧客名',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, client: Answerer) => {
        return (
          <>
            <div>{client.changedName ? client.changedName : client.name}</div>
          </>
        )
      },
    },
    {
      title: 'メール',
      dataIndex: ['sendStatus'],
      key: 'sendStatus',
      render: (_: string, client: Answerer) => {
        const subscribeType = SubscribeType.Email
        const status = client.subscribeStatuses.find((status) => status.type === subscribeType)
        if (!status) return null
        if (status.cancelStatus === CancelStatus.SendCancel) {
          return (
            <span className="status">
              <Tag>{mailSendStatusTypeName[status.sentStatus]}</Tag>
            </span>
          )
        } else {
          return (
            <>
              <span className="status">
                <Tag color={sendStatusColor[status.sentStatus]}>{mailSendStatusTypeName[status.sentStatus]}</Tag>
                <Tooltip placement="top" title={`${subscribeTypeName[subscribeType]}配信を停止`}>
                  <Button
                    danger
                    size="small"
                    shape="circle"
                    onClick={() => props.showCancelDeliveryModal(client, subscribeType)}
                  >
                    ■
                  </Button>
                </Tooltip>
              </span>
            </>
          )
        }
      },
    },
    {
      title: 'SMS',
      dataIndex: ['cancelStatus'],
      key: 'cancelStatus',
      render: (_: string, client: Answerer) => {
        const subscribeType = SubscribeType.Sms
        const status = client.subscribeStatuses.find((status) => status.type === subscribeType)
        if (!status) return null
        if (status.cancelStatus === CancelStatus.SendCancel) {
          return (
            <span className="status">
              <Tag>{smsSendStatusTypeName[status.sentStatus]}</Tag>
            </span>
          )
        } else {
          return (
            <span className="status">
              <Tag color={sendStatusColor[status.sentStatus]}>{smsSendStatusTypeName[status.sentStatus]}</Tag>
              <Tooltip placement="top" title={`${subscribeTypeName[subscribeType]}配信を停止`}>
                <Button
                  danger
                  size="small"
                  shape="circle"
                  onClick={() => props.showCancelDeliveryModal(client, subscribeType)}
                >
                  ■
                </Button>
              </Tooltip>
            </span>
          )
        }
      },
    },
    {
      title: '回答状況',
      dataIndex: ['cancelStatus'],
      key: 'cancelStatus',
      render: (_: string, client: Answerer) => {
        return (
          <span>
            <Tag color={answerStatusColor[client.answerStatus]}>{answerStatusTypeName[client.answerStatus]}</Tag>
          </span>
        )
      },
    },
    {
      title: questionnaireDisplayName.type,
      dataIndex: 'type',
      key: 'type',
      render: (_: number, answerer: Answerer) => {
        const {
          questionnaire: { type },
        } = answerer
        return (
          <span>
            <Tag color={questionnaireTypeColor[type]}>{questionnaireTypeName[type]}</Tag>
          </span>
        )
      },
    },
    {
      title: '回答URL',
      render: (_: string, client: Answerer) => {
        return (
          <span>
            <CopyToClipboard text={client.questionnaireUrl} onCopy={() => message.success('回答URLをコピーしました')}>
              <Button type="primary" ghost>
                コピー
              </Button>
            </CopyToClipboard>
          </span>
        )
      },
    },
  ]
  return (
    <CustomTable<Answerer>
      pageSize={props.pageSize}
      pageNumber={props.pageNumber}
      loading={props.loading}
      columns={columns}
      data={props.list}
      rowKey={(record) => record.id.toString()}
      totalItemCount={props.totalItemCount}
      onChange={props.onChange}
    />
  )
}

export default AnswererList
