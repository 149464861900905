import styled from 'styled-components'
import { Slider as AntSlider, Form } from "antd"


// スライダーの数字の値をボタンぽくする
export const Slider = styled(AntSlider)<{handleChange?: boolean}>`
  .ant-slider-mark-text {
    border: 1px solid #00AEEF;
    width: 24px;
    height: 24px;
    margin-top: 8px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
  }
`

//　スライダーの数字のstyleを変更した影響で、エラー文言が変な位置で表示されてしまうので追加
export const FormItem = styled(Form.Item)`
  .ant-form-item-control-input-content{
    padding-bottom: 24px;
  }
`
