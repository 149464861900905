import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import AnswererListComponent from 'components/AnswererList'
import CancelDeliveryModal from 'components/CancelDeliveryModal/CancelDeliveryModal'
import * as $apis from 'apis'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import { Button, Form, Input, Select, Spin } from 'antd'
import styled from 'styled-components'
import { getSubTitleByPath, getTitleByPath } from 'helper/helper.breadcrumb'
import {
  AnswerStatus,
  answerStatusTypeName,
  CancelStatus,
  cancelStatusTypeName,
  SendStatus,
  sendStatusTypeName,
  SubscribeType,
} from 'constance/constance.display'
import * as config from 'config'
import { useLocation } from 'react-router-dom'
import useBreadcrumbs from 'hooks/hooks.useBreadcrumb'

export interface IAnswererListProps {}

const Container = styled.div``

const FormContainer = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: 12px;
  }
`

const TipsContainer = styled.div`
  textAlign: left;
  margin-bottom: 16px;
  font-size: 12px;

  .ant-btn-sm {
    font-size: 11px;
  }
`

const AnswererList: React.FC<IAnswererListProps> = (props) => {
  const location = useLocation()
  const { setBreadcrumbs, setTitle, setSubTitle } = useBreadcrumbs()
  const [answerer, setAnswerer] = useState<Answerer | undefined>(undefined)
  const [visible, setVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const [list, setList] = useState<Answerer[]>([])
  const [searchNo, setSearchNo] = useState<string>()
  const [searchName, setSearchName] = useState<string>()
  const [searchSentStatus, setSearchSentStatus] = useState<number>()
  const [searchCancelStatus, setSearchCancelStatus] = useState<number>()
  const [searchAnswerStatus, setSearchAnswerStatus] = useState<number>()
  const [cancelDeliveryType, setCancelDeliveryType] = useState<SubscribeType>(SubscribeType.Email)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(50)
  const [totalItemCount, setTotalItemCount] = useState<number>(0)

  const onPaginationChange = async (pageNumber: number, pageSize?: number) => {
    try {
      setLoading(true)
      const res = await $apis.client.getList('', {
        no: searchNo,
        name: searchName,
        sentStatus: searchSentStatus,
        cancelStatus: searchCancelStatus,
        answerStatus: searchAnswerStatus,
        pageSize,
        pageNumber,
      })
      setPageNumber(res.data.pageNumber)
      setPageSize(res.data.pageSize)
      setList(res.data.contents)
      setTotalItemCount(res.data.totalItemCount)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const showCancelDeliveryModal = (client: Answerer, subscribeType: SubscribeType) => {
    setVisible(true)
    setAnswerer(client)
    setCancelDeliveryType(subscribeType)
  }

  const effectQuery = useCallback(async () => {
    try {
      setLoading(true)
      const res = await $apis.client.getList('', {
        no: searchNo,
        name: searchName,
        sentStatus: searchSentStatus,
        cancelStatus: searchCancelStatus,
        answerStatus: searchAnswerStatus,
        pageSize,
        pageNumber,
      })
      setPageNumber(res.data.pageNumber)
      setPageSize(res.data.pageSize)
      setList(res.data.contents)
      setTotalItemCount(res.data.totalItemCount)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [pageNumber, pageSize, searchAnswerStatus, searchCancelStatus, searchName, searchNo, searchSentStatus])

  useEffect(() => {
    effectQuery().then((r) => console.log(''))
  }, [])

  useEffect(() => {
    setSubTitle(getSubTitleByPath(config.setting.paths.staffAnswererList))
    setTitle(getTitleByPath(config.setting.paths.staffAnswererList))
    setBreadcrumbs([
      {
        path: config.setting.paths.staffAnswererList,
        breadcrumbName: getTitleByPath(config.setting.paths.staffAnswererList),
      },
    ])
  }, [location.pathname, setBreadcrumbs, setSubTitle, setTitle])

  const onFinish = async (values: {
    no: string
    name: string
    sentStatus: number
    cancelStatus: number
    answerStatus: number
  }) => {
    try {
      const pageNumber = 1
      setLoading(true)
      // 停止ボタンを押しリロードした際に、再度同じ検索条件でリクエストを取得できるように値をいれる。
      setSearchNo(values.no)
      setSearchName(values.name)
      setSearchSentStatus(values.sentStatus)
      setSearchCancelStatus(values.cancelStatus)
      setSearchAnswerStatus(values.answerStatus)
      const res = await $apis.client.getList('', { ...values, pageSize, pageNumber })
      setPageNumber(pageNumber)
      setPageSize(res.data.pageSize)
      setList(res.data.contents)
      setTotalItemCount(res.data.totalItemCount)
    } catch (error) {
      console.info(error)
    } finally {
      setLoading(false)
    }
  }

  const onFinishFailed = (errorInfo: ValidateErrorEntity<{ no: string; name: string }>) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Container>
      <CancelDeliveryModal
        subscribeType={cancelDeliveryType}
        visible={visible}
        setVisible={setVisible}
        callback={effectQuery}
        setAnswerer={setAnswerer}
        answerer={answerer}
      />
      <Spin spinning={loading}>
        <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <FormContainer>
            <Form.Item name="no" label={'アンケートNo'} labelCol={{ span: 24 }}>
              <Input type="text" />
            </Form.Item>
            <Form.Item name="name" label={'お客様氏名'} labelCol={{ span: 24 }}>
              <Input type="text" />
            </Form.Item>

            <Form.Item name="answerStatus" label={'回答状況'} labelCol={{ span: 24 }} style={{ width: 136 }}>
              <Select placeholder="回答状況" allowClear>
                <Select.Option value={AnswerStatus.Unanswered}>
                  {answerStatusTypeName[AnswerStatus.Unanswered]}
                </Select.Option>

                <Select.Option value={AnswerStatus.Answered}>
                  {answerStatusTypeName[AnswerStatus.Answered]}
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item style={{ marginTop: 40 }}>
              <Button type="default" htmlType="submit">
                検索
              </Button>
            </Form.Item>
          </FormContainer>
          <TipsContainer>
            <Button danger size="small" shape="circle" style={{ cursor: 'default' }}>
              ■
            </Button>
            &nbsp;を押すことで、メールまたはSMSの配信を停止することができます。
          </TipsContainer>
        </Form>
      </Spin>
      <AnswererListComponent
        list={list}
        loading={loading}
        showCancelDeliveryModal={showCancelDeliveryModal}
        pageNumber={pageNumber}
        pageSize={pageSize}
        totalItemCount={totalItemCount}
        onChange={onPaginationChange}
      />
    </Container>
  )
}

export default AnswererList
