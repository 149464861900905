import {QuestionType} from "../../constance/constance.display"

export const formatAnswerForm = (answerQuestionnaire: AnswerQuestionnaire): DeliveryAnswerRequestDto => {
  let data = {answers: []} as DeliveryAnswerRequestDto
  Object.entries(answerQuestionnaire).forEach(([sectionNo, sectionValue]) => {
    if (sectionValue)
      Object.entries(sectionValue).forEach(([questionNo, questionValue]) => {
        const radioValue = questionValue?.[QuestionType.Radio] as undefined | number
        const checkBoxValues = questionValue?.[QuestionType.CheckBox] as undefined | number[]
        const textAreaValue = questionValue?.[QuestionType.OptionalTextArea] as undefined | string
        const emailValue = questionValue?.[QuestionType.OptionalEmail] as undefined | string
        const sliderValue = questionValue?.[QuestionType.Slider] as undefined | number
        const flowValue = questionValue?.[QuestionType.Flow] as undefined | string
        if (radioValue !== undefined) {
          data = setAnswerNumber(data, sectionNo, questionNo, radioValue)
        }
        if (checkBoxValues !== undefined) {
          checkBoxValues.forEach(checkBoxValue => {
            data = setAnswerNumber(data, sectionNo, questionNo, checkBoxValue)
          })
        }
        if (textAreaValue !== undefined) {
          data = setText(data, sectionNo, questionNo, textAreaValue)
        }
        if (emailValue !== undefined) {
          data = setText(data, sectionNo, questionNo, emailValue)
        }
        if (sliderValue !== undefined) {
          data = setAnswerNumber(data, sectionNo, questionNo, sliderValue)
        }
        if (flowValue !== undefined) {
          data = setAnswerNumber(data, sectionNo, questionNo, Number(flowValue))
        }
      })
  })
  return data
}

const setAnswerNumber = (data: any, sectionNo: string, questionNo: string, answerNo: number) => {
  return {
    ...data,
    answers: [
      ...data.answers,
      {
        sectionNo,
        questionNo,
        answerNo
      }
    ]
  }
}

const setText = (data: any, sectionNo: string, questionNo: string, text: string) => {
  return {
    ...data,
    answers: [
      ...data.answers,
      {
        sectionNo,
        questionNo,
        text
      }
    ]
  }
}

