import {Role} from "constance/constance.role"
import * as config from "config"
import {IRoute} from "./index"
import Questionnaire from "presenters/Answerer/Questionnaire"
import UnAuthorizedLayout from "layouts/UnAuthorizedLayout"
import QuestionnaireThanks from "presenters/Answerer/QuestionnairThanks"
import BeforeRedirect from "presenters/Answerer/BeforeRedirect"

const answererRoutes = [
  {
    exact: true,
    role: Role.Answerer,
    layout: UnAuthorizedLayout,
    component: Questionnaire,
    path: `${config.setting.paths.answererQuestionnaire}/:hash`
  },
  {
    exact: true,
    role: Role.Answerer,
    layout: UnAuthorizedLayout,
    component: QuestionnaireThanks,
    path: config.setting.paths.answererQuestionnaireThanks
  },
  {
    exact: true,
    role: Role.Answerer,
    layout: UnAuthorizedLayout,
    component: BeforeRedirect,
    path: config.setting.paths.answererBeforeRedirect(":id")
  }
] as IRoute[]

export default answererRoutes
