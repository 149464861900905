import React, { PropsWithChildren, useEffect } from 'react'
import { Form, Radio, Typography } from 'antd'
import { QuestionType } from '../../../constance/constance.display'

type IProps<T> = {
  sectionNo: number
  questionNo: number
  questionText: string
  list: T[]
  required?: boolean
}

const radioStyle = {
  display: 'block',
  lineHeight: '30px',
  marginBottom: 8,
}

const { Text } = Typography

const RadioComponent = <T extends { no: number; text: string }>({
  sectionNo,
  questionNo,
  questionText,
  list,
  required = true,
}: PropsWithChildren<IProps<T>>) => {
  useEffect(() => {
    document.getElementById(`${sectionNo}-${questionNo}`)?.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.preventDefault()
      }
    })
  }, [questionNo, sectionNo])

  return (
    <>
      <div className="questionnaire-form__text-wrapper">
        <Text className="questionnaire-form__text">{questionText}</Text>
      </div>

      <Form.Item
        className="questionnaire-form-item"
        name={[sectionNo, questionNo, QuestionType.Radio]}
        rules={[{ required, message: `必ず1つ選択してください。` }]}
      >
        <Radio.Group id={`${sectionNo}-${questionNo}`}>
          {list.map((t, index: number) => {
            return (
              <Radio style={radioStyle} key={index} value={t.no}>
                {t.text}
              </Radio>
            )
          })}
        </Radio.Group>
      </Form.Item>
    </>
  )
}

export default RadioComponent
