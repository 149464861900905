export const orderByText = (a: string | undefined, b: string | undefined) => {
  if (a === undefined) {
    return -1
  }
  if (b === undefined) {
    return 1
  }
  a = katakanaToHiragana(a.toString())
  b = katakanaToHiragana(b.toString())
  if (a < b) {
    return -1
  } else if (a > b) {
    return 1
  }
  return 0
}

function katakanaToHiragana(src: string) {
  return src.replace(/[\u30a1-\u30f6]/g, function (match) {
    const chr = match.charCodeAt(0) - 0x60
    return String.fromCharCode(chr)
  })
}

export const omit = (text: string) => (len: number) => (ellipsis: string) =>
  ellipsis.length
    ? text.slice(0, len - ellipsis.length) + ellipsis
    : text

function convertToBase64(file: File): Promise<string | ArrayBuffer | null> {
  const fr = new FileReader()
  return new Promise((resolve, reject) => {
    fr.onload = (e) => {
      if (e.target) {
        resolve(e.target.result)
      } else {
        reject()
      }
    }
    fr.readAsDataURL(file)
  })
}

// FormDataを作成
export const createFormData = async (fileList: [], props?: { data: object, sentAt: Date}) => {
  try {
    const formData = new FormData()
    for (let file of fileList) {
      const base64Uri = await convertToBase64(file)
      if (typeof base64Uri !== 'string') {
        throw new Error()
      }
      const base64 = base64Uri.split(',')[1]
      formData.append('files', base64)
    }
    if (props?.data) {
      formData.append('data', JSON.stringify(props.data))
    }
    if (props?.sentAt) {
      formData.append('sentAt', JSON.stringify(props.sentAt))
    }
    return formData
  } catch (e) {
    throw new Error(e)
  }
}

