import * as React from 'react'
import { useHistory } from 'react-router'
import Table from 'components/Table'
import SyncedConfirmModal from 'components/SyncedConfirmModal'
import UnSyncedConfirmModal from 'components/UnSyncedConfirmModal'
import { TagFieldsContext } from 'contexts'
import { useContext, useEffect, useState } from 'react'
import * as $apis from '../../apis'
import { Button, message, Tag as AntTag } from 'antd'
import * as config from '../../config'

export interface IUnSyncedTagTableProps {}

// 同期されていないファイルを表示テーブル
const UnSyncedTagTable: React.FC<IUnSyncedTagTableProps> = (props) => {
  const [loading, setLoading] = useState(true)
  const [unSyncedTagList, setUnSyncedTagList] = useState<UnSyncedTag[]>([]) //  同期できていないリスト
  const history = useHistory()
  const { setCsvFormat } = useContext(TagFieldsContext)

  // 同期するかどうかのモーダル
  const [isDisplaySyncedConfirmModal, setIsDisplaySyncedConfirmModal] = useState<boolean>(false)

  // 同期しないか確認のモーダル
  const [isDisplayUnSyncedConfirmModal, setIsDisplayUnSyncedConfirmModal] = useState<boolean>(false)
  const [selectUnSyncedTag, setSelectUnSyncedTag] = useState<UnSyncedTag | any>(undefined)

  const getUnSyncedTags = async () => {
    try {
      // 同期されてないタグを取得
      const unSyncedTagList = await $apis.tag.getUnSyncedTagList()
      setUnSyncedTagList(unSyncedTagList.data)

      if (unSyncedTagList.data.length === 0) {
        message.success('再度同期が必要な配信リストファイルはございません。')
      } else {
        message.info('同期できてない項目があります。')
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  // 同期した後に同期できていないtagsを取得
  const getUnSyncedTagsAgain = async () => {
    try {
      // 同期されてないタグを取得
      const unSyncedTagList = await $apis.tag.getUnSyncedTagList()
      setUnSyncedTagList(unSyncedTagList.data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getUnSyncedTags()
  }, [])

  const onClickRegisterBtn = (csvFormat: string) => {
    history.push(config.setting.paths.staffTagCreate)
    setCsvFormat(csvFormat)
  }

  const columns = [
    {
      title: 'id',
      dataIndex: '',
      key: '',
      filterMultiple: false,
      onFilter: (text: any, unSyncedTag: UnSyncedTag) => false,
      render: (text: any, recode: any, index: number) => {
        return <div>{index + 1}</div>
      },
    },

    {
      title: 'ラベル名',
      dataIndex: '',
      key: '',
      filterMultiple: false,
      onFilter: (value: any, unSyncedTag: UnSyncedTag) => false,
      render: (_: string, unSyncedTag: UnSyncedTag) => {
        return (
          <div>
            {unSyncedTag.tag?.name ? <>{unSyncedTag.tag.name}</> : <AntTag color={'magenta'}>{'未登録'}</AntTag>}
          </div>
        )
      },
    },

    {
      title: '未連携ファイル',
      dataIndex: 'fileName',
      key: 'fileName',
      filterMultiple: false,
      onFilter: (value: any, unSyncedTag: UnSyncedTag) => false,
    },

    {
      title: '推奨タグ名',
      dataIndex: 'format',
      key: 'format',
      filterMultiple: false,
      onFilter: (value: any, unSyncedTag: UnSyncedTag) => false,
    },

    {
      title: 'アンケート',
      dataIndex: '',
      key: '',
      filterMultiple: false,
      onFilter: (value: any, unSyncedTag: UnSyncedTag) => false,
      render: (text: string, unSyncedTag: UnSyncedTag) => {
        return <div>{unSyncedTag.tag?.questionnaire.title ?? <AntTag color={'magenta'}>{'未連携'}</AntTag>}</div>
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      filterMultiple: false,
      onFilter: (value: any, unSyncedTag: UnSyncedTag) => false,
      render: (_: string, unSyncedTag: UnSyncedTag) => {
        const onClick = () => {
          setIsDisplaySyncedConfirmModal(true)
          setSelectUnSyncedTag(unSyncedTag)
        }

        return (
          <>
            {unSyncedTag.tag ? (
              <div>
                <Button htmlType={'button'} onClick={onClick}>
                  同期する
                </Button>
              </div>
            ) : (
              <div>
                <Button htmlType={'button'} onClick={() => onClickRegisterBtn(unSyncedTag.format)}>
                  LMD連携タグを登録する
                </Button>
              </div>
            )}
          </>
        )
      },
    },

    {
      title: '',
      dataIndex: '',
      key: '',
      filterMultiple: false,
      onFilter: (value: any, unSyncedTag: UnSyncedTag) => false,
      render: (_: string, unSyncedTag: UnSyncedTag) => {
        // タグの同期をしないボタンをクリックしたあとの関数
        const onClick = () => {
          setIsDisplayUnSyncedConfirmModal(true)
          setSelectUnSyncedTag(unSyncedTag)
        }

        return (
          <div>
            {unSyncedTag.tag && (
              <Button danger={true} htmlType={'button'} onClick={onClick}>
                同期しない
              </Button>
            )}
          </div>
        )
      },
    },
  ]

  return (
    <>
      {/*同期を実行するか確認のモーダル*/}
      <SyncedConfirmModal
        isDisplayModal={isDisplaySyncedConfirmModal}
        setIsDisplayModal={setIsDisplaySyncedConfirmModal}
        selectUnSyncedTag={selectUnSyncedTag}
        getUnSyncedTags={getUnSyncedTagsAgain}
      />

      <UnSyncedConfirmModal
        isDisplayModal={isDisplayUnSyncedConfirmModal}
        setIsDisplayModal={setIsDisplayUnSyncedConfirmModal}
        selectUnSyncedTag={selectUnSyncedTag}
        getUnSyncedTags={getUnSyncedTagsAgain}
      />

      <div className="un-synced-tag-table">
        <Table loading={loading} columns={columns} data={unSyncedTagList} rowKey={(record) => record.fileName} />
      </div>
    </>
  )
}

export default UnSyncedTagTable
